@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.WrapperSubtitle {
  display: grid;
  grid-template-columns: 30% 30% 28% 11% 1%;
  font-family: var(--font-regular);
  color: var(--color-gray-title);
  padding: 0 24px;
  font-size: 12px;
  line-height: 16px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
    padding: 18px 34px 12px 24px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    padding: 28px 34px 16px 24px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    padding: 38px 34px 22px 24px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    padding: 48px 34px 32px 24px;
  }
}

.CourseList {
  display: flex;
  align-self: center;
  flex-direction: column;
}

.WrapperForCourseName {
  display: flex;
}