@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.PaginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @include media_desktop_qhd {
    margin-top: 20px;
  }

  @include media_desktop_qhd {
    margin-top: 24px;
  }

  @include media_desktop_wxga {
    margin-top: 28px;
  }

  @include media_desktop_full {
    margin-top: 34px;
  }
}

.TextNumber {
  font-family: var(--font-regular);
  font-size: 9px;
  line-height: 17px;
  color: var(--color-gray-title);

  @include media_desktop_qhd {
    font-size: 9px;
    line-height: 17px;
  }

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.ListPagination {
  margin: 8px 14px;
  font-family: var(--font-medium);
  color: var(--color-gray-title);
  line-height: 14px;
  font-size: 10px;
  cursor: pointer;

  @include media_desktop_qhd {
    line-height: 14px;
    font-size: 10px;
  }

  @include media_desktop_hd {
    line-height: 18px;
    font-size: 14px;
  }

  @include media_desktop_wxga {
    line-height: 20px;
    font-size: 16px;
  }

  @include media_desktop_full {
    line-height: 22px;
    font-size: 18px;
  }
}

.ContainerPaginate {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
}

.ActiveBtnPaginate {
  background: var(--color-tomato);
  color: var(--color-white);
  border-radius: 5px;
  padding: 8px 14px;
}

.Previous,
.Next {
  color: var(--color-tomato);
  font-family: var(--font-medium);
  font-size: 20px;
  cursor: pointer;

  @include media_desktop_qhd {
    font-size: 20px;
  }

  @include media_desktop_hd {
    font-size: 22px;
  }

  @include media_desktop_wxga {
    font-size: 24px;
  }

  @include media_desktop_full {
    font-size: 26px;
  }
}

.Previous {
  margin: 0 18px 4px 0;
}

.Next {
  margin: 0 0 4px 18px;
}
