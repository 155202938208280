@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.HiddenBackground {
  background-color: var(--color-silver);
  opacity: .3;
}

.MainPopupContainer {
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: var(--color-white);
  user-select: none;
  z-index: 4;
  border-radius: 6px;
  width: 704px;
  height: 452px;

  @include media_desktop_qhd {
    width: 704px;
    height: 452px;
  }

  @include media_desktop_hd {
    width: 804px;
    height: 552px;
  }

  @include media_desktop_wxga {
    width: 904px;
    height: 652px;
  }

  @include media_desktop_full {
    width: 1004px;
    height: 802px;
  }
}

.CloseContainer {
  display: block;
  text-align: right;
}

.CloseButton {
  cursor: pointer;
  margin: 12px 12px 0 0;
  width: 28px;
  height: 28px;

  @include media_desktop_wxga {
    margin: 12px 12px 0 0;
    width: 28px;
    height: 28px;
  }

  @include media_desktop_wxga {
    margin: 16px 16px 0 0;
    width: 32px;
    height: 32px;
  }

  @include media_desktop_wxga {
    margin: 20px 20px 0 0;
    width: 36px;
    height: 36px;
  }

  @include media_desktop_full {
    margin: 24px 24px 0 0;
    width: 40px;
    height: 40px;
  }
}

.TitleText {
  display: block;
  text-align: center;
  font-family: var(--font-medium);
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 14px;

  @include media_desktop_qhd {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 18px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

.WrapperInput {
  display: flex;
  margin-left: 36px;

  @include media_desktop_qhd {
    margin-left: 32px;
  }

  @include media_desktop_hd {
    margin-left: 36px;
  }

  @include media_desktop_wxga {
    margin-left: 40px;
  }

  @include media_desktop_full {
    margin-left: 44px;
  }
}

.Image {
  transition: 0.4s;
}

.ImageClose {
  transition: 0.4s;
  transform: rotate(360deg);
  width: 28px;
  cursor: pointer;
}

.InputField {
  box-sizing: border-box;
  color: var(--color-dark-text);
  border: none;
  padding: 16px 11px;
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 20px;

  @include media_desktop_qhd {
    font-size: 16px;
    line-height: 20px;
    width: 500px;
    padding: 16px 11px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    width: 600px;
    padding: 19px 14px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    width: 700px;
    padding: 22px 17px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    width: 900px;
    padding: 25px 20px;
  }
}

.WrapperQuestions {
  border-top: 1px solid var(--color-gray-border);
  margin: 0 18px 0 18px;
}

.ContainerListQuestionAndDetails {
  display: flex;
}

.Nodata {
  margin: 85px 0 114px;
  font-family: var(--font-medium);
  font-size: 20px;

  @include media_desktop_qhd {
    margin: 85px 0 114px;
  }

  @include media_desktop_hd {
    margin: 123px 0 141px;
  }

  @include media_desktop_wxga {
    margin: 144px 0 180px;
  }

  @include media_desktop_full {
    margin: 180px 0 244px;
  }
}

.BtnOk {
  margin: 30px auto;
}

.ImageChecked {
  @include media_desktop_hd {
    width: 16px;
    height: 16px;
  }

  @include media_desktop_wxga {
    width: 18px;
    height: 18px;
  }

  @include media_desktop_full {
    width: 20px;
    height: 20px;
  }

  svg {
    fill: var(--color-tomato);

    @include media_desktop_hd {
      width: 16px;
      height: 16px;
    }

    @include media_desktop_wxga {
      width: 18px;
      height: 18px;
    }

    @include media_desktop_full {
      width: 20px;
      height: 20px;
    }
  }
}

.Button {
  background-color: var(--color-tomato);
  color: var(--color-white);
  font-family: var(--font-medium);
  outline: none;
  padding: 8px 18px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-tomato);

  span {

    @include media_desktop_hd {
      margin-left: 18px;
    }

    @include media_desktop_wxga {
      margin-left: 20px;
    }

    @include media_desktop_full {
      margin-left: 22px;
    }
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    padding: 9px 34px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    padding: 11px 36px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    padding: 13px 38px;
  }

  &:hover {
    border: 1px solid var(--color-hover-btn);
  }
}

.ButtonUnchecked {
  background-color: transparent;
  border: 1px solid var(--color-tomato);
  color: var(--color-tomato);

  &:hover {
    color: var(--color-hover-btn);
    border: 1px solid var(--color-hover-btn);
    background: transparent;
    span {
      color: var(--color-hover-btn);
    }
    svg {
      fill:var(--color-hover-btn);
    }
  }
}

.BtnReset {
  border: 1px solid var(--color-tomato);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px 12px;
  margin-left: 30px;
  font-size: 18px;
  line-height: 22px;
  font-family: var(--font-medium);
  color: var(--color-tomato);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    padding: 12px 8px;
    margin-left: 26px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    padding: 14px 10px;
    margin-left: 28px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    padding: 16px 12px;
    margin-left: 30px;
  }

  &:hover {
    color: var(--color-hover-btn);
    border: 1px solid var(--color-hover-btn);
  }
}

.DisabledBtn {
  color: var(--color-gray-border);
  border: 1px solid var(--color-gray-border);

  &:hover {
    color: var(--color-gray-border);
    border: 1px solid var(--color-gray-border);
  }
}

.WrapperBtns {
  display: flex;
  justify-content: center;
  margin: 26px 0 18px 0;
}

.TextAttempts {
  color: var(--color-silver);
}

.ToastContainer {
  div {
    font-family: var(--font-medium);
    color: var(--color-silver);
    font-size: 16px;
    text-align: left;
  }

  svg {
    visibility: visible;
    fill: var(--color-tomato);
  }

  [role="progressbar"] {
    background: var(--color-tomato) !important;
  }
}

