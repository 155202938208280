@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.MainContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.BackgroundImage {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.SignInContainer {
  display: block;
}

.TitleText {
  display: block;
  font-family: var(--font-medium);
  color: var(--color-tomato);
  margin-top: 50px;

  @include media_desktop_qhd {
    font-size: 42px;
    line-height: 46px;
  }

  @include media_desktop_hd {
    font-size: 44px;
    line-height: 50px;
  }

  @include media_desktop_wxga {
    font-size: 46px;
    line-height: 54px;
  }

  @include media_desktop_full {
    font-size: 48px;
    line-height: 58px;
  }

  span {
    color: var(--color-black);
  }
}

.TitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.InputContainer {
  display: block;
  margin: px-to-vh(32px) auto 0 auto;

  @include media_desktop_qhd {
    width: 260px;
  }

  @include media_desktop_hd {
    width: 280px;
  }

  @include media_desktop_wxga {
    width: 300px;
  }

  @include media_desktop_full {
    width: 320px;
  }
}

.TitleDescriptionContainerHeader {
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    margin-top: 40px;
  }

  @include media_desktop_wxga {
    margin-top: 57px;
  }

  @include media_desktop_full {
    margin-top: 57px;
  }

  span {
    display: flex;
    font-family: var(--font-regular);
    white-space: nowrap;
    margin: 0 px-to-vh(12px);

    @include media_desktop_qhd {
      font-size: 12px;
      line-height: 16px;
    }

    @include media_desktop_hd {
      font-size: 14px;
      line-height: 28px;
    }

    @include media_desktop_wxga {
      font-size: 16px;
      line-height: 20px;
    }

    @include media_desktop_full {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.CompanyField {
  display: block !important;
  max-width: 150px;
  cursor: default;
  margin: 0 4px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.TitleDescriptionContainer {
  display: flex;
  align-items: center;
  margin-top: px-to-vh(32px);

  span {
    font-family: var(--font-regular);
    width: 100%;
    white-space: nowrap;
    display: block;
    margin: 0 px-to-vh(12px);

    @include media_desktop_qhd {
      font-size: 12px;
      line-height: 16px;
    }

    @include media_desktop_hd {
      font-size: 14px;
      line-height: 28px;
    }

    @include media_desktop_wxga {
      font-size: 16px;
      line-height: 20px;
    }

    @include media_desktop_full {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.Line {
  height: 0.5px;
  width: 100px;
  background-color: var(--color-silver);
}

.LabelInputText {
  display: block;
  margin-bottom: px-to-vh(8px);
  font-family: var(--font-regular);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.InputField {
  display: block;
  box-sizing: border-box;
  margin-bottom: px-to-vh(25px);
  font-family: var(--font-regular);
  padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);

  &::placeholder {
    color: var(--color-gray-border);
  }

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.LoginButton {
  display: block;
  margin: px-to-vh(32px) 0;
  box-sizing: border-box;
  width: 100%;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.SignUpDescriptionContainer {
  display: block;
  margin-top: px-to-vh(32px);
}

.SignUpButton {
  display: block;
  margin: 16px auto 0 auto;
  font-family: var(--font-regular);
  color: var(--color-tomato);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}


.NotAdaptive {
  background-color: var(--color-light-background);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-family: var(--font-medium);
  padding-top: 220px;

  @include media_desktop_qhd {
    padding-top: 236px;
    font-size: 26px;
  }
};

.TitleNotAdaptive {
  text-align: center;
  width: 246px;

  @include media_tablet {
    width: 450px;
  }

  @include media_desktop_qhd {
    width: 536px;
  }
}

.NotAdaptiveWrapper {
  background-color: var(--color-light-background);
  width: 100%;
  height: 100vh;
}

.LogoImage {
  padding: 20px 0 0 35px;
  width: 175px;
  height: 48px;
}

.SmileImg {
  margin-top: 78px;
  margin-left: -28px;
  width: 30px;
  height: 30px;

  @include media_tablet {
    margin-top: 23px;
    margin-left: -5px;
    width: 30px;
    height: 30px;
  }

  @include media_desktop_qhd {
    margin-top: 31px;
    margin-left: -10px;
    width: 30px;
    height: 30px;
  }
}
