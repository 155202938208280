@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.CourseConstructorContainer {
    display: flex;
    overflow: hidden;
}

.MainContentContainer {
    height: 100%;
    width: 100%;
    display: inline-flex;
}

.NotAdaptiveWrapper {
    background-color: var(--color-light-background);
    width: 100%;
    height: 100vh;
}

.LogoImage {
    padding: 20px 0 0 35px;
    width: 175px;
    height: 48px;
}

.NotAdaptive {
    background-color: var(--color-light-background);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-family: var(--font-medium);
    padding-top: 220px;

    @include media_desktop_qhd {
        padding-top: 236px;
        font-size: 26px;
    }
}

.TitleNotAdaptive {
    text-align: center;
    width: 246px;

    @include media_tablet {
        width: 450px;
    }

    @include media_desktop_qhd {
        width: 536px;
    }
}


.SmileImg {
    margin-top: 78px;
    margin-left: -28px;
    width: 30px;
    height: 30px;

    @include media_tablet {
        margin-top: 23px;
        margin-left: -5px;
        width: 30px;
        height: 30px;
    }

    @include media_desktop_qhd {
        margin-top: 31px;
        margin-left: -10px;
        width: 30px;
        height: 30px;
    }
}