@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.ContentComponent {
  color: var(--color-space);
  font-family: var(--font-light);
  background: var(--color-gray-bronze);
  border-radius: 4px;

  @include media_tablet {
    font-family: var(--font-medium);
  }

  @include media_desktop_hd {
    width: 850px;
    height: 494px;
  }

  @include media_desktop_wxga {
    width: 900px;
    height: 544px;
  }

  @include media_desktop_full {
    width: 920px;
    height: 544px;
  }

  canvas {
    margin: 0 auto;
  }

  div {
    text-align: center;
    color: var(--color-space);
  }
}