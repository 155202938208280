@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.MainIntegrationsContainer {
    user-select: none;
    padding: 26px 60px 20px 40px;

    @include media_desktop_qhd {
        padding: 26px 60px 20px 40px;
    }

    @include media_desktop_hd {
        padding: 36px 70px 30px 40px;
    }

    @include media_desktop_wxga {
        padding: 46px 80px 40px 40px;
    }

    @include media_desktop_full {
        padding: 56px 90px 40px 40px;
    }
}

.TitleText {
    font-family: var(--font-medium);
    font-size: 18px;
    line-height: 22px;

    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
    }
}

.HeaderContainer {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.Image {
    width: 30px;
    height: 30px;

    @include media_desktop_qhd {
        width: 30px;
        height: 30px;
    }

    @include media_desktop_hd {
        width: 35px;
        height: 35px;
    }

    @include media_desktop_wxga {
        width: 45px;
        height: 45px;
    }

    @include media_desktop_full {
        width: 48px;
        height: 48px;
    }
}

.CompleteImage {
    margin-right: 50px;
    width: 18px;
    height: 18px;

    @include media_desktop_qhd {
        margin-right: 50px;
        width: 18px;
        height: 18px;
    }

    @include media_desktop_hd {
        margin-right: 60px;
        width: 20px;
        height: 20px;
    }

    @include media_desktop_wxga {
        margin-right: 70px;
        width: 25px;
        height: 25px;
    }

    @include media_desktop_full {
        margin-right: 80px;
        width: 32px;
        height: 32px;
    }
}

.WrapperInputContainer {
    margin-top: 18px;

    @include media_desktop_qhd {
        margin-top: 18px;
    }

    @include media_desktop_hd {
        margin-top: 20px;
    }

    @include media_desktop_wxga {
        margin-top: 22px;
    }

    @include media_desktop_full {
        margin-top: 24px;
    }
}

.Input {
    font-size: 12px;
    width: 500px;
    padding: 12px;

    &::placeholder {
        font-size: 12px;

        @include media_desktop_qhd {
            font-size: 12px;
        }

        @include media_desktop_hd {
            font-size: 14px;
        }

        @include media_desktop_wxga {
            font-size: 16px;
        }

        @include media_desktop_full {
            font-size: 18px;
        }
    }

    @include media_desktop_qhd {
        font-size: 12px;
        width: 400px;
        padding: 12px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        width: 500px;
        padding: 12px;
    }

    @include media_desktop_wxga {
        font-size: 15px;
        width: 550px;
        padding: 14px;
    }

    @include media_desktop_full {
        font-size: 16px;
        width: 600px;
        padding: 16px;
    }
}

.InputError {
    border: 1px solid var(--color-tomato-light) !important;

    &:focus{
        border: 1px solid var(--color-tomato);
        box-shadow: 0 0 1px 1px var(--color-tomato-light);
    }

    &::placeholder {
        color: var(--color-tomato);
    }
}

.InputLabelContainer {
    margin-top: 24px;

    @include media_desktop_wxga {
        margin-top: 18px;
    }

    @include media_desktop_hd {
        margin-top: 20px;
    }

    @include media_desktop_wxga {
        margin-top: 22px;
    }

    @include media_desktop_full {
        margin-top: 24px;
    }
}

.ConnectButton {
    color: var(--color-white);
    background-color: var(--color-tomato);
    height: max-content;
    margin-left: 24px;
    font-size: 20px;
    line-height: 23px;
    border-radius: 6px;
    padding: 8px 26px;
    cursor: pointer;

    @include media_desktop_qhd {
        margin-left: 18px;
        font-size: 14px;
        line-height: 23px;
        padding: 8px 26px;
    }

    @include media_desktop_hd {
        margin-left: 20px;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 28px;
    }

    @include media_desktop_wxga {
        margin-left: 22px;
        font-size: 18px;
        line-height: 24px;
        padding: 12px 30px;
    }

    @include media_desktop_full {
        margin-left: 24px;
        font-size: 20px;
        line-height: 24px;
        padding: 15px 32px;
    }
}

.DisabledButton {
    background: var(--color-gray-border);
    cursor: default;

    &:hover {
        background: var(--color-gray-border);
    }
}

.Intagrations {
    font-family: var(--font-medium);
    font-size: 14px;
    line-height: 16px;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.IntegrationsContainer {
    box-sizing: border-box;
    padding: 20px 40px 20px 20px;
    background: var(--color-white);
    border-radius: 6px;
    max-width: 600px;
    margin-top: 26px;

    @include media_desktop_qhd {
        margin-top: 26px;
        padding: 20px 40px 20px 20px;
        max-width: 600px;
    }

    @include media_desktop_hd {
        margin-top: 28px;
        padding: 30px 40px 30px 30px;
        max-width: 700px;
    }

    @include media_desktop_wxga {
        margin-top: 30px;
        padding: 35px 55px 35px 35px;
        max-width: 800px;
    }

    @include media_desktop_full {
        margin-top: 32px;
        padding: 40px 60px 40px 40px;
        max-width: 844px;
    }
}

.PipedriveWrapper {
    @include media_desktop_hd {
        padding: 30px 26px 30px 30px;
    }

    @include media_desktop_wxga {
        padding: 35px 28px 35px 35px;
    }

    @include media_desktop_full {
        padding: 40px 30px 40px 40px;
    }
}

.IntegrationsStatus {
    display: flex;
    align-items: center;
}

.IntegrationsHeader {
    font-family: var(--font-medium);
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 18px;
        line-height: 26px;
    }

    @include media_desktop_wxga {
        font-size: 24px;
        line-height: 30px;
    }

    @include media_desktop_full {
        font-size: 28px;
        line-height: 34px;
    }

    span {
        margin-left: 24px;
    }
}

.WrapperCheckBox {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 26px;

    input[type='checkbox'] {
        padding: 7px 7px;
        margin: 0 16px 0 0;
        cursor: pointer;
        border: 1px solid var(--color-gray-border);
        border-radius: 2px;
        color: var(--color-white);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;

        @include media_desktop_qhd {
            width: 18px;
            height: 18px;
            margin: 0 16px 0 0;
            padding: 7px 7px;
        }

        @include media_desktop_hd {
            width: 20px;
            height: 20px;
            margin: 0 18px 0 0;
            padding: 8px 8px;
        }

        @include media_desktop_wxga {
            width: 22px;
            height: 22px;
            margin: 0 22px 0 0;
            padding: 9px 9px;
        }

        @include media_desktop_full {
            width: 24px;
            height: 24px;
            margin: 0 24px 0 0;
            padding: 10px 10px;
        }

        &:checked {
            background-color: var(--color-tomato);
            background-image: url('../../../../../../../../assets/images/icon/check-mark-white.svg');
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid var(--color-tomato);
        }
    }
}

.TextConfirm {
    font-family: var(--font-regular);
    font-size: 13px;
    line-height: 17px;
    color: var(--color-silver);

    @include media_desktop_qhd {
        font-size: 13px;
        line-height: 17px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.Invalid {
    margin-top: 20px;
    color: var(--color-tomato);
    font-family: var(--font-medium);
    font-size: 14px;
    line-height: 16px;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.Valid {
    color: var(--color-green);
}