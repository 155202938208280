@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.Container {
  background: var(--color-white);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    padding: 26px 42px;
    max-width: 680px;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    padding: 28px 44px;
    max-width: 744px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    padding: 30px 46px;
    max-width: 844px;
    font-size: 18px;
    line-height: 22px;
  }
}

.Configure {
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    padding: 11px 28px;
    margin-left: 21px;
  }

  @include media_desktop_wxga {
    padding: 13px 30px;
    margin-left: 23px;
  }

  @include media_desktop_full {
    padding: 15px 32px;
    margin-left: 25px;
  }
}

.Image {
  display: flex;
  align-items: center;

  svg {
    fill: var(--color-white);

    @include media_desktop_hd {
      width: 17px;
      height: 17px;
    }

    @include media_desktop_wxga {
      width: 18px;
      height: 18px;
    }

    @include media_desktop_full {
      width: 20px;
      height: 20px;
    }
  }

  @include media_desktop_hd {
    width: 17px;
    height: 17px;
    margin-right: 14px;
  }

  @include media_desktop_wxga {
    width: 18px;
    height: 18px;
    margin-right: 16px;
  }

  @include media_desktop_full {
    width: 20px;
    height: 20px;
    margin-right: 18px;
  }
}

.TextBtn {
  white-space: nowrap;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}