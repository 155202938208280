@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  background: var(--color-white);
  box-sizing: border-box;
  border-radius: 6px;
  height: fit-content;
  padding: 12px 22px;

  @include media_desktop_hd {
    width: 727px;
  }

  @include media_desktop_hd-after {
    width: 827px;
  }

  @include media_desktop_wxga {
    width: 927px;
  }

  @include media_desktop_full {
    width: 1027px;

  }
}

.WrapperMetric {
  display: grid;
  align-items: center;
  box-sizing: border-box;

  @include media_desktop_hd {
    padding: 19px 0;
    grid-template-columns: 24% 42% 18% 16%;
  }

  @include media_desktop_hd-after {
    grid-template-columns: 24% 45% 14% 17%;
    padding: 20px 0;
  }

  @include media_desktop_wxga {
    padding: 21px 0;
    grid-template-columns: 24% 45% 16% 15%;
  }

  @include media_desktop_full {
    padding: 22px 0;
    grid-template-columns: 24% 47% 14% 15%;
  }

  &:not(:last-child) {
    border-bottom: 0.5px solid var(--color-gray-border);
  }
}

.Name {
  @include media_desktop_hd {
    max-width: 130px;
  }

  @include media_desktop_hd-after {
    max-width: 160px;
  }

  @include media_desktop_wxga {
    max-width: 180px;
  }

  @include media_desktop_full {
    max-width: 200px;
  }
}

.Name,
.Action {
  font-family: var(--font-medium);
  align-self: center;

  @include media_desktop_hd {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_hd-after {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.ProgressData {
  display: flex;
  align-items: center;
}

.ActionWrapper {
  position: relative;
}

.Action {
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  user-select: none;
}

.ActionTextMake {
  color: var(--color-green);
  margin-right: 8px;
}

.ActionTextImprove {
  color: var(--color-dark-text);
  margin-right: 8px;
}

.ActionTextNeedHelp {
  color: var(--color-tomato);
  margin-right: 8px;
}

.MakeItImg {
  transition: 0.4s;

  svg {
    fill: var(--color-green);
  }
}

.ImproveImg {
  transition: 0.4s;

  svg {
    fill: var(--color-dark-text);
  }
}

.NeedHelpImg {
  transition: 0.4s;

  svg {
    fill: var(--color-tomato);
  }
}

.TopArrow {
    display: flex;
    align-items: center;
    transition: 0.4s;
    transform: rotate(180deg);
}

.Wrapper {
  opacity: 0.2;
}
