@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.MainContainer {
  border-radius: 6px 6px 0 0;

  @include media_desktop_hd {
    width: 661px;
  }

  @include media_desktop_wxga {
    width: 761px;
  }

  @include media_desktop_full {
    width: 861px;
  }
}

.CloseContainer {
  position: absolute;
  right: 0;
}

.Image {
  width: 100%;
  border-radius: 6px 6px 0 0;

  @include media_desktop_hd {
    margin-bottom: 19px;
  }

  @include media_desktop_wxga {
    margin-bottom: 21px;
  }

  @include media_desktop_full {
    margin-bottom: 23px;
  }
}

.Title {
  font-family: var(--font-medium);
  margin: 0 auto;

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    max-width: 450px;
    margin-bottom: 26px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    max-width: 550px;
    margin-bottom: 28px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    max-width: 650px;
    margin-bottom: 30px;
  }
}

.Subtitle {
  font-family: var(--font-regular);
  margin: 0 auto;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    max-width: 562px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    max-width: 662px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    max-width: 762px;
  }
}

.WrapperButtons {
  display: flex;
  justify-content: center;
  margin: 40px 0 48px 0;

}


.DisagreeBtn {
  background: var(--color-white);
  color: var(--color-gray-border);
  border: 1px solid var(--color-gray-border);
  font-family: var(--font-medium);

  @include media_desktop_hd {
    margin-right: 30px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    margin-right: 35px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    margin-right: 40px;
    font-size: 20px;
    line-height: 24px;
  }

  &:hover {
    background-color: var(--color-light-background);
  }
}

.AgreeBtn {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}