@import '../../../../assets/scss/helpers';
@import '../../../../assets/scss/medias';

.MainPopupContainer {
  @include media_desktop_hd {
    width: 400px;
  }

  @include media_desktop_wxga {
    width: 450px;
  }

  @include media_desktop_full {
    width: 526px;
  }
}

.CloseImg {
  @include media_desktop_hd {
    padding: 18px 18px 0 0;
  }

  @include media_desktop_wxga {
    padding: 28px 28px 0 0;
  }

  @include media_desktop_full {
    padding: 38px 38px 0 0;
  }
}

.WrapperForm {
  display: flex;
  justify-content: center;

  @include media_desktop_hd {
    padding-bottom: 42px;
  }

  @include media_desktop_wxga {
    padding-bottom: 52px;
  }

  @include media_desktop_full {
    padding-bottom: 72px;
  }
}

.Title {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 50px;
  }
}

.SubtitleInput {
  display: block;
  text-align: left;
  font-family: var(--font-medium);
  margin-bottom: 8px;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.InputName {
  font-family: var(--font-medium);
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  margin-bottom: 16px;
  letter-spacing: inherit;

  @include media_desktop_hd {
    padding: 8px 20px;
    width: 306px;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    padding: 10px 22px;
    width: 356px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    padding: 12px 24px;
    width: 396px;
    font-size: 18px;
    line-height: 22px;
  }

  &::placeholder {
    color: var(--color-gray-border);
  }
}

.ErrorName {
  border: 1px solid var(--color-tomato);
}

.WrapperCardElement {
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  margin-bottom: 16px;

  @include media_desktop_hd {
    padding: 8px 20px;
    width: 306px;
  }

  @include media_desktop_wxga {
    padding: 10px 22px;
    width: 356px;
  }

  @include media_desktop_full {
    padding: 12px 24px;
    width: 396px;
  }

  input {
    margin-top: 1px !important;
  }
}

.ContainerExpiryCvc {
  display: flex;
  justify-content: space-between;
}

.WrapperCardElementExpiryCvc {
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  margin-bottom: 16px;

  @include media_desktop_hd {
    padding: 9px 12px;
    width: 118px;
  }

  @include media_desktop_wxga {
    padding: 11px 14px;
    width: 128px;
  }

  @include media_desktop_full {
    padding: 13px 16px;
    width: 138px;
  }
  &:last-child {
    margin-bottom: 32px;
  }
}

.CardNumberError {
  border: 1px solid var(--color-tomato);
}

.ExpiredError {
  border: 1px solid var(--color-tomato);
}

.CvcError {
  border: 1px solid var(--color-tomato);
}

.SubmitButton {
  background: var(--color-tomato);
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
  border: none;
  outline: none;
  font-family: var(--font-medium);
  cursor: pointer;

  @include media_desktop_hd {
    width: 306px;
    padding: 8px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    width: 356px;
    padding: 10px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    width: 396px;
    padding: 12px;
    font-size: 20px;
    line-height: 24px;
  }
}

.SubmitButtonError {
  background: var(--color-gray-border);
}