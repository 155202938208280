@import '../../../../../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../../../../../assets/scss/medias';

.WrapperLearnerMetrics {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.WrapperItems {
  display: flex;
  text-align: left;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.Learner {
  display: flex;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    min-width: 160px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    min-width: 170px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    min-width: 180px;
    font-size: 20px;
    line-height: 24px;
  }
}

.Item {
  //background: var(--color-light-green);
  border-radius: 6px;
  display: flex;
  //color: var(--color-green);
  align-items: center;
  justify-content: center;

  @include media_desktop_hd {
    height: 76px;
    width: 102px;
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_wxga {
    height: 86px;
    width: 112px;
    font-size: 22px;
    line-height: 26px;
  }

  @include media_desktop_full {
    height: 96px;
    width: 132px;
    font-size: 24px;
    line-height: 28px;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
}