@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.ShareContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media_desktop_qhd {
        margin-top: 25px;
    }

    @include media_desktop_hd {
        margin-top: 30px;
    }

    @include media_desktop_wxga {
        margin-top: 35px;
    }

    @include media_desktop_full {
        margin-top: 40px;
    }
}

.ShareTextContainer {
    display: flex;
    align-items: center;
}

.ShareImage {
    @include media_desktop_qhd {
        width: 34px;
        height: 34px;
        margin-right: 26px;
    }

    @include media_desktop_hd {
        width: 36px;
        height: 36px;
        margin-right: 28px;
    }

    @include media_desktop_wxga {
        width: 38px;
        height: 38px;
        margin-right: 30px;
    }

    @include media_desktop_full {
        width: 40px;
        height: 40px;
        margin-right: 32px;
    }
}

.TextContainer {
    display: block;
    text-align: left;

    span {
        display: block;

        &:first-child {
            font-family: var(--font-medium);
            color: var(--color-dark-text);

            @include media_desktop_qhd {
                font-size: 14px;
                line-height: 18px;
            }

            @include media_desktop_hd {
                font-size: 16px;
                line-height: 20px;
            }

            @include media_desktop_wxga {
                font-size: 18px;
                line-height: 22px;
            }

            @include media_desktop_full {
                font-size: 20px;
                line-height: 24px;
            }
        }

        &:last-child {
            font-family: var(--font-regular);
            color: var(--color-silver);
            display: block;
            margin-top: px-to-vh(8px);

            @include media_desktop_qhd {
                font-size: 12px;
                line-height: 16px;
            }

            @include media_desktop_hd {
                font-size: 14px;
                line-height: 18px;
            }

            @include media_desktop_wxga {
                font-size: 16px;
                line-height: 20px;
            }

            @include media_desktop_full {
                font-size: 18px;
                line-height: 22px;
            }
        }

    }
}

.InputContainer {
    display: flex;
    

    @include media_desktop_qhd {
        margin-top: 10px;
    }

    @include media_desktop_hd {
        margin-top: 12px;
    }

    @include media_desktop_wxga {
        margin-top: 14px;
    }

    @include media_desktop_full {
        margin-top: 16px;
    }
}

.InviteButtonClick {
    color: var(--color-white);
    align-self: flex-start;
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        padding: 12px 20px;
        margin-left: 10px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        padding: 14px 24px;
        margin-left: 12px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        padding: 16px 28px;
        margin-left: 14px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        padding: 18px 32px;
        margin-left: 16px;
    }
}

.DisabledBtn {
    cursor: default;
    background-color: var(--color-gray-border);

    &:hover {
        background-color: var(--color-gray-border);
    }
}

.Tooltip {
    font-family: var(--font-medium) !important;
    font-size: 14px !important;
    line-height: 18px !important;
    background: var(--color-dark-text) !important;
    border-radius: 6px !important;
}
