@import '../../../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  display: flex;
  align-items: end;
}

.InputContainer {
  width: 146px;


  @include media_desktop_qhd {
    width: 146px;
  }

  @include media_desktop_hd {
    width: 166px;
  }

  @include media_desktop_wxga {
    width: 206px;
  }

  @include media_desktop_full {
    width: 246px;
  }
}

.DropDownContainer {
  width: 146px;
  padding: 7px 14px 7px 13px;
  box-sizing: border-box;

  @include media_desktop_qhd {
    width: 146px;
    padding: 7px 14px 7px 13px;
  }

  @include media_desktop_hd {
    width: 166px;
    padding: 8px 15px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 206px;
    padding: 9px 16px 9px 15px;
  }

  @include media_desktop_full {
    width: 246px;
    padding: 10px 17px 10px 16px;
  }
}


.FilterContainerItem {
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  margin-right: 20px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
    margin-right: 20px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-right: 24px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin-right: 28px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin-right: 32px;
  }
}


.DropDownMenuContainer {
  width: 83%;

  @include media_desktop_qhd {
    width: 83%;
  }

  @include media_desktop_hd {
    width: 86%;
  }

  @include media_desktop_wxga {
    width: 87%;
  }

  @include media_desktop_full {
    width: 87%;
  }
}

.Input {
  display: flex;
  align-items: center;
  width: 120px;
  padding: 7px 0 7px 13px;
  border-radius: 6px;
  z-index: 2;
  font-family: var(--font-bold);
  font-size: 20px;

  @include media_desktop_qhd {
    width: 120px;
    padding: 7px 0 7px 13px;
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    width: 166px;
    padding: 8px 16px 8px 20px;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    width: 216px;
    padding: 9px 0 9px 15px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    width: 246px;
    padding: 10px 0 10px 16px;
    font-size: 20px;
    line-height: 24px;
  }

  &::placeholder {
    color: var(--color-dark-text);
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
      font-size: 12px;
      line-height: 16px;
    }

    @include media_desktop_hd {
      font-size: 14px;
      line-height: 18px;
    }

    @include media_desktop_wxga {
      font-size: 18px;
      line-height: 22px;
    }

    @include media_desktop_full {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.Title {
  font-family: var(--font-regular);
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-title);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.ResetBtn {
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    height: 35px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    height: 42px;
  }

   @include media_desktop_full {
     font-size: 20px;
     line-height: 24px;
     height: 46px;
   }
}