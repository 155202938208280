@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.TitleText {
  font-family: var(--font-medium);

  @include media_desktop_qhd {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.SummaryText {
  font-family: var(--font-medium);
  display: block;
  margin: 25px 0 40px 0;
}

.DoneButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}