@import '../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../assets/scss/medias';

.ContainerMenu {
  position: absolute;
  background: var(--color-white);
  border: 1px solid var(--color-gray-border);
  box-sizing: border-box;
  border-radius: 6px;
  right: 6px;
  top: 34px;

  @include media_desktop_hd {
    width: 206px;
    max-height: 148px;
    padding: 10px 6px 26px 18px;
  }

  @include media_desktop_wxga {
    width: 226px;
    max-height: 166px;
    padding: 12px 6px 28px 20px;
  }

  @include media_desktop_full {
    width: 246px;
    max-height: 184px;
    padding: 14px 6px 30px 22px;
  }
}

.SubMenu {
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;

  @include media_desktop_hd {
    max-height: 90px;
    padding-right: 12px;
  }

  @include media_desktop_wxga {
    max-height: 100px;
    padding-right: 14px;
  }

  @include media_desktop_full {
    max-height: 120px;
    padding-right: 16px;
  }
}

.TitleMenu {
  position: relative;
  font-family: var(--font-medium);
  color: var(--color-gray-title);
  text-align: left;

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  @include media_desktop_wxga {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 14px;
  }

  @include media_desktop_full {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

.WrapperTextImgMenu {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-medium);

  &:hover {
    color: var(--color-tomato);
    svg {
      fill: var(--color-tomato);
    }
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }

  &:not(:last-child) {
    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
    }
  }
}

.ActivePipeline {
  color: var(--color-tomato);

  svg {
    fill: var(--color-tomato) !important;
  }
}

.PipelinesItem {
  @include media_desktop_hd {
    max-width: 150px;
  }

  @include media_desktop_wxga {
    max-width: 160px;
  }

  @include media_desktop_full {
    max-width: 170px;
  }
}

.ImageMenu {
  svg {
    fill: var(--color-dark-text);

    &:hover {
      fill: var(--color-tomato);
    }
  }
}