.VideoContainer {
    overflow: hidden;
    position: relative;
}

.Video {
    display: block;
    border: none;
    user-select: none;
    outline: none;
}