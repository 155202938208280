@import '../../../../../../../assets/scss/helpers';
@import '../../../../../../../assets/scss/medias';

.ModuleItemContainer {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--color-light-background);
        color: var(--color-tomato);

        .VisibleActionButton {
            visibility: visible;
        }
    }
}

.DragAndDropButton {
    cursor: grab;
    width: 20px;
    height: 20px;
}

.DeleteButton {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.DisabledBtn {
    cursor: not-allowed;
}

.VisibleActionButton {
    visibility: hidden;
    margin-right: px-to-vh(5px);

    svg {
        fill: var(--color-silver);

        @include media_desktop_qhd {
            width: 18px;
            height: 18px;
        }

        @include media_desktop_hd {
            width: 20px;
            height: 20px;
        }

        @include media_desktop_wxga {
            width: 22px;
            height: 22px;
        }

        @include media_desktop_full {
            width: 24px;
            height: 24px;
        }
    }
}

.DragAndDrop {

    @include media_desktop_hd {
        height: 18px;
        width: 18px;
    }

    @include media_desktop_wxga {
        height: 21px;
        width: 21px;
    }

    @include media_desktop_full {
        height: 24px;
        width: 24px;
    }
}

.HideActionButton {
    cursor: not-allowed;
}

.ActiveModule {
    background-color: var(--color-light-background);
    Input {
        color: var(--color-tomato);
    }
}

.ModuleName {
    display: flex;
    outline: none;
    user-select: none;
    cursor: pointer;
}

.Input {
    border: none;
    background-color: transparent;
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        font-size: 16px;
        line-height: 24px;
        padding: px-to-vh(16px) 0 px-to-vh(16px) px-to-vh(14px);
    }

    @include media_desktop_hd {
        font-size: 18px;
        line-height: 26px;
        padding: px-to-vh(18px) 0 px-to-vh(18px) px-to-vh(12px);
    }

    @include media_desktop_wxga {
        font-size: 20px;
        line-height: 26px;
        padding: px-to-vh(20px) 0 px-to-vh(20px) px-to-vh(28px);
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        padding: px-to-vh(22px) 0 px-to-vh(22px) px-to-vh(22px);
    }
}
.TextModuleName {
    border: none;
    background-color: transparent;
    font-family: var(--font-medium);
    word-wrap: break-word;

    @include media_desktop_qhd {
        font-size: 16px;
        line-height: 24px;
        padding: px-to-vh(16px) px-to-vh(4px) px-to-vh(16px) px-to-vh(14px);
    }

    @include media_desktop_hd {
        font-size: 18px;
        line-height: 26px;
        width: 184px;
        padding: px-to-vh(18px) px-to-vh(6px) px-to-vh(18px) px-to-vh(18px);
    }

    @include media_desktop_wxga {
        font-size: 20px;
        line-height: 26px;
        width: 210px;
        padding: px-to-vh(20px) px-to-vh(8px) px-to-vh(20px) px-to-vh(22px);
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        width: 230px;
        padding: px-to-vh(22px) px-to-vh(10px) px-to-vh(22px) px-to-vh(36px);
    }
}