@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.TextEditor {
    padding: 0 5px;
    outline: none;
    text-align: left;
    white-space: pre-wrap;
    user-select: text;
    background: var(--color-white);
    border-radius: 6px;
    box-sizing: border-box;

    div {
        border: none !important;
        font-size: 16px !important;

        &:before {
            font-style: normal !important;
        }
    }

    @include media_desktop_qhd {
        width: 540px;
    }

    @include media_desktop_hd {
        width: 873px;
    }

    @include media_desktop_wxga {
        width: 980px;
    }

    @include media_desktop_full {
        width: 1150px;
    }
}