@import '../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../assets/scss/medias';

.MainContainer {
  width: 90%;
  height: 90%;
  background: var(--color-gray-background-card);
  overflow: auto;

  @include media_desktop_hd {
    padding: 15px 15px 122px 53px;
  }

  @include media_desktop_wxga {
    padding: 25px 25px 125px 56px;
  }

  @include media_desktop_full {
    padding: 35px 35px 128px 59px;
  }
}

.Wrapper {
  user-select: none;

  @include media_desktop_hd {
    padding-right: 38px;
  }

  @include media_desktop_wxga {
    padding-right: 31px;
  }

  @include media_desktop_full {
    padding-right: 24px;
  }
}

.Title {
  font-family: var(--font-medium);
  text-align: left;

  @include media_desktop_hd {
    font-size: 23px;
    line-height: 29px;
    margin-bottom: 30px;
  }

  @include media_desktop_wxga {
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 35px;
  }

  @include media_desktop_full {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 40px;
  }

  span {
    &:first-child {
        margin-right: 20px;
    }
  }
}
