@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.ContentItem {
    display: grid;
    grid-template-columns: 25% 25% 25% 24% 1%;
    width: 100%;
    border-radius: 6px;
    background: var(--color-white);
    font-family: var(--font-medium);
    padding: 9px 34px 9px 24px;
    box-sizing: border-box;
    margin-bottom: 10px;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        align-self: center;
        max-width: 100px;
        font-size: 12px;
        line-height: 16px;

          @include media_desktop_qhd {
              max-width: 120px;
              font-size: 12px;
              line-height: 16px;
          }

          @include media_desktop_hd {
              max-width: 140px;
              font-size: 14px;
              line-height: 18px;
          }

          @include media_desktop_wxga {
              max-width: 180px;
              font-size: 18px;
              line-height: 22px;
          }

          @include media_desktop_full {
              max-width: 236px;
              font-size: 20px;
              line-height: 24px;

          }
    }
    @include media_desktop_qhd {
        padding: 10px 34px 10px 24px;
    }

    @include media_desktop_hd {
        padding: 12px 34px 12px 24px;
    }

    @include media_desktop_wxga {
        padding: 14px 34px 14px 24px;
    }

    @include media_desktop_full {
        padding: 16px 34px 16px 24px;
    }
}

.ContentItemPipedrive {
    cursor: pointer;
    border-left: 5px solid var(--color-tomato);

    @include media_desktop_qhd {
        padding: 10px 34px 10px 19px;
    }

    @include media_desktop_hd {
        padding: 12px 34px 12px 19px;
    }

    @include media_desktop_wxga {
        padding: 14px 34px 14px 19px;
    }

    @include media_desktop_full {
        padding: 16px 34px 16px 19px;
    }
}

.CourseList {
    display: flex;
    align-self: center;
    flex-direction: column;
}

.WrapperForCourseName {
    display: flex;

    &:hover{
        .ImageReset {
            visibility: visible;
        }
    }
}

.ImageResetWrapper {
    display: flex !important;
    margin-left: 10px;
}

.ImageReset {
    cursor: pointer;
    visibility: hidden;

    @include media_desktop_hd {
        width: 15px;
        height: 15px;
    }

    @include media_desktop_wxga {
        width: 20px;
        height: 20px;
    }

    @include media_desktop_full {
        width: 22px;
        height: 22px;
    }
}

.ImageContainer {
    position: relative;
    align-self: center;
    justify-self: center;
    cursor: pointer;
}

.ImgDots {
    color: var(--color-gray-title);
    width: 17px;

    @include media_desktop_qhd {
       width: 17px;
    }

    @include media_desktop_hd {
       width: 20px;
    }

    @include media_desktop_wxga {
       width: 23px;
    }

    @include media_desktop_full {
       width: 26px;
    }
}

.Modal {
    position: absolute;
    top: 11px;
    align-items: center;
    padding: 11px 11px 11px 14px;
    width: max-content;
    background-color: var(--color-white);
    border-radius: 6px;
    border: 1px solid #CBC3C1;
    z-index: 1;

    @include media_desktop_qhd {
        padding: 11px 11px 11px 14px;
    }

    @include media_desktop_hd {
        padding: 13px 13px 13px 16px;
        width: 100px;
        left: -50px;
    }

    @include media_desktop_wxga {
        padding: 15px 15px 15px 18px;
        width: 120px;
        left: -64px;
    }

    @include media_desktop_full {
        padding: 17px 17px 17px 20px;
        width: 134px;
        left: -74px;
    }
}

.WrapperModalItem {
    display: flex;
    align-items: center;
    font-family: var(--font-regular);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }

    &:hover {
        color: var(--color-tomato);
        svg {
            fill: var(--color-tomato-hover);
        }
    }

    &:not(:last-child) {
        @include media_desktop_hd {
            margin-bottom: 16px;
        }

        @include media_desktop_wxga {
            margin-bottom: 20px;
        }

        @include media_desktop_full {
            margin-bottom: 24px;
        }
    }
}


.ImageModal {
    width: 10px;
    height: 14px;

    @include media_desktop_hd {
        width: 12px;
        height: 16px;
        margin-right: 15px;
    }

    @include media_desktop_wxga {
        width: 14px;
        height: 18px;
        margin-right: 17px;
    }

    @include media_desktop_full {
        width: 16px;
        height: 21px;
        margin-right: 19px;
    }
    svg {
        width: 10px;
        height: 14px;
        fill: var(--color-silver);


        @include media_desktop_hd {
            width: 13px;
            height: 17px;
        }

        @include media_desktop_wxga {
            width: 15px;
            height: 18px;
        }

        @include media_desktop_full {
            width: 16px;
            height: 21px;
        }
        &:hover {
            fill: var(--color-tomato-hover);
        }
    }
}

.Tooltip {
    z-index: 1 !important;
    font-family: var(--font-medium) !important;
    font-size: 14px !important;
    line-height: 18px !important;
    background: var(--color-dark-text) !important;
    border-radius: 6px !important;
}