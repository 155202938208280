@import '../../../../../../../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../../../../../../../assets/scss/medias';

.Item {
  background: var(--color-white);
  border-radius: 6px;
  display: grid;
  grid-template-columns: 15% 20% 35% 20% 10%;
  font-family: var(--font-medium);
  align-items: center;
  text-align: left;
  margin-bottom: 16px;

  @include media_desktop_hd {
    padding: 32px 12px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    padding: 34px 14px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    padding: 36px 16px;
    font-size: 20px;
    line-height: 24px;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.Name {
  @include media_desktop_hd {
    max-width: 140px;
  }

  @include media_desktop_wxga {
    max-width: 170px;
  }

  @include media_desktop_full {
    max-width: 216px;
  }
}

.Email {
  @include media_desktop_hd {
    max-width: 200px;
  }

  @include media_desktop_wxga {
    max-width: 250px;
  }

  @include media_desktop_full {
    max-width: 290px;
  }
}

.WrapperImg {
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: end;
  align-items: center;

  &:not(:first-child) {
    padding-top: 9px;
  }
  &:not(:last-child) {
    padding-bottom: 9px;
    border-bottom: 0.5px solid var(--color-border-grey);
  }
}

.ImgDropDown {
  margin: 0 0 2px 5px;

  svg {
    fill: var(--color-dark-text);
  }
}

.GapsColor {
  color: var(--color-light-red);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:not(:first-child) {
    padding-top: 10px;
  }
  &:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 0.5px solid var(--color-border-grey);
  }
}

.DetailsColor {
  padding-left: 16px;
  color: var(--color-silver);
  &:not(:first-child) {
    padding-top: 10px;
  }
  &:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 0.5px solid var(--color-border-grey);
  }
}


.ContainerAction {
  top: 0;
  right: -4px;
}