@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.PopupContainer {
    @include media_desktop_qhd {
        padding: 18px 18px 30px 18px;
        width: 633px;
    }

    @include media_desktop_hd {
        padding: 30px 30px 40px 30px;
        width: 663px;
    }

    @include media_desktop_wxga {
        padding: 35px 35px 45px 35px;
        width: 693px;
    }

    @include media_desktop_full {
        padding: 40px 40px 50px 40px;
        width: 723px;
    }
}

.MainContainer {
    box-sizing: border-box;
    padding: 0 30px;
}

.TitleText {
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
    }
}

.Seats {
    font-family: var(--font-medium);
    color: var(--color-silver);

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-top: 35px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-top: 40px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-top: 45px;
    }
}

.Upgrade {
    text-decoration: underline;
    color: var(--color-tomato);
    cursor: pointer;
}