@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.FilterComponentWrapper {
    margin-right: 24px;

    @include media_desktop_qhd {
      margin-right: 26px;
    }

    @include media_desktop_hd {
      margin-right: 28px;
    }

    @include media_desktop_wxga {
      margin-right: 30px;
    }

    @include media_desktop_full {
      margin-right: 32px;
    }
}

.TitleFilter {
  font-family: var(--font-regular);
  color: var(--color-gray-title);
  font-size: 12px;
  line-height: 14px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 14px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 17px;
  }

  @include media_desktop_wxga {
    font-size: 17px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.DropDownContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  padding: 7px 10px 7px 10px;
  font-family: var(--font-medium);
  border: 1px solid var(--color-gray-border);
  background: var(--color-white);
  border-radius: 6px;
}

.DropDownMenuContainer {
  border: 1px solid var(--color-light-grey);
  background: white;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 16px 4px 16px 14px;
  left: 0;
  top: 130%;
  height: max-content;

  @include media_desktop_wxga {
    padding: 16px 4px 16px 14px;
  }

  @include media_desktop_hd {
    padding: 18px 6px 18px 16px;
  }

  @include media_desktop_wxga {
    padding: 22px 8px 22px 18px;
  }

  @include media_desktop_full {
    padding: 24px 10px 24px 20px;
  }
}

.InputContainer {
  position: relative;
  border-radius: 6px;
  margin-top: 8px;
}

.Input {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  z-index: 2;
  font-family: var(--font-medium);
  width: 146px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::placeholder {
    color: var(--color-black);
    font-family: var(--font-medium);
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
      font-size: 12px;
      line-height: 16px;
    }

    @include media_desktop_hd {
      font-size: 14px;
      line-height: 18px;
    }

    @include media_desktop_wxga {
      font-size: 18px;
      line-height: 22px;
    }

    @include media_desktop_full {
      font-size: 20px;
      line-height: 24px;
    }
  }

  @include media_desktop_qhd {
    width: 146px;
    font-size: 12px;
    padding: 7px 25px 7px 13px;
  }

  @include media_desktop_hd {
    width: 166px;
    font-size: 14px;
    padding: 8px 25px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 206px;
    font-size: 18px;
    padding: 9px 25px 9px 15px;
  }

  @include media_desktop_full {
    width: 246px;
    font-size: 20px;
    padding: 10px 25px 10px 16px;
  }
}

.WrapperForLoader {
  text-align: center;
}

.Image {
  z-index: 0;
  position: absolute;
  bottom: 28%;
  right: 6px;
  cursor: pointer;

  @include media_desktop_hd {
    width: 20px;
    height: 20px;
    bottom: 8px;
    right: 6px;
  }

  @include media_desktop_wxga {
    width: 23px;
    height: 23px;
    bottom: 10px;
    right: 8px;
  }

  @include media_desktop_full {
    width: 26px;
    height: 26px;
    bottom: 10px;
    right: 10px;
  }
}

.ImageClose {

  @include media_desktop_hd {
    right: 4px;
  }

  @include media_desktop_wxga {
    right: 5px;
  }

  @include media_desktop_full {
    right: 6px;
  }
}

.DropDownOverflow {
  max-height: 100px;
  overflow-y: auto;
}

.ActiveDropDown {
  border: 1px solid #F85A40;
}

.OptionsWrapper {
  &:not(:last-child) {
    margin-bottom: 10px;

    @include media_desktop_qhd {
      margin-bottom: 10px;
    }

    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
    }
  }
}

.InputNotFound {
  font-family: var(--font-medium);
  font-size: 14px;
}