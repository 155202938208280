@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.WrapperSubtitleAndPublicLink {
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    margin-top: 32px;
  }

  @include media_desktop_wxga {
    margin-top: 34px;
  }

  @include media_desktop_full {
    margin-top: 36px;
  }
}

.SubtitleLink {

  @include media_desktop_hd {
    max-width: 200px;
    margin-right: 80px;
  }

  @include media_desktop_wxga {
    max-width: 220px;
    margin-right: 85px;
  }

  @include media_desktop_full {
    max-width: 233px;
    margin-right: 90px;
  }
}

.PartnerText {
  font-family: var(--font-medium);
  margin-bottom: 8px;

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_wxga {
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.Description {
  font-family: var(--font-regular);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.PublicInputField {
  box-sizing: border-box;
  border-right: none;
  border-radius: 6px 0  0 6px;
  font-family: var(--font-medium);

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    min-width: 400px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    min-width: 450px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    min-width: 500px;
  }
}

.CopyPublicLinkContainer {
  border: 2px solid var(--color-tomato);
  border-radius: 0 6px 6px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @include media_desktop_qhd {
    padding: 5px 20px 5px 18px;
  }

  @include media_desktop_hd {
    padding: 7px 24px 7px 20px;
  }

  @include media_desktop_wxga {
    padding: 9px 28px 9px 22px;
  }

  @include media_desktop_full {
    padding: 11px 32px 11px 24px;
  }
}


.CopyImage {
  @include media_desktop_qhd {
    height: 26px;
    width: 26px;
  }

  @include media_desktop_hd {
    height: 28px;
    width: 28px;
  }

  @include media_desktop_wxga {
    height: 30px;
    width: 30px;
  }

  @include media_desktop_full {
    height: 32px;
    width: 32px;
  }
}

.WrapperPublicLink {
  display: flex;
  align-items: center;
  height: min-content;

  @include media_desktop_qhd {
    margin-top: 10px;
  }

  @include media_desktop_hd {
    margin-top: 12px;
  }

  @include media_desktop_wxga {
    margin-top: 14px;
  }

  @include media_desktop_full {
    margin-top: 16px;
  }
}

.WrapperForHint {
  font-family: var(--font-regular);
  text-align: center;
  font-size: 14px;
  width: 80px;
  margin-left: 10px;
  padding: 10px 10px;
  border-radius: 3px;
  color: white;
  background-color: #222;
  opacity: 0.9;

  @include media_desktop_qhd {
    margin-top: 10px;
  }

  @include media_desktop_hd {
    margin-top: 12px;
  }

  @include media_desktop_wxga {
    margin-top: 14px;
  }

  @include media_desktop_full {
    margin-top: 16px;
  }
}