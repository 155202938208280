@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.EssayContainer {
    display: flex;
    align-items: center;
}

.WrapperInputField {
    border: 1px solid var(--color-gray-border);
    border-radius: 6px;
    padding: 4px;

    @include media_desktop_qhd {
        width: 483px;
    }

    @include media_desktop_hd {
        width: 483px;
    }

    @include media_desktop_wxga {
        width: 516px;
    }

    @include media_desktop_full {
        width: 555px;
    }
}

.InputField {
    box-sizing: border-box;
    min-height: 28px !important;
    max-height: 110px;
    overflow-y: auto;
    font-family: var(--font-medium);
    width: 100%;
    outline: 0;
    border: none;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        padding: 6px 46px 6px 6px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        padding: 8px 46px 8px 8px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        padding: 10px 46px 10px 10px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        padding: 12px 46px 12px 12px;
    }
}

.newImage {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 6px;
    position: absolute;

    @include media_desktop_hd {
        top: 7px;
        right: 30px;
    }

    @include media_desktop_wxga {
        top: 10px;
        right: 23px;
    }

    @include media_desktop_full {
        top: 13px;
        right: 30px;
    }
}

.DeleteImg {
    border-radius: 50%;
    background: var(--color-tomato);
    padding: 4px;
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 99;
    cursor: pointer;

    @include media_desktop_hd {
        top: 3px;
        right: 23px;
    }

    @include media_desktop_wxga {
        top: 5px;
        right: 17px;
    }

    @include media_desktop_full {
        top: 7px;
        right: 23px;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.WrapperImgAdd {
    position: absolute;
    cursor: pointer;
    visibility: hidden;

    @include media_desktop_hd {
        right: 35px;
        top: 14px;
    }

    @include media_desktop_wxga {
        right: 28px;
        top: 17px;
    }

    @include media_desktop_full {
        right: 35px;
        top: 21px;
    }

    svg {
        fill: var(--color-silver);

        &:hover {
            fill: var(--color-tomato);
        }
    }
}

.WrapperImg {
    position: relative;
    width: 85%;

    &:hover {
        .WrapperImgAdd {
            visibility: visible;
        }
    }

    @include media_desktop_qhd {
        margin-bottom: 10px;
    }

    @include media_desktop_hd {
        margin-bottom: 12px;
    }

    @include media_desktop_wxga {
        margin-bottom: 14px;
    }

    @include media_desktop_full {
        margin-bottom: 16px;
    }
}
