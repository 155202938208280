@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.ModalContainer {
    position: absolute;
    border: 1px solid var(--color-gray-border);
    font-family: var(--font-medium);
    color: var(--color-dark-text);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 4;
    background-color: var(--color-white);
    box-sizing: border-box;
    letter-spacing: 0.01em;
    text-align: left;

    @include media_desktop_qhd {
        width: 150px;
        height: 108px;
        padding: px-to-vh(12px);
    }

    @include media_desktop_hd {
        width: 175px;
        height: 128px;
        padding: 16px;
        bottom: -100px;
        left: -80px;
    }

    @include media_desktop_wxga {
        width: 238px;
        height: 168px;
        bottom: -130px;
        left: -130px;
        padding: 18px;
    }

    @include media_desktop_full {
        width: 255px;
        height: 184px;
        padding: 24px;
        bottom: -130px;
        left: -110px;
    }
}

.TitleModalContainer {

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 8px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 16px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.ModalColorChoose {
    display: grid;

    @include media_desktop_hd {
        grid-gap: 6px;
        grid-template-columns: repeat(auto-fill, minmax(18px, 1fr));
    }

    @include media_desktop_wxga {
        grid-gap: 8px;
        grid-template-columns: repeat(auto-fill, minmax(26px, 1fr));
    }
}

.ButtonChoose {
    border-radius: 50%;
    background: var(--color-light-grey);
    cursor: pointer;
    position: relative;
    display: flex;

    &:hover {
        border: 2px solid var(--color-gray-title);
    }

    @include media_desktop_qhd {

    }

    @include media_desktop_hd {
        width: 18px;
        height: 18px;
    }

    @include media_desktop_wxga {
        width: 26px;
        height: 26px;
    }

    @include media_desktop_full {

    }
}

.IconPicture {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.ButtonContainer {
    display: flex;
    justify-content: space-between;

    @include media_desktop_hd {
        margin-top: 13px;
    }

    @include media_desktop_wxga {
        margin-top: 16px;
    }

    @include media_desktop_full {
        margin-top: 24px;
    }
}

.Button {
    display: flex;
    cursor: pointer;

    &:hover {
        color: var(--color-tomato);
        svg {
            fill: var(--color-tomato-hover);
        }
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.ButtonImg {
    svg {
        fill: var(--color-silver);

        @include media_desktop_hd {
            height: 18px;
            width: 10px;
            margin-right: 7px;
        }

        @include media_desktop_wxga {
            height: 19px;
            width: 13px;
        }

        @include media_desktop_full {
            height: 22px;
            width: 13px;
        }
    }
}

