@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.Container {
  background: #171413;
  cursor: default;
  color: var(--color-white);
  border-radius: 6px;
  position: absolute;
  top: 35px;
  left: -19px;
  box-sizing: border-box;
  z-index: 99;

  @include media_desktop_hd {
    width: 697px;
    padding: 26px 30px 44px 30px;
  }

  @include media_desktop_wxga {
    width: 727px;
    padding: 28px 32px 46px 32px;
  }

  @include media_desktop_full {
    width: 777px;
    padding: 30px 34px 48px 34px;
  }
}

.LightImg {
  svg {
    fill: var(--color-white);
    @include media_desktop_hd {
      padding: 0 1px;
    }

    @include media_desktop_wxga {
      padding: 2px 1px;
    }

    @include media_desktop_full {
      padding: 2px 2px 5px 2px;
    }
  }
}

.Arrow {
  position: absolute;
  background: #171413;
  width: 26.94px;
  height: 26.94px;
  transform: matrix(0.71, -0.7, 0.71, 0.71, 0, 0);
  top: -10px;
  left: 27px;
}

.Title {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.Subtitle {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 26px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 28px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}

.WrapperImgTitle {
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    margin-bottom: 18px;
  }

  @include media_desktop_wxga {
    margin-bottom: 20px;
  }

  @include media_desktop_full {
    margin-bottom: 22px;
  }
}

.WrapperImg {
  cursor: pointer;
  border: 1px solid var(--color-white);
  border-radius: 50%;
  height: 19px;
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-right: 12px;
}

.WrapperMetrics {
  display: grid;

  @include media_desktop_hd {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    margin-bottom: 30px;
  }

  @include media_desktop_wxga {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    margin-bottom: 35px;
  }

  @include media_desktop_full {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    margin-bottom: 40px;
  }
}

.Item {
  box-sizing: border-box;
  border: 1px solid var(--color-white);
  border-radius: 6px;

  @include media_desktop_hd {
    width: 140px;
    height: 74px;
  }

  @include media_desktop_wxga {
    width: 150px;
    height: 76px;
  }

  @include media_desktop_full {
    width: 160px;
    height: 78px;
  }
}

.WrapperNameDiamondImage {
  display: flex;
  align-items: center;
}

.TitleItem {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin: 7px 0 7px 12px;

  }

  @include media_desktop_wxga {
    font-size: 15px;
    line-height: 19px;
    margin: 9px 0 9px 14px;

  }

  @include media_desktop_full {
    font-size: 16px;
    line-height: 20px;
    margin: 11px 0 11px 16px;
  }
}

.Description {
  font-family: var(--font-regular);

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
    margin-left: 12px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
    margin-left: 14px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
    margin-left: 16px;
  }
}

.WrapperDiamondImage {
  margin-left: 4px;
  position: relative;
  cursor: pointer;
}

.DiamondImage {
  position: relative;
  bottom: 2px;
  svg {
    fill: var(--color-white);
    width: 13px;
    height: 11px;
  }
}

.ContainerHint {
  position: absolute;
  top: 34px;
  left: -10px;
  background: var(--color-white);
  border-radius: 6px;
  color: var(--color-dark-text);
  font-family: var(--font-regular);

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
    width: 243px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
    width: 253px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
    width: 263px;
  }
}

.TextHint {
  padding: 10px 30px;
}

.ArrowWhite {
  position: absolute;
  background: var(--color-white);
  width: 26.4px;
  height: 26.4px;
  transform: matrix(0.71, -0.7, 0.71, 0.71, 0, 0);
  top: -5px;
  left: 6px;
}

.WrapperConnectButton {
  display: flex;
  justify-content: center;
}

.ConnectButton {
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  color: var(--color-black);
  background: var(--color-white);
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    padding: 11px 28px;
    width: 220px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    padding: 13px 30px;
    width: 220px;
  }

  @include media_desktop_full {
    font-size: 20px;
    width: 230px;
    line-height: 24px;
    padding: 15px 32px;
  }

  &:hover {
    background: var(--color-light-grey);
  }
}