@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.QuestionsListContainer {
    display: block;
    overflow-x: scroll;
    width: 100%;
    max-height: 65%;
    box-sizing: border-box;

    @include media_desktop_qhd {
        padding-right: 18px;
        max-height: 65%;
    }

    @include media_desktop_hd {
        padding-right: 20px;
        max-height: 68%;
    }

    @include media_desktop_wxga {
        padding-right: 22px;
        max-height: 70%;
    }

    @include media_desktop_full {
        padding-right: 24px;
        max-height: 72%;
    }
}

.QuestionItem {
    display: flex;
    align-items: center;
    font-family: var(--font-medium);

    &:hover {
        .VisibleActionButton {
            visibility: visible;
        }
    }

    @include media_desktop_qhd {
        padding-top: 18px;
    }

    @include media_desktop_hd {
        padding-top: 20px;
    }

    @include media_desktop_wxga {
        padding-top: 22px;
    }

    @include media_desktop_full {
        padding-top: 24px;
    }
}

.QuestionNumber {
    border: 1px solid var(--color-gray-border);
    border-radius: 6px;
    padding: px-to-vh(4px) px-to-vh(10px);
    cursor: pointer;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
        margin-right: 10px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-right: 12px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-right: 14px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-right: 16px;
    }
}

.ActiveQuestionNumber {
    background-color: var(--color-tomato);
    border-color: var(--color-tomato);
    color: var(--color-white);
}

.QuestionTitle {
    color: var(--color-gray-border);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin-right: px-to-vh(5px);
    cursor: pointer;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.ActiveQuestionTitle {
    color: var(--color-tomato);
}

.VisibleActionButton {
    display: flex;
    visibility: hidden;
    margin-right: px-to-vh(5px);
    cursor: pointer;

    svg {
        fill: var(--color-silver);

        @include media_desktop_qhd {
            width: 18px;
            height: 18px;
        }

        @include media_desktop_hd {
            width: 20px;
            height: 20px;
        }

        @include media_desktop_wxga {
            width: 22px;
            height: 22px;
        }

        @include media_desktop_full {
            width: 24px;
            height: 24px;
        }
    }
}

//.HideActionButton {
//    cursor: not-allowed;
//}