$fontPath: '../fonts/';

@mixin font-face($family, $weight: normal, $style: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$fontPath}#{$family}.ttf') format('truetype'), url('#{$fontPath}#{$family}.ttf') format('truetype');
        font-weight: $weight;
        font-style: $style;
    }
}

@include font-face('Lato-Bold');
@include font-face('Lato-Light');
@include font-face('Lato-Medium');
@include font-face('Lato-Regular');
@include font-face('Lato-Semibold');
