@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.DescriptionText {
    display: block;
    margin-top: 20px;
    font-family: var(--font-regular);
    font-size: 18px;
}

.InputContainer {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.InputDescriptionText {
    display: flex;
    align-items: center;
}

.Icon {
    margin-right: 10px;

    @include media_desktop_qhd {
        margin-right: 10px;
    }

    @include media_desktop_hd {
        margin-right: 14px;;
    }

    @include media_desktop_wxga {
        margin-right: 20px;
    }

    @include media_desktop_full {
        margin-right: 27px;
    }
}

.DropDownIcon {
    transition: 0.4s;
}

.isActiveDropDownIcon {
    transition: 0.4s;
    transform: rotate(180deg);
}

.Slack {
    font-size: 14px;
    line-height: 16px;
    color: var(--color-blue);
    cursor: pointer;
    text-decoration: none;
    margin-left: 5px;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 16px;
        margin-left: 5px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-left: 6px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-left: 7px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-left: 8px;
    }
}
