@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.RecordedVideoPreviewVideo {
  width: 100%;
}

.RecordVideoPreviewHeader {
  font-family: var(--font-medium);
  color: var(--color-black);
  font-size: 24px;


  @include media_tablet {
    font-size: 18px;
  }

  @include media_desktop_qhd {
    font-size: 20px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
  }
}

.RecordedVideoPreviewButton {
  display: block;
  margin: 0 50px;
}
