@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  background: var(--color-white);
  border-radius: 6px;
  margin-left: 18px;
  box-sizing: border-box;

  @include media_desktop_hd {
    width: 205px;
    height: 333px;
    padding: 22px 21px 48px 21px;
  }

  @include media_desktop_hd-after {
    width: 225px;
    height: 341px;
    padding: 24px 23px 50px 23px;
  }

  @include media_desktop_wxga {
    width: 250px;
    height: 358px;
    padding: 26px 25px 52px 25px;
  }

  @include media_desktop_full {
    width: 275px;
    height: 375px;
    padding: 28px 27px 54px 27px;
  }
}

.WrapperTitleFilters {
  display: flex;
  justify-content: space-between;
}

.Title  {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 18px;
  }
}

.FiltersWrapper {
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    margin-bottom: 36px;
  }

  @include media_desktop_wxga {
    margin-bottom: 46px;
  }

  @include media_desktop_full {
    margin-bottom: 56px;
  }
}

.FiltersWrapperReports {
  margin-bottom: 20px;
}

.DropDownContainer {
  margin-top: 0;
  width: 113px;
  padding: 7px 14px 7px 13px;
  box-sizing: border-box;

  @include media_desktop_hd {
    width: 113px;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 15px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 123px;
    font-size: 13px;
    line-height: 18px;
    padding: 9px 16px 9px 15px;
  }

  @include media_desktop_full {
    width: 133px;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 17px 10px 16px;
  }
}

.DropDownMenuContainer {
  @include media_desktop_hd {
    width: 79%;
  }

  @include media_desktop_wxga {
    width: 77%;
  }

  @include media_desktop_full {
    width: 76%;
  }
}

.DropDownMenuContainerNotSelected {
  top: 115%;
}

.FilterContainerItem {
  font-size: 12px;
  line-height: 16px;
  margin-right: 20px;

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
    margin-right: 4px !important;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
    margin-right: 6px !important;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px !important;
  }
}

.OptionWrapper {
  margin-bottom: 8px !important;
}

.FilterContainerItemReports {
  @include media_desktop_hd {
    font-size: 12px;
    line-height: 14px;
    margin-right: 13px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 16px;
    margin-right: 15px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
    margin-right: 17px;
  }
}

.WrapperCalendar {
  z-index: 1;
}

.WrapperPrice {
  text-align: center;
}

.WrapperPriceReports {
  display: flex;
  justify-content: center;
}

.Price {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  @include media_desktop_wxga {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 22px;
  }

  @include media_desktop_full {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 24px;
  }
}

.PriceReports {
  margin-right: 20px;
  margin-bottom: 15px;
}

.Percent {
  font-family: var(--font-medium);
  border: 1px solid var(--color-green);
  box-sizing: border-box;
  color: var(--color-green);
  padding: 8px 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 52px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 60px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 68px;
  }
}

.PercentReports {
  margin: 0;
  height: 30px;
}

.ComparisonText {
  text-align: center;
  font-family: var(--font-regular);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
  }
}