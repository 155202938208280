@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.Input {
    width: 100%;
    border-radius: 6px;
    padding: px-to-vh(16px);
    outline: 0;
    border: 1px solid var(--color-gray-border);

    @include media_desktop_qhd {
        padding: 10px;
    }

    @include media_desktop_hd {
        padding: 12px;
    }

    @include media_desktop_wxga {
        padding: 14px;
    }

    @include media_desktop_full {
        padding: 16px;
    }
}