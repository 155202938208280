@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.MainContainer {
    display: grid;
    grid-template-columns: 75% 25%;
    width: 100%;

    @include media_desktop_qhd {
        margin-top: 18px;
    }

    @include media_desktop_hd {
        margin-top: 20px;
    }

    @include media_desktop_wxga {
        margin-top: 22px;
    }

    @include media_desktop_full {
        margin-top: 24px;
    }
}

.ChangeContainer {
    text-align: left;
}

.SliderContainer {
    display: flex;
    align-items: center;
}

.DescriptionText {
    display: block;
    font-family: var(--font-regular);
    color: var(--color-silver);
    margin-top: px-to-vh(8px);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.InputContainer {
    display: flex;
    align-items: center;

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.InputDescription {
    display: block;
    margin-left: px-to-vh(8px);
    color: var(--color-silver);
    font-family: var(--font-regular);
}