#root {
    --color-tomato: #f85a40;
    --color-tomato-hover: #ffbaae;
    --color-tomato-active: #ff3e1e;
    --color-light-background: #f8f6f5;
    --color-tomato-light: #ff795e;
    --color-black: black;
    --color-title-grey: #343434;
    --color-gray-bronze: #424242;
    --color-gray: #959595;
    --color-silver: #8c8c8c;
    --color-light-grey: #c4c4c4;
    --color-pure-grey: #fafafa;
    --color-light: #f8f9fa;
    --color-white: white;
    --color-custom-brand-opacity: #fee6e2;
    --color-modal-error-background: #ffbfbf;
    --color-modal-error-text: #cc3833;
    --color-sun: #ffc700;
    --color-green: #05c03c;
    --color-green-light: #74D991;
    --color-red: #ff0000;
    --color-light-red: #FB5E5E;
    --color-space: #eeeeee;
    --color-blue: #006fff;
    --color-dark-text: #161413;
    --color-gray-border: #cbc3c1;
    --color-gray-title:  #8c8c8c;
    --color-sakura: #ffbaae;
    --color-hover-btn: #c84934;
    --color-background-blue: #0253ff;
    --color-light-tomato: #ffbaae;
    --color-gray-background-card: #f8f6f5;
    --color-light-green: #c7efd3;
    --color-border-grey: #CBC3C2;
    --color-light-yellow: #FFECAA;
    --color-yellow: #FADA6A;

    --font-bold: Lato-Bold;
    --font-medium: Lato-Medium;
    --font-regular: Lato-Regular;
    --font-light: Lato-Light;
    --font-semibold: Lato-Semibold;
}