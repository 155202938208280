@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.MainContainerDesktop {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;

  @include media_tablet {
    align-items: center;
  }
}

.WrapperGoBack {
  display: flex;
  align-items: center;
  padding: 25px 0 25px 25px;

  &:hover {
    cursor: pointer;
    color: var(--color-tomato);
    svg {
      fill: var(--color-tomato);
    }
  }
}

.TextGoBack {
  font-family: var(--font-regular);
  margin-left: 8px;
  font-size: 18px;
  line-height: 22px;

  @include media_desktop_hd {
    margin-left: 6px;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    margin-left: 7px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    margin-left: 8px;
    font-size: 18px;
    line-height: 22px;
  }
}

.ImageMenu {
  display: flex;
  align-items: center;

  svg {
    @include media_desktop_hd {
      width: 8px;
      height: 11px;
    }

    @include media_desktop_wxga {
      width: 9px;
      height: 12px;
    }

    @include media_desktop_full {
      width: 10px;
      height: 13px;
    }
    fill: var(--color-dark-text);
  }

  @include media_desktop_hd {
    width: 8px;
    height: 12px;
  }

  @include media_desktop_wxga {
    width: 9px;
    height: 13px;
  }

  @include media_desktop_full {
    width: 10px;
    height: 14px;
  }
}

.MainContainerCalendar {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
}

.MainContainerMobile {
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: auto;
}

.LogoImage {
  width: 136px;
  height: 37px;
  padding: 25px 0 0 25px;
}

.BackgroundImage {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.Wrapper {
  //margin-top: 50px;

  @include media_desktop_hd {
    padding: 0 0 0 115px;
    display: flex;
    align-items: center;
    margin-top: 0;
  }

  @include media_desktop_wxga {
    padding: 0 155px;
    display: flex;
    align-items: center;
    margin-top: 0;

  }

  @include media_desktop_full {
    padding: 0 165px;
    display: flex;
    align-items: center;
    margin-top: 0;
  }
}

.WrapperCalendar {
  padding: 0;
}
