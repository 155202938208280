@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.MainContainer {
  @include media_desktop_hd {
    padding: 20px 20px;
    width: 650px;
  }

  @include media_desktop_wxga {
    padding: 30px 25px;
    width: 700px;
  }

  @include media_desktop_full {
    padding: 40px 30px;
    width: 760px;
  }
}

.Title {
  margin: 0 auto;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
    max-width: 330px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 14px;
    max-width: 350px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
    max-width: 380px;
  }
}

.Description {
  margin: 0 auto;
  font-family: var(--font-regular);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    max-width: 400px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    max-width: 450px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    max-width: 500px;
  }
}

.SaveButton {
  margin: 0 auto;

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.DisableSaveBtn {
  background: var(--color-gray-border);

  &:hover {
    background: var(--color-gray-border);
    cursor: default;
  }
}

.DropDownContainer {
  width: 146px;
  padding: 7px 14px 7px 13px;
  box-sizing: border-box;

  @include media_desktop_hd {
    width: 166px;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 15px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 206px;
    font-size: 18px;
    line-height: 22px;
    padding: 9px 16px 9px 15px;
  }

  @include media_desktop_full {
    width: 246px;
    font-size: 20px;
    line-height: 24px;
    padding: 10px 17px 10px 16px;
  }
}

.DropDownContainerGray {
  color: var(--color-silver);
}

.WrapperFilter {
  @include media_desktop_hd {
    margin: 30px 0;
  }

  @include media_desktop_wxga {
    margin: 35px 0;
  }

  @include media_desktop_full {
    margin: 40px 0;
  }
}

.DropDownMenuContainer {
  width: 83%;

  @include media_desktop_qhd {
    width: 83%;
  }

  @include media_desktop_hd {
    width: 86%;
  }

  @include media_desktop_wxga {
    width: 87%;
  }

  @include media_desktop_full {
    width: 87%;
  }
}


.FilterContainerItem {
  font-size: 12px;
  line-height: 16px;

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}
