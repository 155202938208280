@import '../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../assets/scss/medias';

.TeamResults {
  font-family: var(--font-regular);
  margin: 0 auto;

  @include media_desktop_hd {
    font-size: 10px;
    line-height: 12px;
  }

  @include media_desktop_hd-after {
    font-size: 10px;
    line-height: 12px;
  }

  @include media_desktop_wxga {
    font-size: 11px;
    line-height: 13px;
  }

  @include media_desktop_full {
    font-size: 12px;
    line-height: 14px;
  }
}

.WrapperResults {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-silver);

  &:not(:last-child) {
    img {
      margin: 0 1px 0 2px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  span {
    &:not(:last-child) {
      margin-left: 7px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
}
