.DisabledSelect {
  background: var(--color-gray-border);
  border: 1px solid var(--color-gray-border);
  opacity: .4;
}

.Text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.DropDownIcon {
  transition: 0.4s;
}

.isActiveDropDownIcon {
  transition: 0.4s;
  transform: rotate(180deg);
}

.WrapperForLoader {
  text-align: center;
}