@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.WelcomeContainer {
    text-align: center;
    user-select: none;
    letter-spacing: 0.01em;

    @include media_desktop_hd {
        margin-top: px-to-vh(300px);
    }

    @include media_desktop_wxga {
        margin-top: px-to-vh(312px);
    }

    @include media_desktop_full {
        margin-top: px-to-vh(327px);
    }
}

.TitleText {
    font-family: var(--font-semibold);
    color: var(--color-tomato);
    line-height: 77px;

    @include media_desktop_hd {
        font-size: px-to-vh(60px);
    }

    @include media_desktop_wxga {
        font-size: px-to-vh(62px);
    }

    @include media_desktop_full {
        font-size: px-to-vh(64px);
    }
}

.Name {
    font-family: var(--font-semibold);
    color: var(--color-black);

    @include media_desktop_hd {
        font-size: px-to-vh(60px);
    }

    @include media_desktop_wxga {
        font-size: px-to-vh(62px);
    }

    @include media_desktop_full {
        font-size: px-to-vh(64px);
    }
}

.DescriptionText {
    font-family: var(--font-semibold);
    font-size: 18px;
    display: block;
    color: var(--color-gray-title);

    @include media_desktop_hd {
        max-width: px-to-vh(500px);
        margin: px-to-vh(16px) auto 0 auto;
        font-size: px-to-vh(20px);
        line-height: px-to-vh(30px);
    }

    @include media_desktop_wxga {
        max-width: px-to-vh(550px);
        margin: px-to-vh(20px) auto 0 auto;
        font-size: px-to-vh(22px);
        line-height: px-to-vh(30px);
    }

    @include media_desktop_full {
        max-width: px-to-vh(650px);
        margin: px-to-vh(24px) auto 0 auto;
        font-size: px-to-vh(24px);
        line-height: px-to-vh(30px);
    }
}

.Button {
    margin: 75px auto;
    background-color: var(--color-tomato);
    box-shadow: 0 4px 10px rgba(255, 66, 35, 0.15);
    color: var(--color-white);
    font-size: 16px;
    font-family: var(--font-medium);

    @include media_desktop_hd {
        font-size: px-to-vh(24px);
        line-height: px-to-vh(26px);
        margin: px-to-vh(52px) auto 0 auto;
        padding: px-to-vh(23px) px-to-vh(28px);
    }

    @include media_desktop_wxga {
        font-size: px-to-vh(26px);
        line-height: px-to-vh(30px);
        margin: px-to-vh(62px) auto 0 auto;
        padding: px-to-vh(25px) px-to-vh(30px);
    }

    @include media_desktop_full {
        font-size: px-to-vh(28px);
        line-height: px-to-vh(34px);
        margin: px-to-vh(76px) auto 0 auto;
        padding: px-to-vh(27px) px-to-vh(32px);
    }
}

.ButtonContainer {
    display: flex;
    align-items: center;

    span {
        margin-left: 16px;
    }
}

.PlusImage {
    @include media_desktop_hd {
        height: px-to-vh(30px);
        width: px-to-vh(30px);
    }

    @include media_desktop_wxga {
        height: px-to-vh(35px);
        width: px-to-vh(35px);
    }

    @include media_desktop_full {
        height: px-to-vh(40px);
        width: px-to-vh(40px);
    }
}