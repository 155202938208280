@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.MainContainer {
    width: 100%;
    margin-top: 27px;

    @include media_desktop_qhd {
        margin-top: 26px;
    }

    @include media_desktop_hd {
        margin-top: 33px;
    }

    @include media_desktop_wxga {
        margin-top: 30px;
    }

    @include media_desktop_full {
        margin-top: 47px;
    }
}

.ReportsHeader {
    display: grid;
    grid-template-columns: 17% 20% 20% 20% 15% 8%;
    padding: 0 16px 0 16px;
    margin-bottom: 16px;
    font-family: var(--font-regular);
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-silver);

    span {
        padding: 0 5px 0 5px;
        display: block;
    }

    @include media_desktop_qhd {
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        margin-bottom: 26px;
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        margin-bottom: 32px;
        font-size: 20px;
        line-height: 24px;
    }
}

//.ReportListContainer {
//    max-height: 45vh;
//    overflow-y: scroll;
//}