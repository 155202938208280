.ContentContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: var(--color-light-background);
    z-index: 2;
}

.LogoContainer {
    left: 65%;
}

.MainContainer {
    width: 61vw;
    height: 50vh;
}