@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.ContainerHome {
  @include media_desktop_qhd {
    padding: 26px 60px 20px 40px;
  }

  @include media_desktop_hd {
    padding: 36px 70px 30px 40px;
  }

  @include media_desktop_wxga {
    padding: 46px 80px 40px 40px;
  }

  @include media_desktop_full {
    padding: 56px 90px 40px 40px;
  }
}

.WrapperMainText {
  display: flex;
  align-items: center;
}

.Title {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.TitleImg {
  margin-left: 8px;

  @include media_desktop_hd {
    height: 20px;
  }

  @include media_desktop_wxga {
    height: 24px;
  }

  @include media_desktop_full {
    height: 26px;
  }
}

.PartnersNone {
    position: absolute;
    top: 28%;
    left: 43%;
    text-align: center;
    font-family: var(--font-medium);
    font-size: 22px;
    line-height: 28px;
    max-width: 500px;

    @include media_desktop_qhd {
      top: 28%;
      left: 43%;
      font-size: 22px;
      line-height: 28px;
    }

    @include media_desktop_hd {
      top: 35%;
      left: 43%;
      font-size: 24px;
      line-height: 30px;
    }

    @include media_desktop_wxga {
      top: 35%;
      left: 43%;
      font-size: 26px;
      line-height: 32px;
    }

    @include media_desktop_full {
      top: 40%;
      left: 43%;
      font-size: 28px;
      line-height: 34px;
    }
}


