@import '../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../assets/scss/medias';

.WrapperItem {
  background: var(--color-white);
  border-radius: 6px;
  display: grid;
  box-sizing: border-box;
  cursor: pointer;
  grid-template-columns: 30% 30% 30% 10%;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    margin-bottom: 28px;
    padding: 24px 14px 24px 12px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    margin-bottom: 30px;
    padding: 26px 16px 26px 14px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    margin-bottom: 32px;
    padding: 28px 18px 28px 16px;
    font-size: 20px;
    line-height: 24px;
  }

  span {
    display: flex;
    align-items: center;
  }
}

.Date {
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.Image {
  @include media_desktop_hd {
    margin-left: 30px;
  }

  @include media_desktop_wxga {
    margin-left: 34px;
  }

  @include media_desktop_full {
    margin-left: 36px;
  }
}

.CheckedImg {
  width: 14px;

  @include media_desktop_hd {
    width: 15px;
    height: 15px;
    margin-right: 9px;
  }

  @include media_desktop_wxga {
    width: 17px;
    height: 17px;
    margin-right: 11px;
  }

  @include media_desktop_full {
    width: 19px;
    height: 19px;
    margin-right: 13px;
  }
}

.PaginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @include media_desktop_qhd {
    margin-top: 20px;
  }

  @include media_desktop_qhd {
    margin-top: 24px;
  }

  @include media_desktop_wxga {
    margin-top: 28px;
  }

  @include media_desktop_full {
    margin-top: 34px;
  }
}

.TextNumberLearner {
  font-family: var(--font-regular);
  font-size: 9px;
  line-height: 17px;
  color: var(--color-gray-title);

  @include media_desktop_qhd {
    font-size: 9px;
    line-height: 17px;
  }

  @include media_desktop_hd {
    font-size: 13px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 15px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.ListPagination {
  margin: 8px 13px;
  font-family: var(--font-medium);
  color: var(--color-gray-title);
  line-height: 22px;
  font-size: 12px;
  cursor: pointer;

  @include media_desktop_qhd {
    line-height: 16px;
    font-size: 12px;
  }

  @include media_desktop_hd {
    line-height: 18px;
    font-size: 14px;
  }

  @include media_desktop_wxga {
    line-height: 20px;
    font-size: 16px;
  }

  @include media_desktop_full {
    line-height: 22px;
    font-size: 18px;
  }
}

.ContainerPaginate {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
}

.ActiveBtnPaginate {
  background: var(--color-tomato);
  color: var(--color-white);
  border-radius: 5px;
  padding: 8px 14px;
}

.Previous,
.Next {
  color: var(--color-tomato);
  font-family: var(--font-medium);
  font-size: 20px;
  cursor: pointer;

  @include media_desktop_qhd {
    font-size: 20px;
  }

  @include media_desktop_hd {
    font-size: 22px;
  }

  @include media_desktop_wxga {
    font-size: 24px;
  }

  @include media_desktop_full {
    font-size: 26px;
  }
}

.Previous {
  margin: 0 18px 4px 0;
}

.Next {
  margin: 0 0 4px 18px;
}

.Tooltip {
  font-family: var(--font-medium) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background: var(--color-dark-text) !important;
  border-radius: 6px !important;
}