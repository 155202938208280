@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.AudioContainer {
    user-select: none;
    display: flex;
    align-items: center;
}

.Audio {
    outline: none;
    display: block;

    @include media_desktop_qhd {
        width: 550px;
    }

    @include media_desktop_hd {
        width: 600px;
    }

    @include media_desktop_wxga {
        width: 650px;
    }

    @include media_desktop_full {
        width: 700px;
    }
}

.PlayBackText {
    margin-left: 10px;
    font-family: var(--font-medium);
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    @include media_desktop_qhd {
        font-size: 14px;
    }

    @include media_desktop_hd {
        font-size: 16px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
    }

    @include media_desktop_full {
        font-size: 20px;
    }
}