@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.TitleFilter {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-silver);
    font-family: var(--font-regular);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.InputContainer {
    margin-top: 8px;
    position: relative;
}

.Input {
    display: flex;
    align-items: center;
    width: 146px;
    padding: 7px 25px 7px 13px;
    border-radius: 6px;
    z-index: 2;
    font-family: var(--font-medium);
    font-size: 12px;
    box-sizing: border-box;
    border: 1px solid var(--color-gray-border);

    @include media_desktop_qhd {
        width: 146px;
        font-size: 12px;
        padding: 7px 25px 7px 13px;
    }

    @include media_desktop_hd {
        width: 145px;
        font-size: 14px;
        padding: 8px 25px 8px 14px;
    }

    @include media_desktop_wxga {
        width: 168px;
        font-size: 18px;
        padding: 9px 25px 9px 15px;
    }

    @include media_desktop_full {
        width: 246px;
        font-size: 20px;
        padding: 10px 25px 10px 16px;
    }

    &::placeholder {
        color: var(--color-black);
        font-family: var(--font-medium);
        font-size: 12px;
        line-height: 16px;

        @include media_desktop_qhd {
            font-size: 12px;
            line-height: 16px;
        }

        @include media_desktop_hd {
            font-size: 14px;
            line-height: 18px;
        }

        @include media_desktop_wxga {
            font-size: 18px;
            line-height: 22px;
        }

        @include media_desktop_full {
            font-size: 20px;
            line-height: 24px;
        }
    }
}


.Image {
    z-index: 0;
    position: absolute;
    bottom: 28%;
    right: 6px;
    cursor: pointer;

    @include media_desktop_hd {
        width: 20px;
        height: 20px;
        bottom: 8px;
        right: 6px;
    }

    @include media_desktop_wxga {
        width: 23px;
        height: 23px;
        bottom: 10px;
        right: 8px;
    }

    @include media_desktop_full {
        width: 26px;
        height: 26px;
        bottom: 10px;
        right: 10px;
    }
}

.ImageClose {

    @include media_desktop_hd {
        right: 4px;
    }

    @include media_desktop_wxga {
        right: 5px;
    }

    @include media_desktop_full {
        right: 6px;
    }
}

.OptionsText {
    font-family: var(--font-medium);
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.OptionsWrapper {
    &:not(:last-child) {
        margin-bottom: 10px;

        @include media_desktop_qhd {
            margin-bottom: 10px;
        }

        @include media_desktop_hd {
            margin-bottom: 12px;
        }

        @include media_desktop_wxga {
            margin-bottom: 14px;
        }

        @include media_desktop_full {
            margin-bottom: 16px;
        }
    }
}

.Options {
    font-family: var(--font-medium);
    padding: 5px 10px;
    margin: 5px 0;
    display: block;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.ActiveDropDown {
    border: 1px solid #F85A40;
    &::placeholder {
        color: var(--color-silver);
    }
}

.WrapperForLoader {
    text-align: center;
}

.InputNotFound {
    font-family: var(--font-medium);
    font-size: 14px;
}



