@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.MainPopupContainer {

  @include media_desktop_hd {
    width: 480px;
    padding: 12px;
  }

  @include media_desktop_wxga {
    width: 520px;
    padding: 14px;
  }

  @include media_desktop_full {
    width: 558px;
    padding: 16px;
  }
}

.MainContainer {
  @include media_desktop_hd {
    padding: 0 px-to-vh(38px) px-to-vh(25px);
  }

  @include media_desktop_wxga {
    padding: 0 px-to-vh(42px) px-to-vh(33px);
  }

  @include media_desktop_full {
    padding: 0 px-to-vh(48px) px-to-vh(40px);
  }
}

.TitleText {
  color: var(--color-gray-title);
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 25px;
  }

  @include media_desktop_wxga {
    font-size: 21px;
    line-height: 27px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 30px;
  }
}

.InputField {
  box-sizing: border-box;
  color: var(--color-dark-text);
  font-family: var(--font-medium);

  @include media_desktop_hd {
    margin: 30px auto 5px;
    font-size: 14px;
  }

  @include media_desktop_wxga {
    margin: 35px auto 5px;
    font-size: 16px;
  }

  @include media_desktop_full {
    margin: 40px auto 5px;
    font-size: 18px;
  }

  &::placeholder {
    font-family: var(--font-medium);
  }
}

.InputError {
  border: 1px solid var(--color-tomato-light) !important;

  &:focus{
    border: 1px solid var(--color-tomato);
    box-shadow: 0 0 1px 1px var(--color-tomato-light);
  }

  &::placeholder {
    color: var(--color-tomato);
  }
}

.CharactersCount {
  text-align: right;
  font-size: 12px;
  font-family: var(--font-regular);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 14px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
  }

  @include media_desktop_full {
    font-size: 18px;
  }
}

.Button {
  background-color: var(--color-tomato);
  color: var(--color-white);
  font-family: var(--font-medium);
  outline: none;

  @include media_desktop_hd {
    margin: 30px auto 0;
    font-size: 16px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    margin: 35px auto 0;
    font-size: 18px;
    line-height: 20px;
  }

  @include media_desktop_full {
    margin: 40px auto 0;
    font-size: 20px;
    line-height: 24px;
  }
}