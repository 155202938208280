@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  cursor: default;
  font-family: var(--font-medium);
  border: 1px solid var(--color-light-grey);
  background: white;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 20px;
  height: max-content;
  box-sizing: border-box;
  user-select: none;

  @include media_desktop_hd {
    padding: 10px 10px 25px 17px;
    width: 260px;
  }

  @include media_desktop_wxga {
    padding: 12px 11px 27px 19px;
    width: 300px;
  }

  @include media_desktop_full {
    padding: 14px 13px 29px 21px;
    width: 330px;
  }
}

.Title {
  font-family: var(--font-medium);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  @include media_desktop_wxga {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  @include media_desktop_full {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

.SubContainer {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 8px;

  @include media_desktop_hd {
    margin-bottom: 22px;
    max-height: 110px;
  }

  @include media_desktop_wxga {
    margin-bottom: 24px;
    max-height: 120px;
  }

  @include media_desktop_full {
    margin-bottom: 26px;
    max-height: 130px;
  }
}

.CoursesItem {
  display: flex;
  justify-content: space-between;
}

.NameCourse {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include media_desktop_hd {
    max-width: 120px;

  }

  @include media_desktop_wxga {
    max-width: 140px;

  }

  @include media_desktop_full {
    max-width: 160px;
  }

  &:not(:last-child) {

    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
    }
  }
}

.Review {
  cursor: pointer;
  color: var(--color-tomato);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }

  &:hover {
    color: var(--color-tomato-hover);
  }
}

.WrapperCoursesAndBtn {
  display: flex;
  align-items: center;
}

.WrapperItem {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {

    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
    }
  }

  &:hover {
    color: var(--color-tomato);
    img {
      transform: scale(1.1);
    }
  }
}

.ImgAction {
  @include media_desktop_hd {
    margin-right: 13px;
    width: 16px;
    height: 17px;
  }

  @include media_desktop_wxga {
    margin-right: 15px;
    width: 16px;
    height: 17px;
  }

  @include media_desktop_full {
    margin-right: 17px;
    width: 16px;
    height: 17px;
  }
}




