@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.AttachFilesContainer {
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    border: 1px solid var(--color-tomato);
    border-radius: 6px;
    color: var(--color-dark-text);
    font-family: var(--font-medium);
    cursor: pointer;

    span {
        @include media_desktop_qhd {
            font-size: 14px;
            line-height: 18px;
        }

        @include media_desktop_hd {
            font-size: 16px;
            line-height: 20px;
        }

        @include media_desktop_wxga {
            font-size: 18px;
            line-height: 22px;
        }

        @include media_desktop_full {
            font-size: 20px;
            line-height: 24px;
        }
    }

    @include media_desktop_qhd {
        padding: 4px 8px;
    }

    @include media_desktop_wxga {
        padding: 6px 12px;
    }

    @include media_desktop_full {
        padding: 8px 16px;
    }
}

.Image {
    @include media_desktop_qhd {
        height: 18px;
        width: 18px;
        margin-right: 10px;
    }

    @include media_desktop_hd {
        height: 20px;
        width: 20px;
        margin-right: 12px;
    }

    @include media_desktop_wxga {
        height: 22px;
        width: 22px;
        margin-right: 14px;
    }

    @include media_desktop_full {
        height: 24px;
        width: 24px;
        margin-right: 16px;
    }
}