@import '../../../../../../assets/scss/helpers';
@import '../../../../../../assets/scss/medias';

.MainContentComponent {
    //width: 100%;
    padding: px-to-vh(40px) px-to-vh(88px) px-to-vh(100px) px-to-vh(65px);
}

.WrapperGoalField {
    box-sizing: border-box;
    background-color: var(--color-white);
    border-radius: 6px;
    padding: 4px;

    @include media_desktop_qhd {
        width: 570px;
        margin-top: 26px;
    }

    @include media_desktop_hd {
        width: 650px;
        margin-top: 28px;
    }

    @include media_desktop_wxga {
        width: 790px;
        margin-top: 30px;
    }

    @include media_desktop_full {
        width: 960px;
        margin-top: 32px;
    }
}

.GoalField {
    display: block;
    max-height: 50px;
    overflow-y: auto;
    font-family: var(--font-medium);
    border: none;
    background-color: var(--color-white);
    color: var(--color-dark-text);
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;

    &::placeholder {
        color: var(--color-gray-border);
    }

    @include media_desktop_qhd {
        padding: 6px;
        font-size: 14px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        padding: 8px;
        font-size: 16px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        padding: 10px;
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        padding: 12px;
        font-size: 18px;
        line-height: 22px;
    }
}

.ButtonContainer {
    width: 100%;
}

.InviteButton {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;

    @include media_desktop_qhd {
        padding: 6px 18px;
    }

    @include media_desktop_hd {
        padding: 8px 20px;
    }

    @include media_desktop_wxga {
        padding: 10px 22px;
    }

    @include media_desktop_full {
        padding: 12px 24px;
    }
}

.InviteImage {
    @include media_desktop_qhd {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    @include media_desktop_hd {
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }

    @include media_desktop_wxga {
        width: 22px;
        height: 22px;
        margin-right: 14px;
    }

    @include media_desktop_full {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
}

.InviteText {
    font-family: var(--font-medium);
    color: var(--color-white);

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.FooterTemplate {
    font-family: var(--font-medium);
    background: var(--color-light-tomato);
    position: fixed;
    bottom: 0;
    width: 100%;

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        padding: 20px 75px 20px 26px;
    }

    @include media_desktop_wxga {
        font-size: 20px;
        line-height: 24px;
        padding: 22px 95px 22px 36px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        padding: 26px 105px 26px 46px;
    }
}

.LinkTemplate {
    color: var(--color-dark-text);
}