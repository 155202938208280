@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.ContentContainer {
    display: flex;
    align-items: center;

    @include media_desktop_qhd {
        padding-top: 26px;
    }

    @include media_desktop_hd {
        padding-top: 28px;
    }

    @include media_desktop_wxga {
        padding-top: 30px;
    }

    @include media_desktop_full {
        padding-top: 32px;
    }

    &:hover {
        .VisibleActionButton {
            visibility: visible;
        }
    }
}

.DragAndDropImg,
.VisibleActionButton {
    cursor: grab;
    margin-right: 10px;
    visibility: hidden;

    svg {
        width: 18px;
        fill: var(--color-gray);
        cursor: grab;

        @include media_desktop_qhd {
            width: 18px;
        }

        @include media_desktop_hd {
            width: 20px;
        }

        @include media_desktop_wxga {
            width: 22px;
        }

        @include media_desktop_full {
            width: 24px;
        }

        &:hover {
            fill: var(--color-tomato);
            transform: scale(1.1);
        }
    }
}

.DeleteIcon,
.VisibleActionButton {
    display: block;
    height: 20px;
    cursor: pointer;
    outline: none;
    visibility: hidden;

    svg {
        fill: var(--color-gray);

        @include media_desktop_qhd {
            width: 18px;
            height: 18px;
            margin-left: 18px;
        }

        @include media_desktop_hd {
            width: 20px;
            height: 20px;
            margin-left: 20px;
        }

        @include media_desktop_wxga {
            width: 22px;
            height: 22px;
            margin-left: 22px;
        }

        @include media_desktop_full {
            width: 24px;
            height: 24px;
            margin-left: 24px;
        }

        &:hover {
            fill: var(--color-tomato);
            transform: scale(1.1);
        }
    }
}

.AssignmentContainer {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    border-radius: 6px;
    width: max-content;
    padding: px-to-vh(8px) px-to-vh(16px);
    user-select: none;
    cursor: pointer;
}

.AssignmentText {
    font-family: var(--font-medium);
    color: var(--color-tomato);

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.AssignmentIcon {
    @include media_desktop_qhd {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    @include media_desktop_hd {
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }

    @include media_desktop_wxga {
        width: 22px;
        height: 22px;
        margin-right: 14px;
    }

    @include media_desktop_full {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
}

.QuizContainer {
    display: flex;
    align-items: center;
    margin-left: 30px;

    @include media_desktop_qhd {
        margin-top: 26px;
    }

    @include media_desktop_hd {
        margin-top: 28px;
    }

    @include media_desktop_wxga {
        margin-top: 30px;
    }

    @include media_desktop_full {
        margin-top: 32px;
    }

    &:hover {
        .VisibleActionButton {
            visibility: visible;
        }
    }
}

.TaskContainer {
    display: flex;
    align-items: center;
    margin-left: 30px;

    @include media_desktop_qhd {
        margin-top: 26px;
    }

    @include media_desktop_hd {
        margin-top: 28px;
    }

    @include media_desktop_wxga {
        margin-top: 30px;
    }

    @include media_desktop_full {
        margin-top: 32px;
    }

    &:hover {
        .VisibleActionButton {
            visibility: visible;
        }
    }
}

.WrapperForLoader {
    display: flex;
    justify-content: center;
    margin: 20px;
}