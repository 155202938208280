@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.MainPopupContainer {
  @include media_desktop_hd {
    width: 446px;
    padding: 42px 0;
    height: 722px;
  }

  @include media_desktop_wxga {
    width: 456px;
    padding: 45px 0;
    height: 822px;
  }

  @include media_desktop_full {
    width: 486px;
    height: 922px;
    padding: 48px 0;
  }
}

.ContainerPipeline {
  width: 900px;
}

.ContainerStages {
  width: 1200px;
}

.Title {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 32px;
  }
}

.WrapperDetails {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media_desktop_hd {
    margin-bottom: 30px;
  }

  @include media_desktop_wxga {
    margin-bottom: 35px;
  }

  @include media_desktop_full {
    margin-bottom: 40px;
  }
}

.WrapperTab {
  @include media_desktop_hd {
    width: 96px;
  }

  @include media_desktop_wxga {
    width: 96px;
  }

  @include media_desktop_full {
    width: 116px;
  }

  &:first-child {
    margin-right: 24px;
  }
}

.NoActive {
  margin-bottom: 10px;
}

.ActiveTabLearner {
  transition: 0.4s;

  .Circle {
    background: var(--color-tomato);
  }

  span {
    color: var(--color-tomato);
  }

  &::after {
    content: "";
    transition: 0.4s;
    margin-top: 8px;
    display: block;
    background-color: var(--color-tomato);
    height: 2px;

    @include media_desktop_hd {
      width: 106px;
    }

    @include media_desktop_wxga {
      width: 108px;
    }

    @include media_desktop_full {
      width: 116px;
    }
  }
}

.ActiveTabMetrics {
  transition: 0.4s;

  .Circle {
    background: var(--color-tomato);
  }

  span {
    color: var(--color-tomato);
  }

  &::after {
    content: "";
    transition: 0.4s;
    margin-top: 8px;
    display: block;
    background-color: var(--color-tomato);
    height: 2px;

    @include media_desktop_hd {
      width: 96px;
    }

    @include media_desktop_wxga {
      width: 102px;
    }

    @include media_desktop_full {
      width: 106px;
    }
  }
}

.Tab {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    div {
      background: var(--color-tomato);
    }
    span {
      color: var(--color-tomato);
    }
  }
}

.ImageDetails {
  width: 16px;
  height: 14px;
  padding: 4px;
  display: flex;
  justify-content: center;
}

.Circle {
  background: var(--color-gray-border);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.Text {
  font-family: var(--font-medium);
  color: var(--color-gray-title);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.Subtitle {
  text-align: left;
  font-family: var(--font-medium);
  color: var(--color-gray-title);

  @include media_desktop_hd {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 18px;
    margin-left: 42px;
  }

  @include media_desktop_wxga {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    margin-left: 45px;
  }

  @include media_desktop_full {
    margin-bottom: 18px;
    margin-left: 48px;
    font-size: 18px;
    line-height: 22px;
  }
}

.ButtonSave {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    margin: 43px auto;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 51px;
  }

  @include media_desktop_wxga {
    margin: 45px auto;
    font-size: 18px;
    line-height: 22px;
    padding: 11px 53px;
  }

  @include media_desktop_full {
    margin: 48px auto;
    font-size: 20px;
    line-height: 24px;
    padding: 12px 55px;
  }
}