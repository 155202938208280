@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.CalendarIcon {
  background: var(--color-white);
  border-radius: 6px;
  border: 1px solid var(--color-gray-border);
  cursor: pointer;
  margin-right: 31px;
  position: relative !important;

  @include media_desktop_hd {
    padding: 5px 6px;
  }

  @include media_desktop_wxga {
    padding: 6px 9px;
  }

  @include media_desktop_full {
    padding: 7px 11px;
  }
}

.PerformanceReports {
  margin-right: 0;
}

.IconCalendar {
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--color-gray-title);

    &:hover {
      fill: var(--color-tomato);
    }
  }
}

.ActiveIcon {
  width: fit-content;
  border: 1px solid var(--color-tomato);
}

.WrapperCalendar {
  position: absolute;
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  background: var(--color-white);

  @include media_desktop_hd {
    margin-top: 5px;
  }

  @include media_desktop_wxga {
    margin-top: 6px;
  }

  @include media_desktop_full {
    margin-top: 7px;
  }
}

.Calendar {
  font-family: var(--font-medium);
  border-radius: 6px;
  .rdrDateDisplayWrapper {
    border: 1px solid var(--color-gray-border);
  }
}

.BtnSave {
  margin: 15px auto 15px;
  padding: 6px 20px;
}