@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  display: flex;
  align-items: center;
  font-family: var(--font-regular);

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
  }
}

.WrapperProgressBar {
  margin-right: 16px;

  @include media_desktop_hd {
    width: 213px;
  }

  @include media_desktop_hd-after {
    width: 263px;
  }

  @include media_desktop_wxga {
    width: 310px;
  }

  @include media_desktop_full {
    width: 363px;
  }
}

.NoData {
  background: var(--color-gray-border);
  border-radius: 6px;
}