@import '../../../../assets/scss/helpers';
@import '../../../../assets/scss/medias';

.LearnerAccountPage {
  display: inline-flex;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  letter-spacing: 0.01em;
}

.LogoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 186px;
  border-bottom: 1px solid var(--color-light-grey);
  width: 100%;
  height: 100%;
  cursor: pointer;

  @include media_desktop_qhd {
    height: 56px;
    width: 118px;
    margin-bottom: 15px;
  }

  @include media_desktop_hd {
    height: 100px;
    width: 164px;
    margin-bottom: 20px;
  }

  @include media_desktop_wxga {
    height: 115px;
    width: 234px;
    margin-bottom: 25px;
  }

  @include media_desktop_full {
    margin-bottom: 30px;
    height: 130px;
  }
}

.SubTitle {
  font-family: var(--font-regular);

  @include media_desktop_hd {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_hd {
    font-size: 15px;
    line-height: 19px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.MainContainer {
  display: block;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
}

.MenuContainer {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;

  @include media_desktop_qhd {
    width: 224px;
  }

  @include media_desktop_hd {
    width: 313px;
  }

  @include media_desktop_wxga {
    width: 392px;
  }

  @include media_desktop_full {
    width: 417px;
  }
}

.UserContainer {
  display: flex;
  align-items: center;
  width: max-content;
  font-family: var(--font-medium);
  user-select: none;

  span {
    color: var(--color-tomato);

    @include media_desktop_hd {
      font-size: px-to-vh(16px);
      line-height: px-to-vh(18px);
      margin-left: px-to-vh(16px);
    }

    @include media_desktop_wxga {
      font-size: px-to-vh(18px);
      line-height: px-to-vh(22px);
    }

    @include media_desktop_full {
      font-size: px-to-vh(24px);
      line-height: px-to-vh(24px);
    }
  }

  @include media_desktop_hd {
    margin: px-to-vh(32px) auto px-to-vh(23px) px-to-vh(40px);
  }

  @include media_desktop_wxga {
    margin: px-to-vh(40px) auto px-to-vh(40px) px-to-vh(56px);
  }
}

.UserLogo {
  background-color: var(--color-gray-bronze);
  border-radius: 50%;

  @include media_desktop_hd {
    height: px-to-vh(32px);
    width: px-to-vh(32px);
  }

  @include media_desktop_wxga {
    height: 48px;
    width: 48px;
  }
}


.TabListComponent {
  overflow: auto;
}

.SignOutIcon {
  width: 100%;
  height: 100%;
  cursor: pointer;

  @include media_desktop_hd {
    max-height: px-to-vh(24px);
    max-width: px-to-vh(24px);
  }

  @include media_desktop_wxga {
    max-height: px-to-vh(32px);
    max-width: px-to-vh(32px);
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;

  @include media_desktop_qhd {
    width: 5px;
  }

  @include media_desktop_hd {
    width: 6px;
  }

  @include media_desktop_wxga {
    width: 7px;
  }

  @include media_desktop_full {
    width: 8px;
  }
}

::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--color-light-grey);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-light-grey);
  border-radius: 10px;
}

.NotAdaptiveWrapper {
  background-color: var(--color-light-background);
  width: 100%;
  height: 100vh;
}

.LogoImage {
  padding: 20px 0 0 35px;
  width: 175px;
  height: 48px;
}

.NotAdaptive {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-family: var(--font-medium);
  padding-top: 220px;

  @include media_desktop_qhd {
    padding-top: 236px;
    font-size: 26px;
  }
};

.TitleNotAdaptive {
  text-align: center;
  width: 246px;

  @include media_tablet {
    width: 450px;
  }

  @include media_desktop_qhd {
    width: 536px;
  }
}

.SmileImg {
  margin-top: 78px;
  margin-left: -28px;
  width: 30px;
  height: 30px;

  @include media_tablet {
    margin-top: 23px;
    margin-left: -5px;
    width: 30px;
    height: 30px;
  }

  @include media_desktop_qhd {
    margin-top: 31px;
    margin-left: -10px;
    width: 30px;
    height: 30px;
  }
}

.LogoImg {
  margin-bottom: 10px;

  @include media_desktop_hd {
    width: 175px;
    height: 48px;
  }

  @include media_desktop_wxga {
    width: 200px;
    height: 60px;
  }

  @include media_desktop_full {
    width: 230px;
    height: 70px;
  }
}
