@import '../../../../assets/scss/helpers';
@import '../../../../assets/scss/medias';

.WrapperTitle {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media_desktop_hd {
    margin: px-to-vh(64px) auto 16px auto;
  }

  @include media_desktop_wxga {
    margin: px-to-vh(64px) auto 16px auto;
  }

  @include media_desktop_full {
    margin: px-to-vh(64px) auto 16px auto;
  }
}

.Title {
  font-family: var(--font-medium);
  text-align: center;

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.Circle {
  border-radius: 50%;
  background: var(--color-green);
  display: flex;
  align-items: center;
  justify-content: center;

  @include media_desktop_hd {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  @include media_desktop_wxga {
    width: 22px;
    height: 22px;
    margin-left: 12px;
  }

  @include media_desktop_full {
    width: 24px;
    height: 24px;
    margin-left: 14px;
  }
}

.Image {
  @include media_desktop_hd {
    width: 14px;
    height: 10px;
  }

  @include media_desktop_wxga {
    width: 16px;
    height: 12px;
  }

  @include media_desktop_full {
    width: 18px;
    height: 14px;
  }
}

.WrapperSubtitle {
  text-align: center;
  font-family: var(--font-regular);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.WrapperSignUp {
  margin: px-to-vh(37px) auto;

  @include media_desktop_qhd {
    width: 260px;
  }

  @include media_desktop_hd {
    width: 280px;
  }

  @include media_desktop_wxga {
    width: 300px;
  }

  @include media_desktop_full {
    width: 320px;
  }
}

.TitleDescriptionContainer {
  display: flex;
  align-items: center;
  margin-bottom: px-to-vh(32px);

  span {
    font-family: var(--font-regular);
    width: 100%;
    white-space: nowrap;
    display: block;
    margin: 0 px-to-vh(12px);

    @include media_desktop_qhd {
      font-size: 12px;
      line-height: 16px;
    }

    @include media_desktop_hd {
      font-size: 14px;
      line-height: 28px;
    }

    @include media_desktop_wxga {
      font-size: 16px;
      line-height: 20px;
    }

    @include media_desktop_full {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.Line {
  height: 0.5px;
  width: 100%;
  background-color: var(--color-silver);
}

.SignUpButton {
  display: block;
  margin: 0 auto;
  font-family: var(--font-regular);
  color: var(--color-tomato);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}