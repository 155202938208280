@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.ContainerListQuestionAndDetails {
  display: flex;
}

.ListQuestionsContainer {
  text-align: left;
  overflow: scroll;
  height: 179px;
  width: 220px;
  margin-top: 18px;

  @include media_desktop_qhd {
    height: 179px;
    width: 220px;
  }

  @include media_desktop_hd {
    height: 240px;
    width: 230px;
  }

  @include media_desktop_wxga {
    height: 300px;
    width: 240px;
  }

  @include media_desktop_full {
    height: 400px;
    width: 250px;
  }
}

.ListQuestionsContainerAttempts {
  @include media_desktop_qhd {
    height: 179px;
  }

  @include media_desktop_hd {
    height: 230px;
  }

  @include media_desktop_wxga {
    height: 285px;
  }

  @include media_desktop_full {
    height: 400px;
  }
}

.ListQuestions {
  font-family: var(--font-regular);
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 22px;

    @include media_desktop_hd {
      margin-bottom: 22px;
      font-size: 15px;
      line-height: 19px;
    }

    @include media_desktop_hd {
      margin-bottom: 26px;
      font-size: 16px;
      line-height: 20px;
    }

    @include media_desktop_wxga {
      margin-bottom: 28px;
      font-size: 17px;
      line-height: 21px;
    }

    @include media_desktop_full {
      margin-bottom: 32px;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &:hover {
    color: var(--color-tomato);
    div {
      color: var(--color-tomato);
    }
    span {
      border: 1px solid var(--color-tomato);
      color: var(--color-tomato);
    }
  }
}

.ActiveQuestion {
  span {
    color: var(--color-tomato);
    border: 1px solid var(--color-tomato);
  }
  div {
    color: var(--color-tomato);
  }
}

.WrapperCheckbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type='checkbox'] {
    padding: 7px 7px;
    margin: 0 16px 0 24px;
    cursor: pointer;
    border: 1px solid var(--color-green);
    border-radius: 2px;
    color: var(--color-white);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background-color: var(--color-green);
    background-image: url('../../../../../../../../../../../assets/images/icon/check-mark-white.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 78%;
    width: 18px;
    height: 18px;

    @include media_desktop_qhd {
      width: 18px;
      height: 18px;
      margin: 0 16px 0 24px;
      padding: 7px 7px;
      background-size: 64%;
    }

    @include media_desktop_hd {
      width: 20px;
      height: 20px;
      margin: 0 18px 0 26px;
      padding: 8px 8px;
      background-size: 67%;
    }

    @include media_desktop_wxga {
      width: 22px;
      height: 22px;
      margin: 0 22px 0 28px;
      padding: 9px 9px;
      background-size: 64%;
    }

    @include media_desktop_full {
      width: 24px;
      height: 24px;
      margin: 0 24px 0 30px;
      padding: 10px 10px;
      background-size: 64%;
    }
  }
}

.EssayImg {
  margin: 0 16px 0 23px;
  width: 18px;
  height: 18px;

  @include media_desktop_qhd {
    margin: 0 16px 0 23px;
    width: 18px;
    height: 18px;
  }

  @include media_desktop_hd {
    margin: 0 18px 0 25px;
    width: 20px;
    height: 20px;
  }

  @include media_desktop_wxga {
    margin: 0 22px 0 27px;
    width: 22px;
    height: 22px;
  }

  @include media_desktop_full {
    margin: 0 24px 0 29px;
    width: 24px;
    height: 24px;
  }
}

.InputFailed {
  input[type='checkbox'] {
    border: 1px solid var(--color-red);
    background-color: var(--color-red);
    background-image: url('../../../../../../../../../../../assets/images/icon/cross-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.NumberList {
  border: 1px solid var(--color-gray-border);
  margin-right: 14px;
  border-radius: 6px;
  padding: 2px 6px;
  color: var(--color-gray-title);

  @include media_desktop_qhd {
    margin-right: 14px;
  }

  @include media_desktop_hd {
    margin-right: 16px;
  }

  @include media_desktop_wxga {
    margin-right: 18px;
  }

  @include media_desktop_full {
    margin-right: 20px;
  }
}

.TitleQuestions {
  color: var(--color-gray-title);
  font-family: var(--font-medium);
  font-size: 14px;
  line-height: 16px;
  margin-right: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}


.WrapperAnswer {
  margin-top: 18px;
  width: 448px;
  height: 179px;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  @include media_desktop_qhd {
    width: 448px;
    height: 179px;
  }

  @include media_desktop_hd {
    width: 536px;
    height: 240px;
  }

  @include media_desktop_wxga {
    width: 627px;
    height: 300px;
  }

  @include media_desktop_full {
    width: 718px;
    height: 400px;
  }
}

.WrapperAnswerAttempts {
  @include media_desktop_qhd {
    height: 179px;
  }

  @include media_desktop_hd {
    height: 230px;
  }

  @include media_desktop_wxga {
    height: 285px;
  }

  @include media_desktop_full {
    height: 400px;
  }
}

.ContainerQuestion {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  @include media_desktop_qhd {
    margin-bottom: 25px;
  }

  @include media_desktop_hd {
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    margin-bottom: 31px;
  }

  @include media_desktop_full {
    margin-bottom: 34px;
  }
}

.NumberListDetails {
  margin: 0 16px 0 28px;
  border-radius: 6px;
  padding: 2px 6px;

  @include media_desktop_qhd {
    margin: 0 16px 0 28px;
  }

  @include media_desktop_hd {
    margin: 0 18px 0 31px;
  }

  @include media_desktop_wxga {
    margin: 0 22px 0 34px;
  }

  @include media_desktop_full {
    margin: 0 24px 0 37px;
  }
}

.NumberPassed {
  border: 1px solid var(--color-green);
  color: var(--color-green);
}

.NumberFailed {
  border: 1px solid var(--color-red);
  color: var(--color-red);
}

.NumberEssay {
  border: 1px solid var(--color-gray-border);
  color: var(--color-gray-title);
}

.TitleQuestionsDetails {
  display: flex;
  word-break: break-word;
  text-align: left;
  flex-wrap: wrap;
  font-family: var(--font-medium);
  font-size: 14px;
  line-height: 16px;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.ImageAttach {
  display: flex;
  max-width: 30%;
  cursor: pointer;
  margin: 0 16px 8px 28px;

  @include media_desktop_qhd {
    margin: 0 16px 8px 28px;
  }

  @include media_desktop_hd {
    margin: 0 18px 8px 31px;
  }

  @include media_desktop_wxga {
    margin: 0 22px 8px 34px;
  }

  @include media_desktop_full {
    margin: 0 24px 8px 37px;
  }
}

.ContainerDetails {
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  @include media_desktop_qhd {
    margin-bottom: 13px;
  }

  @include media_desktop_hd {
    margin-bottom: 16px;
  }

  @include media_desktop_wxga {
    margin-bottom: 19px;
  }

  @include media_desktop_full {
    margin-bottom: 22px;
  }
}

.WrapperCheckboxDetails {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    padding: 7px 7px;
    cursor: default;
    border-radius: 2px;
    color: var(--color-white);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 64%;

    @include media_desktop_qhd {
      width: 18px;
      height: 18px;
      padding: 7px 7px;
      background-size: 64%;
    }

    @include media_desktop_hd {
      width: 20px;
      height: 20px;
      padding: 8px 8px;
      background-size: 64%;
    }

    @include media_desktop_wxga {
      width: 22px;
      height: 22px;
      padding: 9px 9px;
      background-size: 64%;
    }

    @include media_desktop_full {
      width: 24px;
      height: 24px;
      padding: 10px 10px;
      background-size: 64%;
    }
  }
}

.isCorrect {
  border: 1px solid var(--color-green);
  background-color: var(--color-green);
  background-image: url('../../../../../../../../../../../assets/images/icon/check-mark-white.svg');
}

.isCorrectExpected {
  border: 1px solid var(--color-gray-border);
  background-color: var(--color-gray-border);
  background-image: url('../../../../../../../../../../../assets/images/icon/check-mark-white.svg');
}

.isNotCorrectExpected {
  border: 1px solid var(--color-gray-border);
  background-image: url('../../../../../../../../../../../assets/images/icon/cross-icon-gray.svg');
}

.FailedAnswer {
  border: 1px solid var(--color-red);
  background-color: var(--color-red);
  background-image: url('../../../../../../../../../../../assets/images/icon/cross-icon.svg');
}

.InputExpected {
  margin: 0 16px 0 28px;

  @include media_desktop_qhd {
    margin: 0 16px 0 28px;
  }

  @include media_desktop_hd {
    margin: 0 18px 0 31px;
  }

  @include media_desktop_wxga {
    margin: 0 22px 0 34px;
  }

  @include media_desktop_full {
    margin: 0 24px 0 37px;
  }
}

.TitleDetails,
.TextEssay {
  text-align: left;
  margin-left: 28px;
  font-family: var(--font-medium);
  font-size: 12px;
  line-height: 16px;

  @include media_desktop_qhd {
    margin-left: 28px;
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    margin-left: 31px;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    margin-left: 34px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    margin-left: 37px;
    font-size: 18px;
    line-height: 22px;
  }
}