@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.LogoContainer {
    width: 275px;
    height: 85px;
    font-family: var(--font-medium);
    font-size: 20px;

    span {
      display: block;
    }

    @include media_tablet {
        width: 500px;
    }

    @include media_desktop_qhd {
        width: 500px;
    }

    @include media_desktop_hd {
        width: 500px;
    }

    @include media_desktop_wxga {
        width: 500px;
    }

    @include media_desktop_full {
        width: 500px;
    }
}