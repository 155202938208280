@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.WrapperWelcomeTeamComponent {
  text-align: center;

  @include media_desktop_hd {
    margin-top: px-to-vh(300px);
  }

  @include media_desktop_wxga {
    margin-top: px-to-vh(312px);
  }

  @include media_desktop_full {
    margin-top: px-to-vh(327px);
  }
}

.TitleWrapper {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.Title {
  font-family: var(--font-semibold);
  color: var(--color-tomato);
  line-height: 77px;

  @include media_desktop_hd {
    font-size: px-to-vh(60px);
  }

  @include media_desktop_wxga {
    font-size: px-to-vh(62px);
  }

  @include media_desktop_full {
    font-size: px-to-vh(64px);
  }
}

.Image {
  margin-left: 15px;
  width: 50px;
  height: 50px;

  @include media_desktop_hd {
    width: 38px;
    height: 38px;
  }

  @include media_desktop_wxga {
    width: 48px;
    height: 48px;
  }

  @include media_desktop_full {
    width: 60px;
    height: 60px;
  }
}

.Subtitle {
  font-family: var(--font-semibold);
  font-size: 18px;
  display: block;
  color: var(--color-gray-title);

  @include media_desktop_hd {
    max-width: px-to-vh(500px);
    margin: px-to-vh(16px) auto 0 auto;
    font-size: px-to-vh(20px);
    line-height: px-to-vh(30px);
  }

  @include media_desktop_wxga {
    max-width: px-to-vh(550px);
    margin: px-to-vh(20px) auto 0 auto;
    font-size: px-to-vh(22px);
    line-height: px-to-vh(30px);
  }

  @include media_desktop_full {
    max-width: px-to-vh(650px);
    margin: px-to-vh(24px) auto 0 auto;
    font-size: px-to-vh(24px);
    line-height: px-to-vh(30px);
  }
}

.Button {
  margin: 75px auto;
  background-color: var(--color-tomato);
  box-shadow: 0 4px 10px rgba(255, 66, 35, 0.15);
  color: var(--color-white);
  font-size: 16px;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: px-to-vh(24px);
    line-height: px-to-vh(26px);
    margin: px-to-vh(52px) auto 0 auto;
    padding: px-to-vh(23px) px-to-vh(28px);
  }

  @include media_desktop_wxga {
    font-size: px-to-vh(26px);
    line-height: px-to-vh(30px);
    margin: px-to-vh(62px) auto 0 auto;
    padding: px-to-vh(25px) px-to-vh(30px);
  }

  @include media_desktop_full {
    font-size: px-to-vh(28px);
    line-height: px-to-vh(34px);
    margin: px-to-vh(76px) auto 0 auto;
    padding: px-to-vh(27px) px-to-vh(32px);
  }
}

.ButtonContainer {
  display: flex;
  align-items: center;

  svg {
    fill: var(--color-white);
    @include media_desktop_hd {
      height: px-to-vh(25px);
      width: px-to-vh(25px);
    }

    @include media_desktop_wxga {
      height: px-to-vh(30px);
      width: px-to-vh(30px);
    }

    @include media_desktop_full {
      height: px-to-vh(35px);
      width: px-to-vh(35px);
    }
  }

  span {
    margin-left: 16px;
  }
}

.ImageAdd {
  @include media_desktop_hd {
    height: px-to-vh(25px);
    width: px-to-vh(25px);
  }

  @include media_desktop_wxga {
    height: px-to-vh(30px);
    width: px-to-vh(30px);
  }

  @include media_desktop_full {
    height: px-to-vh(35px);
    width: px-to-vh(35px);
  }
}