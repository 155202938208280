@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.WrapperForSeatsAndPrice {
  background: var(--color-white);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_wxga {
    font-size: 21px;
    line-height: 25px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.SubtitleSeatsAndPrice {
  font-family: var(--font-medium);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
    margin-top: 13px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
    margin-top: 16px;
  }
}


.ChangeSeatsImg {
  svg {
    border: 1px solid var(--color-tomato);
    border-radius: 6px;
    cursor: pointer;
    fill: var(--color-tomato);

    @include media_desktop_hd {
      width: 15px;
      height: 16px;
      padding: 12px;
    }

    @include media_desktop_wxga {
      width: 16px;
      height: 17px;
      padding: 14px;
    }

    @include media_desktop_full {
      width: 17px;
      height: 18px;
      padding: 16px;
    }

    &:hover {
      border: 1px solid var(--color-light-tomato);
      fill: var(--color-light-tomato);
    }
  }
}
