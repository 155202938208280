@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.NewCourseContainer {
    height: 100%;
    color: var(--color-dark-text);
    letter-spacing: 0.01em;
    user-select: none;

    @include media_desktop_hd {
        padding: 38px 65px 130px 65px;
    }

    @include media_desktop_wxga {
        padding: 50px 78px 160px 78px;
    }

    @include media_desktop_full {
        padding: 64px 96px 220px 96px;
    }
}

.StartCreateCourseText {
    display: block;
    font-family: var(--font-medium);
    color: var(--color-dark-text);

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 30px;
    }
}

.StepIcon {
    display: block;
    border-radius: 50%;
    position: relative;
    box-shadow: -2px 2px 6px rgba(58, 70, 93, 0.11965);
    z-index: 2;

    @include media_desktop_hd {
        width: 20px;
        height: 20px;
    }

    @include media_desktop_wxga {
        width: 22px;
        height: 22px;
    }

    @include media_desktop_full {
        width: 24px;
        height: 24px;
    }
}

.LabelText {
    display: block;
    font-family: var(--font-medium);

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 22px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 24px;
    }
}

.CloseButtonContainer {
    width: 100%;
    text-align: right;
}

.CloseImg {
    height: 30px;
    cursor: pointer;
}