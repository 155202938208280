@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.Modal {
  position: absolute;
  left: -5px;
  top: 11px;
  align-items: center;
  padding: 11px 11px 11px 14px;
  width: max-content;
  background-color: var(--color-white);
  border-radius: 6px;
  border: 1px solid #CBC3C1;
  z-index: 1;

  @include media_desktop_qhd {
    padding: 11px 11px 11px 14px;
  }

  @include media_desktop_hd {
    padding: 13px 13px 13px 16px;
  }

  @include media_desktop_wxga {
    padding: 15px 15px 15px 18px;
  }

  @include media_desktop_full {
    padding: 17px 17px 17px 20px;
  }
}

.Delete {
  display: flex;
  align-items: center;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }

  &:hover {
    color: var(--color-tomato);
    svg {
      fill: var(--color-tomato-hover);
    }
  }
}

.DeleteImage {
  svg {
    width: 10px;
    height: 14px;
    margin-right: 13px;
    fill: var(--color-silver);

    @include media_desktop_qhd {
      width: 10px;
      height: 14px;
      margin-right: 13px;
    }

    @include media_desktop_hd {
      width: 12px;
      height: 16px;
      margin-right: 15px;
    }

    @include media_desktop_wxga {
      width: 14px;
      height: 18px;
      margin-right: 17px;
    }

    @include media_desktop_full {
      width: 16px;
      height: 21px;
      margin-right: 19px;
    }
  }
}