@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.WrapperCalendar {
  position: relative;
  margin-top: px-to-vh(10px);
}

.TitleCalendar {
  font-family: var(--font-medium);
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  max-width: 320px;

  @include media_tablet {
    font-size: 18px;
    line-height: 24px;
    max-width: 100%;
  }

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 26px;
    max-width: 100%;
  }

  @include media_desktop_wxga {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 28px;
    max-width: 100%;
  }

  @include media_desktop_full {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 32px;
    max-width: 100%;
  }
}

.Calendar {
  box-shadow: 0px 0px 3px 1px rgba(165, 167, 169, 0.25);
  margin: 0 auto;
  max-width: 320px;

  @include media_tablet {
    max-width: 320px;
  }

  @include media_desktop_qhd {
    max-width: 400px;
  }

  @include media_desktop_hd {
    max-width: 400px;
  }

  @include media_desktop_wxga {
    max-width: 400px;
  }

  @include media_desktop_full {
    max-width: 400px;
  }
}

.SkipText {
  outline: none;
  border: none;
  background: transparent;
  font-family: var(--font-medium);
  cursor: pointer;
  color: var(--color-tomato);
  font-size: 18px;
  line-height: 22px;
  margin: 26px auto !important;

  @include media_tablet {
    font-size: 18px;
    line-height: 22px;
    margin: 20px auto;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin: 20px auto;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin: 25px auto;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin: 30px auto;
  }

  &:hover {
    color: var(--color-tomato-hover);
  }
}