@import '../../assets/scss/medias';
@import '../../assets/scss/helpers';

.ica--txt-h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: px-to-vw(30px);
    line-height: 3.4;

    @include media_tablet {
        font-size: px-to-vw-tablet(40px);
        line-height: 1.1;
    }

    @include media_desktop_full {
        font-size: px-to-vh(74px);
    }
}

.ica--txt-h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;

    @include media_tablet {
        font-size: px-to-vw-tablet(24px);
        line-height: 2.9;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 2.9;
    }
}

.ica--txt-p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;

    @include media_tablet {
        font-size: px-to-vw-tablet(16px);
        line-height: 1.5;
    }

    @include media_desktop_full {
        font-size: px-to-vh(24px);
        line-height: 1.5;
    }
}