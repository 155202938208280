@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.Header {
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;

  span {
    color: var(--color-silver);
    font-family: var(--font-regular);

    @include media_desktop_hd {
      font-size: 16px;
      line-height: 20px;
    }

    @include media_desktop_wxga {
      font-size: 18px;
      line-height: 22px;
    }

    @include media_desktop_full {
      font-size: 20px;
      line-height: 24px;
    }
  }

  @include media_desktop_hd {
    padding: 0 14px 0 12px;
    margin-bottom: 30px;
  }

  @include media_desktop_wxga {
    padding: 0 16px 0 14px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    padding: 0 18px 0 16px;
    margin-bottom: 32px;
  }
}