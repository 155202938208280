@import '../../../../../../../assets/scss/helpers';
@import '../../../../../../../assets/scss/medias';

.MainContainer {
  width: 55vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MainContainerMobile {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.ContainerNeeds {
  margin-left: 0;

  @include media_desktop_hd {
    margin-left: 100px;
  }

  @include media_desktop_wxga {
    margin-left: 100px;
  }

  @include media_desktop_full {
    margin-left: 100px;
  }

}

.ContainerCalendar {
  margin-left: 0;
}

.TitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  @include media_desktop_hd {
    margin-bottom: px-to-vh(64px);
  }

  @include media_desktop_wxga {
    margin-bottom: px-to-vh(64px);
  }

  @include media_desktop_full {
    margin-bottom: px-to-vh(64px);
  }
}

.TitleText {
  display: block;
  font-family: var(--font-medium);
  color: var(--color-tomato);
  font-size: 28px;
  line-height: 34px;

  @include media_tablet {
    font-size: 40px;
    line-height: 44px;
  }

  @include media_desktop_qhd {
    font-size: 42px;
    line-height: 46px;
  }

  @include media_desktop_hd {
    font-size: 44px;
    line-height: 50px;
  }

  @include media_desktop_wxga {
    font-size: 46px;
    line-height: 54px;
  }

  @include media_desktop_full {
    font-size: 48px;
    line-height: 58px;
  }

  span {
    color: var(--color-black);
  }
}

.Description {
  font-family: var(--font-medium);
  text-align: center;
  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.InputContainer {
  display: block;
  width: 320px;
  margin: 30px auto 0;

  @include media_desktop_qhd {
    width: 280px;
  }

  @include media_desktop_hd {
    width: 280px;
  }

  @include media_desktop_wxga {
    width: 300px;
  }

  @include media_desktop_full {
    width: 320px;
  }
}

.InputContainerCalendar {
  margin: 0;
}

.ContinueButton {
  width: 100%;
  margin: 32px auto;
  font-size: 20px;
  line-height: 24px;

  @include media_desktop_qhd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.DisabledContinueButton {
  background: var(--color-gray-border);
}
