@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Button {
    font-family: var(--font-regular);
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    height: auto;
    width: auto;
    display: block;
    margin: 0;
    background: none;
    border: none;
    padding: 0;
    user-select: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}
