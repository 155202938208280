@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.WrapperHeaderAndList {
  max-width: 610px;

  @include media_desktop_qhd {
    max-width: 610px;
  }

  @include media_desktop_hd {
    max-width: 724px;
  }

  @include media_desktop_wxga {
    max-width: 860px;
  }

  @include media_desktop_full {
    max-width: 996px;
  }
}

.OnboardeeHeader {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 75% 25%;
  padding-left: 10px;

  @include media_desktop_qhd {
    padding-left: 10px;
  }

  @include media_desktop_hd {
    padding-left: 12px;
  }

  @include media_desktop_wxga {
    padding-left: 14px;
  }

  @include media_desktop_full {
    padding-left: 16px;
  }
}

.OnboardeeHeaderRank {
  grid-template-columns: 65% 26% 9%;
}

.OnboardeeHeaderStatus {
  grid-template-columns: 55% 21% 24%;
}

.SubTitle {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-regular);
  color: var(--color-gray-title);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.OnboardeeList {
  margin-top: 10px;
  max-height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

    @include media_desktop_qhd {
      margin-top: 10px;
      max-height: 200px;
      padding-right: 20px;
    }

    @include media_desktop_hd {
      margin-top: 12px;
      max-height: 260px;
      padding-right: 20px;
    }

    @include media_desktop_wxga {
      margin-top: 14px;
      max-height: 380px;
      padding-right: 30px;
    }

    @include media_desktop_full {
      margin-top: 16px;
      max-height: 400px;
      padding-right: 40px;
    }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;

    @include media_desktop_qhd {
      width: 5px;
    }

    @include media_desktop_hd {
      width: 6px;
    }

    @include media_desktop_wxga {
      width: 7px;
    }

    @include media_desktop_full {
      width: 8px;
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--color-white);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-light-grey);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-light-grey);
    border-radius: 6px;
  }
}

.LearnerItem {
  display: grid;
  align-items: center;
  box-sizing: border-box;
  background: var(--color-white);
  border-radius: 6px;
  margin-bottom: 10px;

    @include media_desktop_qhd {
      margin-bottom: 10px;
      padding-left: 10px;
    }

    @include media_desktop_hd {
      margin-bottom: 12px;
      padding-left: 12px;
      grid-template-columns: 75.6% 25%;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
      padding-left: 14px;
      grid-template-columns: 75% 25%;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
      padding-left: 16px;
      grid-template-columns: 75% 25%;
    }
}

.NameLearner,
.DetailsLearner {
  font-family: var(--font-medium);
  font-size: 12px;
  line-height: 16px;
  padding: 10px 0;
  color: var(--color-dark-text);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 0;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    padding: 12px 0;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    padding: 14px 0;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    padding: 16px 0;
  }
}

.LearnerItemRankAndStatus {
  grid-template-columns: 65% 26% 9%;
}

.LearnerItemCourseProgress {
  grid-template-columns: 55% 21% 24%;
}

.WrapperRank {
  display: flex;
  cursor: pointer;
}

.Rank {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  color: var(--color-gray-border) !important;
  background-color: var(--color-gray-border);
  margin-right: 2px;
}

.RankActive {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  color: var(--color-tomato) !important;
  background-color: var(--color-tomato);
}

.Tooltip {
  font-family: var(--font-medium) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background: var(--color-dark-text) !important;
  border-radius: 6px !important;
}