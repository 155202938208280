@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.ContentItem {
  display: grid;
  grid-template-columns: 30% 30% 28% 11% 1%;
  width: 100%;
  border-radius: 6px;
  background: var(--color-white);
  font-family: var(--font-medium);
  padding: 9px 34px 9px 24px;
  box-sizing: border-box;
  margin-bottom: 10px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    align-self: center;
    max-width: 100px;
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
      max-width: 100px;
      font-size: 12px;
      line-height: 16px;
    }

    @include media_desktop_hd {
      max-width: 100px;
      font-size: 14px;
      line-height: 18px;
    }

    @include media_desktop_wxga {
      max-width: 130px;
      font-size: 18px;
      line-height: 22px;
    }

    @include media_desktop_full {
      max-width: 150px;
      font-size: 20px;
      line-height: 24px;
    }

    &:nth-child(-n+2) {
      max-width: 240px;

      @include media_desktop_hd {
        max-width: 220px;
      }

      @include media_desktop_wxga {
        max-width: 300px;
      }

      @include media_desktop_full {
        max-width: 350px;
      }
    }

    &:nth-child(3) {
      @include media_desktop_hd {
        max-width: 220px;
      }

      @include media_desktop_wxga {
        max-width: 280px;
      }

      @include media_desktop_full {
        max-width: 330px;
      }
    }
  }
  @include media_desktop_qhd {
    padding: 10px 34px 10px 24px;
  }

  @include media_desktop_hd {
    padding: 12px 34px 12px 24px;
  }

  @include media_desktop_wxga {
    padding: 14px 34px 14px 24px;
  }

  @include media_desktop_full {
    padding: 16px 34px 16px 24px;
  }
}

.ImageContainer {
  position: relative;
  align-self: center;
  justify-self: center;
  cursor: pointer;
}

.ImgDots {
  color: var(--color-gray-title);
  width: 17px;

  @include media_desktop_qhd {
    width: 17px;
  }

  @include media_desktop_hd {
    width: 20px;
  }

  @include media_desktop_wxga {
    width: 23px;
  }

  @include media_desktop_full {
    width: 26px;
  }
}
