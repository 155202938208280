@import '../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../assets/scss/medias';

.Input, .Label {
  box-sizing: border-box;
  font-family: var(--font-medium);
  font-size: 18px;
  line-height: 22px;

  @include media_tablet {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.Input {
  margin-top: 8px;
  padding: 12px 25px;

  @include media_desktop_qhd {
    padding: 8px 21px;
  }

  @include media_desktop_hd {
    padding: 8px 21px;
  }

  @include media_desktop_wxga {
    padding: 10px 23px;
  }

  @include media_desktop_full {
    padding: 12px 25px;
  }
}
