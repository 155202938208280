@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.CustomBrandContainer {
    display: flex;
    user-select: none;
    padding: 26px 60px 20px 40px;

    @include media_desktop_qhd {
        padding: 26px 60px 20px 40px;
    }

    @include media_desktop_hd {
        padding: 36px 70px 30px 40px;
    }

    @include media_desktop_wxga {
        padding: 46px 80px 40px 40px;
    }

    @include media_desktop_full {
        padding: 56px 90px 40px 40px;
    }
}