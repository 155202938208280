@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.WrapperExtras {
  display: flex;
  background: var(--color-white);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 24px 40px 26px 42px;

  @include media_desktop_hd {
    width: 918px;
  }

  @include media_desktop_hd-after {
    width: 1020px;
  }

  @include media_desktop_wxga {
    width: 1120px;
  }

  @include media_desktop_wxga-after {
    width: 1212px;
  }

  @include media_desktop_full {
    width: 1302px;
  }
}

.Title {
  text-align: left;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  @include media_desktop_wxga {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 18px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

.WrapperExtrasItem {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    @include media_desktop_hd {
      margin-right: 40px;
    }

    @include media_desktop_hd-after {
      margin-right: 50px;
    }

    @include media_desktop_wxga {
      margin-right: 50px;
    }

    @include media_desktop_wxga-after {
      margin-right: 80px;
    }

    @include media_desktop_full {
      margin-right: 75px;
    }
  }
}

.TextItem {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-left: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-left: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-left: 22px;
  }
}