@import '../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../assets/scss/medias';

.Container {

  @include media_desktop_hd {
    margin: 30px 0 42px 0;
  }

  @include media_desktop_wxga {
    margin: 35px 0 44px 0;
  }

  @include media_desktop_full {
    margin: 40px 0 46px 0;
  }
}

.Title {
  font-family: var(--font-medium);
  text-align: left;

  @include media_desktop_hd {
    font-size: 19px;
    line-height: 23px;
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px;
  }
}