@import '../../../../../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  //width: 98%;
}

.Header {
  display: grid;
  grid-template-columns: 15% 20% 35% 20% 10%;
  text-align: left;
  box-sizing: border-box;
  font-family: var(--font-regular);
  color: var(--color-silver);

  @include media_desktop_hd {
    margin: 44px 0 20px 0;
    padding: 0 12px;
  }

  @include media_desktop_wxga {
    margin: 49px 0 23px 0;
    padding: 0 14px;
  }

  @include media_desktop_full {
    margin: 54px 0 27px 0;
    padding: 0 16px;
  }
}

.DetailsHeader {
  padding-left: 16px;
}

.Action {
  text-align: right;
}