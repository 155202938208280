@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.Text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;

  @include media_desktop_qhd {
    width: 100px;
  }

  @include media_desktop_hd {
    width: 116px;
  }

  @include media_desktop_wxga {
    width: 180px;
  }

  @include media_desktop_full {
    width: 190px;
  }
}

.isOpenDropDown {
  border: 1px solid var(--color-tomato);
}

.DropDownIcon {
  transition: 0.4s;
}

.isActiveDropDownIcon {
  transition: 0.4s;
  transform: rotate(180deg);
}

.DisabledOptions {
  cursor: default;
  //pointer-events: none;
  opacity: 0.4;

  &:hover {
    color: #959595;
    opacity: 0.4;
  }
}

.WrapperForLoader {
  text-align: center;
}