@import '../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../assets/scss/medias';

.Title {
  text-align: left;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 19px;
    line-height: 22px;
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px;
  }
}

.SubWrapperOverview {
  overflow: auto;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  padding-right: 5px;

  @include media_desktop_hd {
    max-height: 467px;
  }

  @include media_desktop_wxga {
    max-height: 510px;
  }

  @include media_desktop_full {
    max-height: 554px;
  }
}

.DividerSubWrapper {

}