@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.OptionsList {
    position: absolute;
    width: 100%;
    bottom: -100px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-border);
    background-color: var(--color-white);
    box-sizing: border-box;
    text-align: left;

    @include media_desktop_qhd {
        padding: 18px 0 18px 12px;
        bottom: -90px;
    }

    @include media_desktop_hd {
        padding: 20px 0 20px 14px;
        bottom: -100px;
    }

    @include media_desktop_wxga {
        padding: 22px 0 22px 16px;
        bottom: -110px;
    }

    @include media_desktop_full {
        padding: 24px 0 24px 18px;
        bottom: -120px;
    }
}

.OptionItem {
    cursor: pointer;
    color: var(--color-black);

    &:first-child {
        margin-top: 0;
    }

    &:hover {
        color: var(--color-tomato);
    }

    @include media_desktop_qhd {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        margin-top: 12px;
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        margin-top: 14px;
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        margin-top: 16px;
        font-size: 18px;
        line-height: 22px;
    }
}

.AddQuestionsButton {
    display: flex;
    align-items: center;
    color: var(--color-tomato);
    border: 1px solid var(--color-tomato);
    border-radius: 6px;
    padding: px-to-vh(8px) px-to-vh(16px);

    @include media_desktop_qhd {
        margin: 18px auto 0;
    }

    @include media_desktop_hd {
        margin: 20px auto 0;
    }

    @include media_desktop_wxga {
        margin: 22px auto 0;
    }

    @include media_desktop_full {
        margin: 24px auto 0;
    }
}

.ButtonImg {
    @include media_desktop_qhd {
        height: 18px;
        width: 18px;
        margin-right: 10px;
    }

    @include media_desktop_hd {
        height: 20px;
        width: 20px;
        margin-right: 12px;
    }

    @include media_desktop_wxga {
        height: 22px;
        width: 22px;
        margin-right: 14px;
    }

    @include media_desktop_full {
        height: 24px;
        width: 24px;
        margin-right: 16px;
    }
}

.AddQuestionsText {
    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}