@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.LearnerInviteContainer {
  width: 422px;
  border-radius: 6px;
  outline: 0;
  border: 1px solid var(--color-gray-border);
}

.InviteInputField {
  width: 90%;
  border: none;
  font-family: var(--font-medium);

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.InvalidInviteInputField {
  border: 1px solid var(--color-tomato-light);

  input {
    &::placeholder {
      color: var(--color-tomato-light);
    }
  }
}

.ErrorText {
  font-family: var(--font-medium);

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}