@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.SubtitleSubscription {
  font-family: var(--font-medium);
  color: var(--color-silver);

  @include media_desktop_wxga {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.WrapperForCardDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ContainerCardDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-gray-background-card);
  border-radius: 6px;
  margin-top: 30px;
  box-sizing: border-box;
  width: 100%;

  @include media_desktop_hd {
    padding: 8px 12px;
    width: 205px;
  }

  @include media_desktop_wxga {
    padding: 10px 24px;
    width: 250px;
  }

  @include media_desktop_full {
    padding: 13px 32px;
    width: 325px;
  }

  span {
    @include media_desktop_hd {
      font-size: 14px;
      line-height: 18px;
    }

    @include media_desktop_wxga {
      font-size: 16px;
      line-height: 20px;
    }

    @include media_desktop_full {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.NumberCard {
  font-family: var(--font-medium);
}

.WrapperForIconCard {
  background: var(--color-white);
  border-radius: 5px;
}

.CardImg {
  width: 26px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media_desktop_hd {
    padding: 6px 9px;
  }

  @include media_desktop_wxga {
    padding: 7px 10px;
  }

  @include media_desktop_full {
    padding: 8px 11px;
  }
}

.ChangeCardImg {
  svg {
    border: 1px solid var(--color-tomato);
    border-radius: 6px;
    cursor: pointer;
    fill: var(--color-tomato);
    margin-top: 30px;

    @include media_desktop_hd {
      width: 15px;
      height: 16px;
      padding: 12px;
    }

    @include media_desktop_wxga {
      width: 16px;
      height: 17px;
      padding: 14px;
    }

    @include media_desktop_full {
      width: 17px;
      height: 18px;
      padding: 16px;
    }

    &:hover {
      border: 1px solid var(--color-light-tomato);
      fill: var(--color-light-tomato);
    }
  }
}
