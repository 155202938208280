@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.WrapperSubscription {
  padding: 26px 60px 20px 40px;

  @include media_desktop_qhd {
    padding: 26px 60px 20px 40px;
  }

  @include media_desktop_hd {
    padding: 36px 70px 30px 40px;
  }

  @include media_desktop_wxga {
    padding: 46px 80px 40px 40px;
  }

  @include media_desktop_full {
    padding: 56px 90px 40px 40px;
  }
}

.SubtitleSubscription {
  font-family: var(--font-medium);
  color: var(--color-silver);

  @include media_desktop_wxga {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.TitleSubscription {
  font-family: var(--font-medium);
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 24px;

  @include media_desktop_qhd {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 24px;
  }

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 27px;
  }

  @include media_desktop_wxga {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 33px;
  }
}

.WrapperDetailsSubscription {
  display: flex;
  justify-content: space-between;
}

.ContainerCard,
.ContainerSubscription,
.ContainerNextPayment {
  background: var(--color-white);
  border-radius: 6px;
  width: 100%;
  padding: 32px 40px 45px 40px;
  box-sizing: border-box;

  @include media_desktop_hd {
    padding: 22px 25px 35px 25px;
  }

  @include media_desktop_wxga {
    padding: 22px 30px 35px 30px;
  }

  @include media_desktop_full {
    padding: 32px 40px 45px 40px;
  }
}

.ContainerSubscription {
  @include media_desktop_hd {
    max-width: 310px;
  }

  @include media_desktop_wxga {
    max-width: 380px;
  }

  @include media_desktop_full {
    max-width: 448px;
  }
}

.ContainerCard {
  @include media_desktop_hd {
    max-width: 320px;
  }

  @include media_desktop_wxga {
    max-width: 380px;
  }

  @include media_desktop_full {
    max-width: 485px;
  }
}

.ContainerNextPayment {
  @include media_desktop_hd {
    max-width: 260px;
  }

  @include media_desktop_wxga {
    max-width: 360px;
  }

  @include media_desktop_full {
    max-width: 386px;
  }
}

.DataNextPayment {
  font-family: var(--font-medium);
  margin-top: 30px;
  display: flex;
  justify-content: center;

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_wxga {
    font-size: 19px;
    line-height: 23px;
  }
}

.SubtitleSubscription {
  font-family: var(--font-medium);
  color: var(--color-silver);

  @include media_desktop_wxga {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.WrapperForSeats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.Button {
  padding: 12px 32px;

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}