@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.HeaderMetrics {
  font-family: var(--font-regular);
  color: var(--color-silver);
  display: grid;
  box-sizing: border-box;
  user-select: none;
  padding: 0 22px;


  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    width: 727px;
    margin-bottom: 28px;
    grid-template-columns: 85% 15%;
  }

  @include media_desktop_hd-after {
    font-size: 16px;
    line-height: 20px;
    width: 827px;
    margin-bottom: 28px;
    grid-template-columns: 85% 15%;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    width: 927px;
    margin-bottom: 30px;
    grid-template-columns: 86% 14%;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    width: 1027px;
    margin-bottom: 32px;
    grid-template-columns: 86% 14%;
  }
}

.Action {
  text-align: right;
}

.MetricsAndAmount {
  display: flex;
}

.NoData {
  display: flex;
  justify-content: center;
  margin-top: 150px;
  font-family: var(--font-medium);
  font-size: 20px;
}