@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.CourseCellItem {
    display: block;
    //margin: 0 auto;
    border-radius: 6px;

    @include media_desktop_hd {
        width: 210px;
        height: 144px;
    }

    @include media_desktop_wxga {
        width: 258px;
        height: 184px;
    }

    @include media_desktop_full {
        max-width: 275px;
        width: 275px;
        height: 238px;
    }

    &:hover {
        box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
    }
}

.CourseCellContainer {
    border-radius: 6px 6px 0 0;
    font-family: var(--font-medium);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--color-white);
    overflow: hidden;
    user-select: none;
    position: relative;
    background: var(--color-white);
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    @include media_desktop_hd {
        font-size: 18px;
        line-height: 24px;
        height: 90px;
        width: 210px;
        padding: px-to-vh(15px) px-to-vh(22px);
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 28px;
        height: 120px;
        width: 258px;
        padding: px-to-vh(22px);
    }

    @include media_desktop_full {
        font-size: 28px;
        line-height: 34px;
        height: 150px;
        width: 275px;
        padding: px-to-vh(24px);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
    }
}

.CourseName {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 80px;
    overflow: hidden;
    word-break: break-word;

    @include media_desktop_hd {
        max-height: 80px;
    }

    @include media_desktop_wxga {
        max-height: 100px;
    }

    @include media_desktop_full {
        max-height: 140px;
    }
}

.DotsContainer {
    position: relative;
    user-select: none;

    @include media_desktop_hd {
        margin: 14px;
    }

    @include media_desktop_wxga {
        margin: 16px;
    }

    @include media_desktop_full {
        margin: 20px;
    }
}

.DotsImage {
    cursor: pointer;

    @include media_desktop_qhd {
        width: 16px;
        height: 16px;
    }

    @include media_desktop_hd {
        width: 24px;
        height: 24px;
    }

    @include media_desktop_wxga {
        width: 40px;
        height: 40px;
    }
}

.SignatureContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    background: var(--color-white);
    border-radius: 0 0 6px 6px;

    @include media_desktop_hd {
        width: 210px;
        height: 54px;
    }

    @include media_desktop_wxga {
        width: 258px;
        height: 64px;
    }

    @include media_desktop_full {
        width: 275px;
        height: 88px;
    }
}

.LearnersWrapper {
    display: flex;
    flex-direction: column;
    text-align: left;

    @include media_desktop_hd {
        margin-left: 21px;
    }

    @include media_desktop_wxga {
        margin-left: 23px;
    }

    @include media_desktop_full {
        margin-left: 25px;
    }
}

.Learner {
    font-family: var(--font-regular);
    color: var(--color-gray-border);

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.LearnersNumbers {
    font-family: var(--font-medium);
    color: var(--color-silver);

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
        max-width: 130px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
        max-width: 150px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        max-width: 170px;
    }
}