@import '../../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../../assets/scss/medias';

.ContainerSub {
  position: absolute;
  background: var(--color-white);
  border: 1px solid var(--color-gray-border);
  box-sizing: border-box;
  border-radius: 6px;
  box-sizing: border-box;
  right: 6px;
  top: 34px;
  z-index: 9;

  @include media_desktop_hd {
    width: 280px;
    max-height: 230px;
    padding: 10px 6px 24px 18px;
  }

  @include media_desktop_wxga {
    width: 330px;
    max-height: 236px;
    padding: 12px 6px 26px 20px;
  }

  @include media_desktop_full {
    width: 352px;
    max-height: 244px;
    padding: 14px 6px 28px 22px;
  }
}

.WrapperGoBack {
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  &:hover {
    cursor: pointer;
    color: var(--color-tomato);
    svg {
      fill: var(--color-tomato);
    }
  }
}

.TextGoBack {
  font-family: var(--font-regular);

  @include media_desktop_hd {
    margin-left: 6px;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    margin-left: 7px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    margin-left: 8px;
    font-size: 18px;
    line-height: 22px;
  }
}

.ImageMenu {
  display: flex;
  align-items: center;

  svg {
    @include media_desktop_hd {
      width: 8px;
      height: 11px;
    }

    @include media_desktop_wxga {
      width: 9px;
      height: 12px;
    }

    @include media_desktop_full {
      width: 10px;
      height: 13px;
    }
    fill: var(--color-dark-text);
  }

  @include media_desktop_hd {
    width: 8px;
    height: 12px;
  }

  @include media_desktop_wxga {
    width: 9px;
    height: 13px;
  }

  @include media_desktop_full {
    width: 10px;
    height: 14px;
  }
}

.TitleSub {
  font-family: var(--font-medium);
  color: var(--color-gray-title);
  text-align: left;

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  @include media_desktop_wxga {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 14px;
  }

  @include media_desktop_full {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

.SubMenu {
  overflow-y: auto;
  overflow-x: auto;
  text-align: left;

  @include media_desktop_hd {
    max-height: 130px;
  }

  @include media_desktop_wxga {
    max-height: 130px;
  }

  @include media_desktop_full {
    max-height: 134px;
  }
}

.WrapperInputImageSub {
  &:not(:last-child) {
    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 12px;
    }

    @include media_desktop_full {
      margin-bottom: 12px;
    }
  }
}

.LabelSub {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--font-medium);

  input[type='checkbox'] {
    transition: 0.4s;
    cursor: pointer;
    box-sizing: border-box;
    appearance: none;
    padding: 0;
    background: var(--color-white);
    border-radius: 2px;
    outline: 1px solid var(--color-gray-border);
    border: 3px solid white;
    margin-left: 3px;
    width: 18px;
    height: 18px;
    margin-right: 16px;
  }

  input[type='checkbox']:checked {
    transition: 0.4s;
    transform: scale(1.1);
    box-sizing: border-box;
    background: var(--color-tomato);
    border-radius: 2px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.StageItem {
  @include media_desktop_hd {
    max-width: 200px;
  }

  @include media_desktop_wxga {
    max-width: 240px;
  }

  @include media_desktop_full {
    max-width: 250px;
  }
}