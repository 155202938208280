@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.MainPopupContainer {
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        width: 520px;
        padding: 18px 18px 38px 18px;
    }

    @include media_desktop_hd {
        width: 650px;
        padding: 20px 20px 42px 20px;
    }

    @include media_desktop_wxga {
        width: 740px;
        padding: 22px 22px 46px 22px;
    }

    @include media_desktop_full {
        width: 813px;
        padding: 24px 24px 50px 24px;
    }
}

.TitleText {
    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
    }
}

.InputContainer {
    text-align: left;

    @include media_desktop_qhd {
        padding: 25px;
    }

    @include media_desktop_hd {
        padding: 30px;
    }

    @include media_desktop_wxga {
        padding: 35px;
    }

    @include media_desktop_full {
        padding: 40px;
    }
}

.InputLabel {
    color: var(--color-black);

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.InputNameField {
    min-height: 28px !important;
    max-height: 110px;
    overflow-y: auto;
}

.TextArea {
    display: block;
    border-radius: 6px;
    width: 100%;
    min-height: 88px;
    border: 1px solid var(--color-gray-border);
    box-sizing: border-box;
    font-family: var(--font-medium);
    max-height: 110px;
    overflow-y: auto;

    &::placeholder {
        color: var(--color-silver);
    }

    @include media_desktop_qhd {
        padding: 10px;
        margin: 10px 0;
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        padding: 12px;
        margin: 12px 0;
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        padding: 14px;
        margin: 14px 0;
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        padding: 16px;
        margin: 16px 0;
        font-size: 20px;
        line-height: 24px;
    }
}

.SaveButton {
    margin: 0 auto;
    color: var(--color-white);
}

.InformationText {
    color: var(--color-silver);
}