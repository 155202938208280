@import '../../../../assets/scss/helpers';
@import '../../../../assets/scss/medias';

.InputError {
    border: 1px solid var(--color-tomato-light) !important;

    &:focus{
        border: 1px solid var(--color-tomato);
        box-shadow: 0 0 1px 1px var(--color-tomato-light);
    }

    &::placeholder {
        color: var(--color-tomato);
    }
}

.InputContainer {
    position: relative;
    margin-bottom: 20px;
}

.EyeButtonContainer {
    position: absolute;
    bottom: 24%;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    cursor: pointer;

    @include media_desktop_qhd {
        bottom: 18%;
    }

    @include media_desktop_hd {
        bottom: 18%;
    }

    @include media_desktop_wxga {
        bottom: 27%;
    }

    @include media_desktop_full {
        bottom: 28%;
    }
}

.PasswordInput {
    padding-right: 40px;
}

.ShowPassword {
    height: 24px;
    width: 24px;

    @include media_desktop_qhd {
        height: 20px;
        width: 20px;
    }

    @include media_desktop_hd {
        height: 20px;
        width: 20px;
    }

    @include media_desktop_wxga {
        height: 20px;
        width: 20px;
    }

    @include media_desktop_full {
        height: 20px;
        width: 20px;
    }
}