@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.MainTitle {
  font-family: var(--font-medium);
  margin-bottom: 26px;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 26px;
  }

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 26px;
  }

  @include media_desktop_wxga {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 34px;
  }
}

.WrapperTemplatesComponent {
  @include media_desktop_hd {
    padding: 61px 50px 100px 50px;
  }

  @include media_desktop_wxga {
    padding: 75px 70px 100px 60px;
  }

  @include media_desktop_full {
    padding: 86px 112px 100px 97px;
  }
}

.AddNewCourseContainer {
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  background: var(--color-tomato);
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 2px solid var(--color-tomato);

  @include media_desktop_hd {
    width: 210px;
    height: 144px;
  }

  @include media_desktop_wxga {
    width: 258px;
    height: 184px;
  }

  @include media_desktop_full {
    width: 275px;
    height: 238px;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  }
}

.ImagePlus {
  @include media_desktop_qhd {
    margin-top: 23px;
    height: 24px;
    width: 24px;
  }

  @include media_desktop_hd {
    margin-top: 31px;
    height: 48px;
    width: 48px;
  }

  @include media_desktop_wxga {
    margin-top: 38px;
    height: 72px;
    width: 72px;
  }

  @include media_desktop_full {
    margin-top: 54px;
    height: 96px;
    width: 96px;
  }
}

.TitleAddCourse {
  font-family: var(--font-medium);
  display: flex;
  letter-spacing: 0.01em;
  color: var(--color-white);
  justify-content: center;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_wxga {
    font-size: 24px;
    line-height: 28px;
  }

  @include media_desktop_full {
    font-size: 28px;
    line-height: 34px;
  }
}

.TemplateList {
    display: grid;
    width: auto;
    margin: 0 auto;
    text-align: center;

    @include media_desktop_qhd {
      //width: 980px;
      grid-gap: 30px;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    @include media_desktop_hd {
      //width: 980px;
      grid-gap: 30px;
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    }

    @include media_desktop_wxga {
      //width: 1080px;
      grid-gap: 35px;
      grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
    }

    @include media_desktop_full {
      //width: 1280px;
      grid-gap: 40px;
      grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    }
}

.TemplateListItem {
  display: block;
  border-radius: 6px;


  @include media_desktop_hd {
    width: 210px;
    height: 144px;
    margin-top: 32px;
  }

  @include media_desktop_wxga {
    width: 258px;
    height: 184px;
    margin-top: 36px;
  }

  @include media_desktop_full {
    max-width: 275px;
    width: 275px;
    height: 238px;
    margin-top: 40px;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  }
}
.WrapperForTemplateIcon {
  background: var(--color-white);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media_desktop_hd {
    width: 90px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    width: 92px;
    height: 20px;

    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_full {
    width: 94px;
    height: 22px;
    font-size: 14px;
    line-height: 18px;
  }
}

.TemplateImg {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.TemplateContainer {
  border-radius: 6px 6px 0 0;
  font-family: var(--font-medium);
  user-select: none;
  position: relative;
  background: var(--color-background-blue);
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 3;
  overflow: hidden;

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 24px;
    height: 96px;
    width: 210px;
    padding: 15px 22px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 28px;
    height: 120px;
    width: 258px;
    padding: 24px;
  }

  @include media_desktop_full {
    font-size: 28px;
    line-height: 34px;
    height: 150px;
    width: 275px;
    padding: 24px;
  }
}

.TemplateText {
  color: var(--color-white);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 50px;
  overflow: hidden;
  word-break: break-word;
  text-align: center;

  @include media_desktop_hd {
    max-height: 50px;
    margin-top: 16px;
  }

  @include media_desktop_wxga {
    max-height: 60px;
    margin-top: 18px;
  }

  @include media_desktop_full {
    max-height: 70px;
    margin-top: 20px;
  }
}

.SignatureContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background: var(--color-white);
  border-radius: 0 0 6px 6px;

  @include media_desktop_hd {
    width: 210px;
    height: 48px;
  }

  @include media_desktop_wxga {
    width: 258px;
    height: 64px;
  }

  @include media_desktop_full {
    width: 275px;
    height: 88px;
  }
}

.SignatureText {
  margin-left: 24px;
  font-family: var(--font-medium);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.Logo {
  width: 86px;
  height: 34px;
  margin-left: 10px;
}

.WrapperTrainingExpert {
  cursor: pointer;
  border-radius: 6px;
  background: var(--color-white);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 2px solid var(--color-tomato);
  font-family: var(--font-medium);
  display: flex;
  justify-content: center;
  align-items: center;

  @include media_desktop_hd {
    width: 210px;
    height: 144px;
  }

  @include media_desktop_wxga {
    width: 258px;
    height: 184px;
  }

  @include media_desktop_full {
    width: 275px;
    height: 238px;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  }
}

.Link {
  text-decoration: none;
  color: var(--color-tomato);
}

.ImgTraining {
  @include media_desktop_hd {
    width: 46px;
    height: 36px;
    margin-bottom: 11px;
  }

  @include media_desktop_wxga {
    width: 50px;
    height: 40px;
    margin-bottom: 15px;
  }

  @include media_desktop_full {
    width: 54px;
    height: 46px;
    margin-bottom: 19px;
  }
}

.TrainingText {
  @include media_desktop_hd {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 6px;
  }

  @include media_desktop_wxga {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  @include media_desktop_full {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 10px;
  }
}

.SubtitleText {
  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.WrapperForTemplates {
  border-top: 1px solid var(--color-gray-border);

  @include media_desktop_hd {
    margin-top: 32px;
  }

  @include media_desktop_wxga {
    margin-top: 36px;
  }

  @include media_desktop_full {
    margin-top: 40px;
  }
}

.TitleTemplate {
  font-family: var(--font-medium);
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    margin-top: 32px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-top: 36px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-top: 40px;
  }
}

.ImageBook {
  width: 22px;
  height: 22px;
  margin-left: 5px;
}

.RocketImg {
  width: 22px;
  height: 22px;
  margin-left: 5px;
}