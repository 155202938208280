@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.GoalComponent {
    display: flex;
    width: 100%;
    height: max-content;

    @include media_desktop_hd {
        width: 425px;
    }

    @include media_desktop_wxga {
        width: 600px;
    }

    @include media_desktop_full {
        width: 775px;
    }
}

.ImageContainer {
    display: block;
    text-align: center;
    position: relative;
}

.Line {
    width: 100%;
    height: 100%;
    max-width: 1px;
    background: var(--color-gray-border);
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.StepText {
    font-family: var(--font-regular);
    color: var(--color-silver);
    margin: 1px 6px 0 0;

    @include media_desktop_hd {
        font-size: 13px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 15px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 17px;
        line-height: 22px;
    }
}

.InputField {
    width: 100%;
    border: 1px solid var(--color-gray-border);
    border-radius: 5px;
    box-sizing: border-box;
    height: 100%;
    font-family: var(--font-medium);

    @include media_desktop_hd {
        max-height: 65px;
        font-size: 16px;
        line-height: 18px;
        padding: 14px;
    }

    @include media_desktop_wxga {
        height: 68px;
        font-size: 18px;
        line-height: 20px;
        padding: 16px;
    }

    @include media_desktop_full {
        height: 72px;
        font-size: 20px;
        line-height: 24px;
        padding: 16px;
    }
}

.InputContainer {
    width: 100%;

    @include media_desktop_hd {
        margin-left: 30px;
    }

    @include media_desktop_wxga {
        margin-left: 35px;
    }

    @include media_desktop_full {
        margin-left: 40px;
    }
}

.NextStepButton {
    color: var(--color-white);
    font-family: var(--font-medium);

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 22px;
        padding: px-to-vh(12px) px-to-vh(32px);
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.IntroducedGoalInputContainer {
    opacity: 0.3;

    .InputField {
        &::selection {
            background: none;
        }
    }
}

.ErrorInput {
    border: 1px solid var(--color-tomato-light) !important;

    &:focus {
        border: 1px solid var(--color-tomato);
        box-shadow: 0 0 1px 1px var(--color-tomato-light);
    }

    &::placeholder {
        color: var(--color-tomato);
    }
}


.CharactersCount {
    display: block;
    text-align: right;
    font-family: var(--font-regular);
    color: var(--color-silver);
    width: 100%;

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 23px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 27px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 31px;
    }
}

.IntroducedName {
    margin-bottom: 30px;

    input {
        user-select: none;
    }
}

.ErrorCharactersCount {
    color: var(--color-tomato);
}