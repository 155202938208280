@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.ImageContainer {
    max-width: 853px;
    width: 100%;
    padding: 5px 0;
    border-radius: 10px;
    text-align: center;

    @include media_desktop_hd {
        max-width: 873px;
    }

    @include media_desktop_wxga {
        max-width: 980px;
    }

    @include media_desktop_full {
        max-width: 1150px;
    }
}