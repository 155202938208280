@import '../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../assets/scss/medias';

.Options {
  font-size: 18px;
  line-height: 22px;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.Title, .DropDownContainer {
  font-family: var(--font-medium);
  font-size: 18px;
  line-height: 22px;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.Title {
  color: var(--color-dark-text);
}

.DropDownContainer {
  box-sizing: border-box;
  width: 320px;
  padding: 12px 20px 12px 25px;

  @include media_desktop_qhd {
    width: 280px;
    padding: 8px 16px 8px 21px;
  }

  @include media_desktop_hd {
    width: 280px;
    padding: 8px 16px 8px 21px;
  }

  @include media_desktop_wxga {
    width: 300px;
    padding: 10px 18px 10px 23px;
  }

  @include media_desktop_full {
    width: 320px;
    padding: 12px 20px 12px 25px;
  }
}

.DropDownMenuContainer {
  box-sizing: border-box;
  width: 320px;
  height: auto;

  @include media_desktop_qhd {
    width: 280px;
  }

  @include media_desktop_hd {
    width: 280px;
  }

  @include media_desktop_wxga {
    width: 300px;
  }

  @include media_desktop_full {
    width: 320px;
  }
}