@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.QuizContainer {
    @include media_desktop_qhd {
        padding-right: 25px;
    }

    @include media_desktop_hd {
        padding-right: 30px;
    }

    @include media_desktop_wxga {
        padding-right: 35px;
    }

    @include media_desktop_full {
        padding-right: 40px;
    }
}