@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.ContainerPro {
  background: var(--color-white);
  border-radius: 6px;
  box-sizing: border-box;

  @include media_desktop_hd {
    width: 590px;
    padding: 15px 36px 54px 36px;
    margin-bottom: 28px;
    margin-left: 40px;
  }

  @include media_desktop_hd-after {
    width: 620px;
    padding: 15px 36px 54px 36px;
    margin-bottom: 28px;
    margin-left: 50px;
  }

  @include media_desktop_wxga {
    width: 690px;
    padding: 20px 40px 64px 40px;
    margin-bottom: 38px;
    margin-left: 50px;
  }

  @include media_desktop_wxga-after {
    width: 722px;
    padding: 20px 40px 64px 40px;
    margin-bottom: 38px;
    margin-left: 50px;
  }

  @include media_desktop_full {
    width: 802px;
    padding: 25px 46px 74px 46px;
    margin-bottom: 48px;
    margin-left: 50px;
  }
}

.ProPlanWrapper {
  text-align: center;
}

.PlanTitlePro {
  font-family: var(--font-medium);
  color: var(--color-green);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 21px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 23px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 25px;
  }
}

.PlanPro {
  font-family: var(--font-bold);
  color: var(--color-tomato);

  @include media_desktop_hd {
    font-size: 39px;
    line-height: 49px;
    margin-bottom: 6px;
  }

  @include media_desktop_wxga {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 8px;
  }

  @include media_desktop_full {
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 10px;
  }
}

.SeatsPro {
  font-family: var(--font-medium);
  color: var(--color-gray-title);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 23px;

  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 25px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 28px;
  }
}

.Link {
  text-align: center;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  text-decoration: none;
  border-radius: 6px;
  outline: none;
  font-family: var(--font-medium);
  background-color: var(--color-tomato);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-hover-btn);
  }

  &:active {
    background-color: var(--color-tomato-active);
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 32px;
    padding: 8px 24px;
    width: 130px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 35px;
    padding: 10px 28px;
    width: 140px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 38px;
    padding: 14px 32px;
    width: 150px;
  }
}

.ContainerDetailsPro {
  display: flex;
  justify-content: space-between;
}

.SubtitlePro {
  font-family: var(--font-medium);
  color: var(--color-gray-title);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 7px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 9px;
  }
}

.Item {
  display: flex;
  align-items: center;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 9px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}

.Image {
  @include media_desktop_hd {
    margin-right: 18px;
  }

  @include media_desktop_wxga {
    margin-right: 20px;
  }

  @include media_desktop_full {
    margin-right: 22px;
  }
}

.WrapperDetailsPro {
  @include media_desktop_hd {
    margin-bottom: 25px;
  }

  @include media_desktop_wxga {
    margin-bottom: 28px;
  }

  @include media_desktop_full {
    margin-bottom: 31px;
  }
}