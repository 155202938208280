@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.WrapperPipedrive {
  margin-bottom: 84px;
}

.WrapperIcon {
  position: relative;
}

.InfoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InfoText {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.DropDownIcon {
  transition: 0.4s;
}

.isActiveDropDownIcon {
  transition: 0.4s;
  transform: rotate(180deg);
}


.ConnectionBtn {
  display: flex;
  text-decoration: none;
  font-family: var(--font-medium);
  color: var(--color-white);
  align-items: center;
  background-color: var(--color-tomato);
  margin-left: 24px;
  font-size: 20px;
  line-height: 23px;
  border-radius: 6px;
  padding: 8px 26px;
  margin-right: 10px;
  cursor: pointer;

  @include media_desktop_qhd {
    margin-left: 18px;
    font-size: 14px;
    line-height: 23px;
    padding: 8px 26px;
  }

  @include media_desktop_hd {
    margin-left: 20px;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 28px;
  }

  @include media_desktop_wxga {
    margin-left: 22px;
    font-size: 18px;
    line-height: 24px;
    padding: 12px 30px;
  }

  @include media_desktop_full {
    margin-left: 24px;
    font-size: 20px;
    line-height: 24px;
    padding: 15px 32px;
  }

  &:hover {
    background: var(--color-hover-btn);
  }
}

.DescriptionAndLightImg {
  display: flex;
  align-items: center;
}

.LightImg {
  svg {
    fill: var(--color-tomato);
    @include media_desktop_hd {
      padding: 1px;
    }

    @include media_desktop_wxga {
      padding: 3px 1px;
    }

    @include media_desktop_full {
      padding: 2px 2px 4px 2px;
    }
  }
}

.WrapperImg {
  border: 1px solid var(--color-tomato);
  border-radius: 50%;
  margin-left: 12px;
  height: 19px;
  width: 19px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.ImgBtn {
  @include media_desktop_hd {
    margin-right: 14px;
  }

  @include media_desktop_wxga {
    margin-right: 16px;
  }

  @include media_desktop_full {
    margin-right: 18px;
  }
}

.WrapperDescription {
  font-family: var(--font-regular);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-top: 33px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-top: 43px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-top: 53px;
  }
}

.DisconnectButton {
  font-family: var(--font-medium);
  color: var(--color-tomato);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }

  &:hover {
    color: var(--color-tomato-hover);
  }
}