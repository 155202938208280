@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.Container {
  margin: px-to-vh(64px) auto 0;
  text-align: center;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.WrapperSubTitleImg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Image {
  @include media_desktop_hd {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }

  @include media_desktop_wxga {
    width: 22px;
    height: 22px;
    margin-left: 6px;
  }

  @include media_desktop_full {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
}

.LoginButton {
  display: block;
  margin: px-to-vh(64px) auto;
  box-sizing: border-box;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
    width: 200px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    width: 280px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    width: 300px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    width: 320px;
  }
}