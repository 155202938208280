.HiddenInput {
    display: none;
}

.InputField {
    display: flex;
    text-align: left;
    font-family: var(--font-regular);
    color: var(--color-grey);
    padding-left: 12px;
}