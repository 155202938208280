@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.TitleCustomers {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    margin: 32px 0;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_wxga {
    margin: 34px 0;
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_full {
    margin: 36px 0;
    font-size: 24px;
    line-height: 28px;
  }
}

.HeaderCostumers {
  display: grid;
  grid-template-columns: 16% 16% 16% 22% 15% 15%;
  font-family: var(--font-regular);
  color: var(--color-silver);

    @include media_desktop_hd {
      font-size: 16px;
      line-height: 20px;
      padding: 0 20px 32px 20px;
    }

    @include media_desktop_wxga {
      font-size: 18px;
      line-height: 22px;
      padding: 0 22px 34px 22px;
    }

    @include media_desktop_full {
      font-size: 20px;
      line-height: 24px;
      padding: 0 24px 36px 24px;
    }
}