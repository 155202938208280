@import '../../../../../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../../../../../assets/scss/medias';

.FilterContainer {
  position: relative;
  display: inline-block;
}

.Title {
  font-family: var(--font-regular);
  color: var(--color-gray-title);
}

.DropDownContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  color: var(--color-dark-text);
  font-size: 11px;
  line-height: 15px;
  background: var(--color-white);
  font-family: var(--font-medium);
  margin-top: 8px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 15px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 17px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.DropDownValue {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ActiveDropDown {
  border: 1px solid #F85A40;
  color: var(--color-silver);
}

.DropDownMenuContainer {
  border: 1px solid var(--color-light-grey);
  background: white;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 16px 4px 16px 14px;
  left: 0;
  top: 115%;
  height: max-content;

  @include media_desktop_wxga {
    padding: 16px 4px 16px 14px;
  }

  @include media_desktop_hd {
    padding: 18px 6px 18px 16px;
  }

  @include media_desktop_wxga {
    padding: 22px 8px 22px 18px;
  }

  @include media_desktop_full {
    padding: 24px 10px 24px 20px;
  }
}

.DropDownMenuSelected {
  top: 115%;
}

.DropDownOverflow {
  max-height: 100px;
  overflow-y: auto;
}

.DropDownIcon {
  transition: 0.4s;
}

.isActiveDropDownIcon {
  transition: 0.4s;
  transform: rotate(180deg);
}

.WrapperForLoader {
  text-align: center;
}

.OptionsWrapper {
  &:not(:last-child) {
    margin-bottom: 10px !important;

    @include media_desktop_qhd {
      margin-bottom: 10px !important;
    }

    @include media_desktop_hd {
      margin-bottom: 12px !important;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px !important;
    }

    @include media_desktop_full {
      margin-bottom: 16px !important;
    }
  }
}
