@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.CourseList {
    display: grid;
    width: auto;
    margin: 0 auto;
    text-align: center;

    @include media_desktop_qhd {
        //width: 980px;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    @include media_desktop_hd {
        //width: 980px;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    }

    @include media_desktop_wxga {
        //width: 1080px;
        grid-gap: 35px;
        grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
    }

    @include media_desktop_full {
        //width: 1280px;
        grid-gap: 40px;
        grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    }
}

.AddNewCourseContainer {
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    background: var(--color-white);
    display: block;
    //margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-tomato);

    @include media_desktop_hd {
        width: 210px;
        height: 144px;
    }

    @include media_desktop_wxga {
        width: 258px;
        height: 184px;
    }

    @include media_desktop_full {
        width: 275px;
        height: 238px;
    }

    &:hover {
        box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
    }
}

.ImagePlus {
    @include media_desktop_qhd {
        margin-top: 23px;
        height: 24px;
        width: 24px;
    }

    @include media_desktop_hd {
        margin-top: 31px;
        height: 48px;
        width: 48px;
    }

    @include media_desktop_wxga {
        margin-top: 38px;
        height: 72px;
        width: 72px;
    }

    @include media_desktop_full {
        margin-top: 54px;
        height: 96px;
        width: 96px;
    }
}

.TitleAddCourse {
    font-family: var(--font-medium);
    display: flex;
    letter-spacing: 0.01em;
    color: var(--color-tomato);
    justify-content: center;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 18px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 28px;
    }

    @include media_desktop_full {
        font-size: 28px;
        line-height: 34px;
    }
}

.WrapperFilter {
    display: flex;

    @include media_desktop_hd {
        margin-bottom: 24px;
    }

    @include media_desktop_wxga {
        margin-bottom: 28px;
    }

    @include media_desktop_full {
        margin-bottom: 32px;
    }
}

.WrapperForCourseList {
    @include media_desktop_hd {
        padding: 38px 50px 100px 50px;
    }

    @include media_desktop_wxga {
        padding: 38px 70px 100px 60px;
    }

    @include media_desktop_full {
        padding: 48px 112px 100px 97px;
    }
}


.ImageClose {
    transition: 0.4s;
    transform: rotate(360deg);
    cursor: pointer;

    @include media_desktop_hd {
        width: 20px;
        height: 20px;
    }

    @include media_desktop_wxga {
        width: 22px;
        height: 22px;
    }

    @include media_desktop_full {
        width: 24px;
        height: 24px;
    }
}

.SearchImage {
    transition: 0.4s;

    @include media_desktop_hd {
        width: 20px;
        height: 20px;
    }

    @include media_desktop_wxga {
        width: 22px;
        height: 22px;
    }

    @include media_desktop_full {
        width: 24px;
        height: 24px;
    }
}

.InputSearch {
    margin-left: 10px;
    background: transparent;
    border: none;
    padding: 0;
    font-family: var(--font-regular);

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.MainFilterContainer {
    display: block;
}

.WrapperTitleAndIcon {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.CoursesNone {
    position: absolute;
    top: 28%;
    left: 44%;
    text-align: center;
    font-family: var(--font-medium);
    font-size: 22px;
    line-height: 28px;

    @include media_desktop_qhd {
        top: 28%;
        left: 44%;
        font-size: 22px;
        line-height: 28px;
    }

    @include media_desktop_hd {
        top: 35%;
        left: 45%;
        font-size: 24px;
        line-height: 30px;
    }

    @include media_desktop_wxga {
        top: 35%;
        left: 45%;
        font-size: 26px;
        line-height: 32px;
    }

    @include media_desktop_full {
        top: 40%;
        left: 45%;
        font-size: 28px;
        line-height: 34px;
    }
}