@import '../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../assets/scss/medias';

.Container {
  display: block;
  margin: 0 auto;

  @include media_desktop_qhd {
    display: flex;
    justify-content: center;
  }

  @include media_desktop_hd {
    display: flex;
    justify-content: center;
  }

  @include media_desktop_wxga {
    display: flex;
    justify-content: center;
  }

  @include media_desktop_full {
    display: flex;
    justify-content: center;
  }
}

.Item {
  cursor: pointer;
  border: 1px solid var(--color-gray-border);
  box-sizing: border-box;
  border-radius: 6px;
  width: 320px;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  background: var(--color-white);
  margin: 0 0 30px 0;

  @include media_tablet {
    margin: 0 0 30px 0;
  }

  @include media_desktop_qhd {
    width: 310px;
    padding: 20px 12px;
    margin: 0;
  }

  @include media_desktop_hd {
    width: 310px;
    padding: 20px 12px;
    margin: 0;
  }

  @include media_desktop_wxga {
    width: 350px;
    padding: 25px 14px;
    margin: 0;
  }

  @include media_desktop_full {
    width: 390px;
    padding: 30px 16px;
    margin: 0;
  }

  span {
    font-family: var(--font-medium);
    font-size: 22px;
    line-height: 26px;
    max-width: 249px;

    @include media_desktop_qhd {
      font-size: 19px;
      line-height: 23px;
      max-width: 100%;
    }

    @include media_desktop_hd {
      font-size: 19px;
      line-height: 23px;
      max-width: 100%;
    }

    @include media_desktop_wxga {
      font-size: 21px;
      line-height: 25px;
      max-width: 100%;
    }

    @include media_desktop_full {
      font-size: 24px;
      line-height: 28px;
      max-width: 100%;
    }
  }


  &:not(:last-child) {

    @include media_desktop_qhd {
      margin-bottom: 30px;
    }

    @include media_desktop_hd {
      margin-bottom: 30px;
    }

    @include media_desktop_wxga {
      margin-bottom: 30px;
    }

    @include media_desktop_full {
      margin-bottom: 30px;
    }
  }
}

.WrapperItem {
  input[type='checkbox'] {
    transition: 0.2s;
    cursor: pointer;
    box-sizing: border-box;
    appearance: none;
    padding: 0;
    background: var(--color-white);
    border-radius: 2px;
    outline: 1px solid var(--color-gray-border);
    border: 3px solid white;
    margin-left: 0;
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  input[type='checkbox']:checked {
    transition: 0.2s;
    transform: scale(1.1);
    box-sizing: border-box;
    background: var(--color-tomato);
    border-radius: 2px;
  }
}

.WrapperItemFirstList {
  margin-right: 0;

  @include media_desktop_qhd {
    margin-right: 40px;
  }

  @include media_desktop_hd {
    margin-right: 40px;
  }

  @include media_desktop_wxga {
    margin-right: 40px;
  }

  @include media_desktop_full {
    margin-right: 40px;
  }
}

.ContinueButtonNeeds {
  margin: 0 0 32px 0;
  font-size: 20px;
  line-height: 24px;
  width: 320px;

  @include media_tablet {
    margin: 0 0 32px 0;
    font-size: 20px;
    line-height: 24px;
    width: 320px;
  }

  @include media_desktop_qhd {
    font-size: 16px;
    line-height: 20px;
    width: 280px;
    margin: 32px auto;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    width: 280px;
    margin: 32px auto;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    width: 300px;
    margin: 32px auto;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    width: 320px;
    margin: 32px auto;
  }
}