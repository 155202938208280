@import '../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../assets/scss/medias';

.LearnerInviteCell {
  display:flex;
  justify-content: space-between;
  margin: 0 7px 0 20px;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-gray-border);
}

.RemoveInvitationButton {
  display: flex;
  margin-left: 20px;
  height: 18px;
  align-self: center;

  svg {
    fill: var(--color-gray);
    height: auto;

    &:hover {
      fill: var(--color-tomato);
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}

.EditableInviteInput {
  padding: 5px 0;
  width: 60%;
  border: none;
  font-family: var(--font-medium);

  @include media_desktop_qhd {
    font-size: 14px;
  }

  @include media_desktop_hd {
    font-size: 16px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
  }

  @include media_desktop_full {
    font-size: 20px;
  }
}
