root {
    letter-spacing: 0.01em;
}

.react-pdf__Page__annotations {
    display: none;
}

.calendar {
    position: relative;
}

.rdrMonth {
    width: 280px !important;
    height: 240px !important;
    padding-bottom: 0 !important;
}

.rdrDateDisplayWrapper {
    border-radius: 6px 6px 0 0 !important;
}

.ql-editor {
    overflow: visible !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    border-radius: 6px 0 0 6px !important;
}
.react-tel-input .selected-flag {
    border-radius: 6px 0 0 6px !important;
}
