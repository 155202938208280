@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.ModuleListContainer {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--color-white);

    @include media_desktop_qhd {
        width: 224px;
    }

    @include media_desktop_hd {
        width: 313px;
    }

    @include media_desktop_wxga {
        width: 392px;
    }

    @include media_desktop_full {
        width: 417px;
    }

    &::-webkit-scrollbar {
        width: 2px;
    }
}

.LogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-light-grey);
    margin: 0 auto;

    @include media_desktop_qhd {
        height: 56px;
        width: 118px;
    }

    @include media_desktop_hd {
        height: 76px;
        width: 164px;
    }

    @include media_desktop_wxga {
        height: 112px;
        width: 234px;
    }
}

.Logo {
    cursor: pointer;
    display: flex;
    justify-content: center;

    @include media_desktop_hd {
        width: 175px;
        height: 48px;
    }

    @include media_desktop_wxga {
        width: 200px;
        height: 60px;
    }

    @include media_desktop_full {
        width: 230px;
        height: 70px;
    }
}

.CourseName {
    display: block;
    box-sizing: border-box;
    font-family: var(--font-medium);
    color: var(--color-silver);
    word-wrap: break-word;

    @include media_desktop_qhd {
        font-size: 16px;
        line-height: 28px;
        padding: px-to-vh(31px) 0 px-to-vh(22px) px-to-vh(44px);
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 30px;
        width: 240px;
        padding: px-to-vh(35px) 0 px-to-vh(25px) px-to-vh(48px);
    }

    @include media_desktop_wxga {
        font-size: 24px;
        line-height: 32px;
        width: 280px;
        padding: px-to-vh(38px) 0 px-to-vh(27px) px-to-vh(52px);
    }

    @include media_desktop_full {
        font-size: 28px;
        line-height: 34px;
        width: 330px;
        padding: px-to-vh(41px) 0 px-to-vh(31px) px-to-vh(56px);
    }
}

.WrapperForModules {
    display: flex;
    align-items: center;
}

.WrapperBtn {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.AddModuleButton {
    display: flex;
    align-items: center;
    font-family: var(--font-medium);
    margin-left: 4px;


    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.PlusImg {
    width: 16px;
    height: 16px;
}
