$tablet-width: 768px;
$desktop-width-qhd: 960px;
$desktop-width-hd: 1280px;
$desktop-width-hd-after: 1400px;
$desktop-width-medium: 1600px;
$desktop-width-medium-after: 1800px;
$desktop-width-full: 1920px;

@mixin media_tablet {
    @media only screen and (min-width: $tablet-width) {
        @content;
    }
}

@mixin media_desktop_qhd {
    @media only screen and (min-width: $desktop-width-qhd) {
        @content;
    }
}

@mixin media_desktop_hd {
    @media only screen and (min-width: $desktop-width-hd) {
        @content;
    }
}

@mixin media_desktop_hd-after {
    @media only screen and (min-width: $desktop-width-hd-after) {
        @content;
    }
}

@mixin media_desktop_wxga {
    @media only screen and (min-width: $desktop-width-medium) {
        @content;
    }
}

@mixin media_desktop_wxga-after {
    @media only screen and (min-width: $desktop-width-medium-after) {
        @content;
    }
}

@mixin media_desktop_full {
    @media only screen and (min-width: $desktop-width-full) {
        @content;
    }
}

@mixin media_custom($min-width, $max-width) {
    @media only screen and (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}
