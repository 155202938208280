@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.MainPopupContainer {
  padding: 38px 38px 72px 38px;

  @include media_desktop_hd {
    width: 553px;
    padding: 18px 18px 52px 18px;
  }

  @include media_desktop_wxga {
    width: 663px;
    padding: 28px 22px 62px 22px;
  }

  @include media_desktop_full {
    width: 763px;
    padding: 38px 24px 72px 24px;
  }
}

.WrapperInviteInput {
  padding: 0 26px;
}
