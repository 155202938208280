@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.WrapperOptions {
    display: flex;
      &:not(:last-child) {

        @include media_desktop_hd {
          margin-bottom: 12px;
        }

        @include media_desktop_wxga {
          margin-bottom: 14px;
        }

        @include media_desktop_full {
          margin-bottom: 16px;
        }
      }
}

.Options {
    display: block;
    cursor: pointer;
    font-family: var(--font-medium);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
      &:hover {
        color: var(--color-tomato);
      }
}

.OptionsImg {
  width: 13px;
  height: 15px;

  @include media_desktop_qhd {
    width: 13px;
    height: 15px;
  }

  @include media_desktop_hd {
    width: 15px;
    height: 17px;
  }

  @include media_desktop_wxga {
    width: 17px;
    height: 19px;
  }

  @include media_desktop_full {
    width: 19px;
    height: 21px;
  }
}

.ReportsImg {
  margin-left: 16px;
}

.ActiveOptions {
    color: var(--color-tomato);
}

.Tooltip {
  font-family: var(--font-medium) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background: var(--color-dark-text) !important;
  border-radius: 6px !important;
}
