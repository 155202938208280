@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.AddContentContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;
    user-select: none;
    padding-bottom: 230px;

    @include media_desktop_qhd {
        margin-top: 26px;
    }

    @include media_desktop_hd {
        margin-top: 28px;
    }

    @include media_desktop_wxga {
        margin-top: 30px;
    }

    @include media_desktop_full {
        margin-top: 32px;
    }
}

.AddButtonImage {
    border-radius: 50%;
    cursor: pointer;
    display: block;

    @include media_desktop_hd {
        height: 40px;
        width: 40px;
    }

    @include media_desktop_wxga {
        height: 44px;
        width: 44px;
    }

    @include media_desktop_full {
        height: 48px;
        width: 48px;
    }
}

.AddButtonText {
    display: block;
    font-family: var(--font-medium);
    color: var(--color-tomato);
    cursor: pointer;

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        margin-left: 12px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        margin-left: 14px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        margin-left: 16px;
    }
}