@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.MainContainerDesktop {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;
    overflow: hidden;
}

.MainContainerMobile {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;
}

.BackgroundImage {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.ContentContainer {
    display: flex;
    justify-content: space-between;

    @include media_desktop_hd {
        margin-top: px-to-vh(140px);
        padding: 0 12% 5% 8%;
    }

    @include media_desktop_wxga {
        margin-top: px-to-vh(150px);
        padding: 0 12% 5% 9%;
    }

    @include media_desktop_full {
        margin-top: px-to-vh(160px);
        padding: 0 13% 5% 10%;
    }

    padding: 0 10% 5% 10%;
    width: 100%;
}

.ContentContainerMobile {
    padding: 0;
    justify-content: center;
}

.SignUpContainerMobile {
    display: block;
}

.SignUpContainer {
    display: flex;
    align-items: center;
}

.TitleContainerWelcome {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 52px 0 32px 0;
}

.TitleText {
    display: block;
    font-family: var(--font-medium);
    color: var(--color-tomato);
    font-size: 28px;
    line-height: 34px;

    @include media_tablet {
        font-size: 40px;
        line-height: 44px;
    }

    @include media_desktop_qhd {
        font-size: 42px;
        line-height: 46px;
    }

    @include media_desktop_hd {
        font-size: 44px;
        line-height: 50px;
    }

    @include media_desktop_wxga {
        font-size: 46px;
        line-height: 54px;
    }

    @include media_desktop_full {
        font-size: 48px;
        line-height: 58px;
    }

    span {
        color: var(--color-black);
    }
}

.TitleContainer {
    display: flex;
    align-items: center;
    margin-bottom: px-to-vh(32px);

    span {
        font-family: var(--font-regular);
        width: 100%;
        white-space: nowrap;
        display: block;
        font-size: 16px;
        line-height: 22px;
        margin: 0 px-to-vh(12px);

        @include media_tablet {
            font-size: 16px;
            line-height: 22px;
        }

        @include media_desktop_qhd {
            font-size: 14px;
            line-height: 20px;
        }

        @include media_desktop_hd {
            font-size: 14px;
            line-height: 20px;
        }

        @include media_desktop_wxga {
            font-size: 16px;
            line-height: 20px;
        }

        @include media_desktop_full {
            font-size: 18px;
            line-height: 22px;
        }
    }
}

.Line {
    height: 0.5px;
    width: 100%;
    background-color: var(--color-silver);
}

.LabelInputText {
    display: block;
    font-family: var(--font-regular);
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 8px;

    @include media_tablet {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 8px;
    }

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: px-to-vh(8px);
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: px-to-vh(8px);
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: px-to-vh(8px);
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: px-to-vh(8px);
    }
}

.InputField {
    display: block;
    box-sizing: border-box;
    font-family: var(--font-regular);
    font-size: 18px;
    line-height: 22px;
    padding: 12px 25px;
    margin-bottom: 24px;

    &::placeholder {
        color: var(--color-gray-border);
    }

    @include media_tablet {
        font-size: 18px;
        line-height: 22px;
        padding: 12px 25px;
        margin-bottom: 24px;
    }

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: px-to-vh(25px);
        padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: px-to-vh(25px);
        padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: px-to-vh(25px);
        padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: px-to-vh(25px);
        padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);
    }
}

.PrivacyPolicyContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 32px;

    @include media_tablet {
        margin-bottom: 32px;
    }

    @include media_desktop_qhd {
        margin-bottom: px-to-vh(32px);
    }

    @include media_desktop_hd {
        margin-bottom: px-to-vh(32px);
    }

    @include media_desktop_wxga {
        margin-bottom: px-to-vh(32px);
    }

    @include media_desktop_full {
        margin-bottom: px-to-vh(32px);
    }


    span {
        font-family: var(--font-regular);
        color: var(--color-silver);
        font-size: 14px;
        line-height: 18px;

        @include media_tablet {
            font-size: 14px;
            line-height: 18px;
        }

        @include media_desktop_qhd {
            font-size: 12px;
            line-height: 16px;
        }

        @include media_desktop_hd {
            font-size: 12px;
            line-height: 16px;
        }

        @include media_desktop_wxga {
            font-size: 14px;
            line-height: 18px;
        }

        @include media_desktop_full {
            font-size: 16px;
            line-height: 20px;
        }
    }

    a {
        font-family: var(--font-bold);
        color: var(--color-silver);
        text-decoration: none;

        &:hover {
            color: var(--color-tomato);
            cursor: pointer;
        }
    }

    input[type='checkbox'] {
        cursor: pointer;
        border: 1px solid var(--color-gray-border);
        border-radius: 2px;
        color: var(--color-white);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        width: 24px;
        height: 24px;
        margin: 0 24px 0 0;
        padding: 10px 10px;

        @include media_tablet {
            width: 24px;
            height: 24px;
            margin: 0 24px 0 0;
            padding: 10px 10px;
        }

        @include media_desktop_qhd {
            width: 20px;
            height: 20px;
            margin: 0 18px 0 0;
            padding: 8px 8px;
        }

        @include media_desktop_hd {
            width: 20px;
            height: 20px;
            margin: 0 18px 0 0;
            padding: 8px 8px;
        }

        @include media_desktop_wxga {
            width: 22px;
            height: 22px;
            margin: 0 22px 0 0;
            padding: 9px 9px;
        }

        @include media_desktop_full {
            width: 24px;
            height: 24px;
            margin: 0 24px 0 0;
            padding: 10px 10px;
        }

        &:checked {
            background-color: var(--color-tomato);
            background-image: url('../../../assets/images/icon/check-box-mark-white.svg');
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid var(--color-tomato);
        }
    }
}

.InputContainer {
    margin: px-to-vh(20px) auto 0;
    width: 320px;

    @include media_tablet {
        width: 320px;
    }

    @include media_desktop_qhd {
        width: 300px;
    }

    @include media_desktop_hd {
        width: 300px;
    }

    @include media_desktop_wxga {
        width: 310px;
    }

    @include media_desktop_full {
        width: 320px;
    }
}

.SignUpButton {
    width: 100% !important;
    display: block;
    box-sizing: border-box;
    font-size: 20px;
    line-height: 24px;
    margin: px-to-vh(32px) auto !important;

    @include media_tablet {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_qhd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.SignInButton {
    display: block;
    margin: px-to-vh(32px) auto !important;
    color: var(--color-tomato);
    font-family: var(--font-regular);
    font-size: 18px;
    line-height: 22px;

    @include media_tablet {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.LogoImage {
    width: 136px;
    height: 37px;
    padding: 25px 0 0 25px;
}

