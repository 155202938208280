@import '../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../assets/scss/medias';

.WrapperExplanation {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Personal {
  border-radius: 6px;
  background: var(--color-tomato);
  width: 24px;
  height: 6px;
}

.Team {
  border-radius: 6px;
  width: 24px;
  height: 6px;
  background: var(--color-silver);
}

.PersonalLast {
  border-radius: 6px;
  width: 24px;
  height: 6px;
  background: var(--color-tomato-hover);
}

.TitleExplanation {
  font-family: var(--font-regular);
  margin: 0 32px 0 12px;
  color: var(--color-dark-text);

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
  }
}