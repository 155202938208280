@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.WrapperForLogoAndDeleteIcon {
    display: flex;
    margin-right: 62px;

    @include media_desktop_qhd {
        margin-right: 62px;
    }

    @include media_desktop_hd {
        margin-right: 72px;
    }

    @include media_desktop_wxga {
        margin-right: 82px;
    }

    @include media_desktop_full {
        margin-right: 92px;
    }
}

.NewLogoContainer {
    display: block;
    margin-right: 78px;

    @include media_desktop_qhd {
        margin-right: 78px;
    }

    @include media_desktop_hd {
        margin-right: 88px;
    }

    @include media_desktop_wxga {
        margin-right: 98px;
    }

    @include media_desktop_full {
        margin-right: 108px;
    }

}

.BrandLogoDowload {
    margin-right: 10px;
}

.TitleText {
    font-family: var(--font-medium);
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 40px;

    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 42px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 44px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 46px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 48px;
    }
}

.LogoImage {
    width: 96px;
    height: 96px;
}

.NewImg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

.LogoAndBtnContainer{
    display: flex;
}

.DeleteImage {
    display: flex;
    align-items: center;
    cursor: pointer;


    svg {
        fill: var(--color-silver);
        height: 22px;
        width: 22px;
        &:hover {
            fill: var(--color-tomato);
            transform: scale(1.1);
        }
    }
}

.InputContainer {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LogoContainer {
   border: none;
   background: transparent;
}

.LogoContainerEmply {
    cursor: pointer;
    background: var(--color-white);
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid var(--color-tomato);
    box-sizing: border-box;
    border-radius: 6px;

    @include media_desktop_qhd {
       height: 150px;
        width: 220px;
    }

    @include media_desktop_wxga {
        height: 150px;
        width: 240px;
    }

    @include media_desktop_wxga {
        height: 170px;
        width: 290px;
    }

    @include media_desktop_full {
        width: 307px;
        height: 196px;
    }


    &:hover {
        .DarkBackground {
            border-radius: 6px;
            background: rgba(0, 0, 0, 0.25);
            transition: all 0.4s ease-in-out 0s;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 1;
        }
    }
}

.DarkBackground {
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease-in-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
}

.SignatureText {
    font-size: 16px;
    font-family: var(--font-regular);
    display: block;
    width: 220px;
    text-align: left;
    color: var(--color-silver);
    margin-top: 16px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

