@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  display: flex;
  align-items: center;
  font-family: var(--font-regular);

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
  }
}

.ProgressBar {
  height: 7px;
  background: var(--color-gray-border);
  border-radius: 6px;
  margin-right: 16px;
  box-sizing: border-box;

  @include media_desktop_hd {
    width: 213px;
  }

  @include media_desktop_hd-after {
    width: 263px;
  }

  @include media_desktop_wxga {
    width: 310px;
  }

  @include media_desktop_full {
    width: 363px;
  }
}

.WrapperImageResult {
  display: flex;
}

.Image {
  margin-left: 6px;

  @include media_desktop_hd {
    width: 12px;
    height: 12px;
  }

  @include media_desktop_wxga {
    width: 13px;
    height: 13px;
  }

  @include media_desktop_full {
    width: 14px;
    height: 14px;
  }
}

.WrapperImageBest {
  cursor: pointer;
}

.Tooltip {
  font-family: var(--font-medium);
  border-radius: 6px;
  padding: 10px 30px;

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
  }
}

.BetterPerformance {
  cursor: pointer;
}