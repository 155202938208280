@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.Title {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 40px;
  }
}

.Subtitle {
  font-family: var(--font-regular);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    width: 250px;
    margin: 0 auto 20px auto;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    width: 280px;
    margin: 0 auto 30px auto;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    width: 320px;
    margin: 0 auto 40px auto;
  }
}

.InputContainer {
  display: flex;
  align-items: center;
  height: min-content;
}

.ButtonInvite {
  @include media_desktop_hd {
    margin-left: 16px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    margin-left: 20px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    margin-left: 24px;
    font-size: 20px;
    line-height: 24px;
  }
}

.ButtonSave {
  @include media_desktop_hd {
    margin: 40px auto 0 auto;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    margin: 40px auto 0 auto;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    margin: 40px auto 0 auto;
    font-size: 20px;
    line-height: 24px;
  }
}

.InviteButtonClick {
  color: var(--color-white);
  align-self: flex-start;
  font-family: var(--font-medium);

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
    padding: 12px 20px;
    margin-left: 10px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    padding: 14px 24px;
    margin-left: 12px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    padding: 16px 28px;
    margin-left: 14px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    padding: 18px 32px;
    margin-left: 16px;
  }
}

.DisabledBtn {
  cursor: default;
  background-color: var(--color-gray-border);

  &:hover {
    background-color: var(--color-gray-border);
  }
}

.Tooltip {
  font-family: var(--font-medium) !important;
  font-size: 12px !important;
}