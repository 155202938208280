@import '../../../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  background: var(--color-white);
  border-radius: 6px;
  box-sizing: border-box;

  @include media_desktop_hd {
    padding: 28px 20px 28px 28px;
    margin-bottom: 23px;
  }

  @include media_desktop_wxga {
    padding: 30px 22px 30px 30px;
    margin-bottom: 28px;
  }

  @include media_desktop_full {
    padding: 32px 24px 32px 32px;
    margin-bottom: 33px;
  }

  &:not(:nth-child(3n)) {

    @include media_desktop_hd {
      margin-right: 23px;
    }

    @include media_desktop_wxga {
      margin-right: 28px;
    }

    @include media_desktop_full {
      margin-right: 33px;
    }
  }
  &:nth-last-child(-n+3) {
    margin-bottom: 0;

  }
}

.WrapperHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media_desktop_hd {
    margin-bottom: 26px;
  }

  @include media_desktop_wxga {
    margin-bottom: 28px;
  }

  @include media_desktop_full {
    margin-bottom: 30px;
  }
}

.Header {
  display: flex;
  align-items: center;
}

.MetricName {
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    max-width: 110px;

  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    max-width: 150px;

  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    max-width: 210px;

  }
}

.Tooltip {
  font-family: var(--font-medium) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background: var(--color-dark-text) !important;
  border-radius: 6px !important;
}

.Percentage {
  font-family: var(--font-medium);
  border-radius: 6px;

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 11px;
    margin-left: 12px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
    padding: 5px 12px;
    margin-left: 16px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
    padding: 6px 13px;
    margin-left: 20px;
  }
}

.ColorPercentageGreen {
  border: 1px solid var(--color-green);
  color: var(--color-green);
}

.ColorPercentageRed {
  color: var(--color-tomato-active);
  border: 1px solid var(--color-tomato-active);
}

.ColorPercentageYellow {
  color: var(--color-yellow);
  border: 1px solid var(--color-yellow);
}

.TeamPersonalText {
  font-family: var(--font-regular);
  color: var(--color-silver);
  text-align: right;

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
  }
}

.ContainerProgressBar {
  display: grid;
  grid-template-columns: 30% 55% 15%;
  font-family: var(--font-medium);
  align-items: center;
  text-align: left;

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
    grid-template-columns: 25% 60% 15%;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
    grid-template-columns: 25% 60% 15%;
  }
}

.Result {
  text-align: right;
}

.WrapperProgressBar {
    width: 100%;
}

.ProgressBarPeriod {
  background: var(--color-tomato);
  border-radius: 6px;
  height: 6px;
}

.ProgressBarDate {
  background: var(--color-silver);
  border-radius: 6px;
  height: 6px;
}

