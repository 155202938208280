@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.CourseConstructorContainer {
    width: 100%;
    height: 100vh;
    background-color: var(--color-light-background);
    overflow-y: auto;
    //display: flex;
}

.LogoContainer {
    left: 65%;
}

.MainContainer {
    width: 61vw;
}