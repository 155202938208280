@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.HiddenBackground {
    background-color: var(--color-silver);
    opacity: .3;
}

.MainPopupContainer {
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: var(--color-white);
    z-index: 4;
    border-radius: 6px;
    width: 730px;
    height: 407px;

    @include media_desktop_qhd {
        width: 730px;
        height: 407px;
    }

    @include media_desktop_hd {
        width: 830px;
        height: 457px;
    }

    @include media_desktop_wxga {
        width: 930px;
        height: 507px;
    }

    @include media_desktop_full {
        width: 1030px;
        height: 537px;
    }
}

.CloseContainer {
    display: block;
    text-align: right;
}

.CloseButton {
    cursor: pointer;
    margin: 12px 12px 0 0;
    width: 28px;
    height: 28px;

    @include media_desktop_wxga {
        margin: 12px 12px 0 0;
        width: 28px;
        height: 28px;
    }

    @include media_desktop_wxga {
        margin: 16px 16px 0 0;
        width: 32px;
        height: 32px;
    }

    @include media_desktop_wxga {
        margin: 20px 20px 0 0;
        width: 36px;
        height: 36px;
    }

    @include media_desktop_full {
        margin: 24px 24px 0 0;
        width: 40px;
        height: 40px;
    }
}

.TitleText {
    display: block;
    text-align: center;
    font-family: var(--font-medium);
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 25px;

    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 25px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 30px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 35px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 40px;
    }
}

.MainContainer {
    padding: 0 48px;
    text-align: left;

    @include media_desktop_qhd {
        padding: 0 28px 0 48px;
    }

    @include media_desktop_hd {
        padding: 0 38px 0 58px;
    }

    @include media_desktop_wxga {
        padding: 0 48px 0 68px;
    }

    @include media_desktop_full {
        padding: 0 58px 0 78px;
    }
}

.WrapperContent {
    overflow-x: hidden;
    overflow-y: auto;

    @include media_desktop_hd {
        max-height: 310px;
        min-height: 310px;
    }

    @include media_desktop_wxga {
        max-height: 335px;
        min-height: 335px;
    }

    @include media_desktop_full {
        max-height: 350px;
        min-height: 350px;
    }
}

.Subtitle {
    color: var(--color-gray-title);
    font-family: var(--font-regular);
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.SubtitleTask {
    margin-bottom: 18px;

    @include media_desktop_qhd {
        margin-bottom: 18px;
    }

    @include media_desktop_hd {
        margin-bottom: 20px;
    }

    @include media_desktop_wxga {
        margin-bottom: 22px;
    }

    @include media_desktop_full {
        margin-bottom: 24px;
    }
}

.SubtitleAnswer {
    margin: 26px 0 10px 0;

    @include media_desktop_qhd {
        margin: 26px 0 10px 0;
    }

    @include media_desktop_hd {
        margin: 28px 0 12px 0;
    }

    @include media_desktop_wxga {
        margin: 33px 0 14px 0;
    }

    @include media_desktop_full {
        margin: 43px 0 16px 0;
    }
}

.TaskName {
    font-family: var(--font-medium);
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;
    color: var(--color-silver);

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.TaskDescription {
    font-family: var(--font-regular);
    color: var(--color-silver);
    margin-top: 20px;
    font-size: 13px;
    line-height: 17px;
    word-break: break-word;

    @include media_desktop_qhd {
        margin-top: 20px;
        font-size: 13px;
        line-height: 17px;
    }

    @include media_desktop_hd {
        margin-top: 24px;
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        margin-top: 28px;
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        margin-top: 32px;
        font-size: 18px;
        line-height: 22px;
    }
}

.Answer {
    color: var(--color-dark-text);
    font-family: var(--font-medium);
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    word-break: break-word;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.AttachName {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    padding: 4px 8px;
    color: var(--color-dark-text);
    font-family: var(--font-medium);
    border: 1px solid var(--color-tomato);
    border-radius: 6px;
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 10px;

    @include media_desktop_qhd {
        font-size: 13px;
        line-height: 17px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.ClipImg {
    margin-right: 18px;
}

.Link {
    color: var(--color-blue);
    cursor: pointer;
}

.BtnOk {
    margin: 30px auto;
}

.ImageChecked {
    @include media_desktop_hd {
        width: 16px;
        height: 16px;
    }

    @include media_desktop_wxga {
        width: 18px;
        height: 18px;
    }

    @include media_desktop_full {
        width: 20px;
        height: 20px;
    }

    svg {
        fill: var(--color-tomato);

        @include media_desktop_hd {
            width: 16px;
            height: 16px;
        }

        @include media_desktop_wxga {
            width: 18px;
            height: 18px;
        }

        @include media_desktop_full {
            width: 20px;
            height: 20px;
        }
    }
}

.Button {
    background-color: var(--color-tomato);
    color: var(--color-white);
    font-family: var(--font-medium);
    outline: none;
    padding: 8px 18px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px solid var(--color-tomato);

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        padding: 9px 34px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        padding: 11px 36px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        padding: 13px 38px;
    }

    span {

        @include media_desktop_hd {
            margin-left: 18px;
        }

        @include media_desktop_wxga {
            margin-left: 20px;
        }

        @include media_desktop_full {
            margin-left: 22px;
        }
    }

    &:hover {
        border: 1px solid var(--color-hover-btn);
    }
}

.ButtonUnchecked {
    background-color: transparent;
    border: 1px solid var(--color-tomato);
    color: var(--color-tomato);

    &:hover {
        color: var(--color-hover-btn);
        border: 1px solid var(--color-hover-btn);
        background: transparent;
        span {
            color: var(--color-hover-btn);
        }
        svg {
            fill:var(--color-hover-btn);
        }
    }
}
