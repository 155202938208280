@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.MainOnboardeesContainer {
    padding: 26px 60px 20px 40px;

    @include media_desktop_qhd {
        padding: 26px 60px 20px 40px;
    }

    @include media_desktop_hd {
        padding: 36px 70px 30px 40px;
    }

    @include media_desktop_wxga {
        padding: 46px 80px 40px 40px;
    }

    @include media_desktop_full {
        padding: 56px 90px 40px 40px;
    }
}

.PerformanceContainer {
    padding: 26px 60px 20px 40px;
    user-select: none;

    @include media_desktop_qhd {
        padding: 26px 20px 20px 40px;
    }

    @include media_desktop_hd {
        padding: 36px 30px 30px 40px;
    }

    @include media_desktop_wxga {
        padding: 46px 40px 40px 40px;
    }

    @include media_desktop_full {
        padding: 56px 40px 40px 40px;
    }
}

.WrapperDetailsSeats {
    display: flex;
    justify-content: space-between;
    background: var(--color-white);
    border-radius: 6px;
    box-sizing: border-box;

    @include media_desktop_hd {
        padding: 36px 52px 24px 39px;
        margin-bottom: 36px;
        width: 314px;
    }

    @include media_desktop_wxga {
        padding: 40px 62px 28px 49px;
        margin-bottom: 46px;
        width: 344px;
    }

    @include media_desktop_full {
        padding: 46px 72px 32px 59px;
        margin-bottom: 56px;
        width: 374px;
    }
}

.TextSeats {
    text-align: center;
    font-family: var(--font-medium);
    color: var(--color-silver);

    @include media_desktop_hd {
        font-size: 13px;
        line-height: 17px;
    }

    @include media_desktop_wxga {
        font-size: 13px;
        line-height: 17px;
    }

    @include media_desktop_full {
        font-size: 14px;
        line-height: 18px;
    }
}

.NumbersSeats {
    font-family: var(--font-medium);
    text-align: center;

    @include media_desktop_hd {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 14px;
    }

    @include media_desktop_wxga {
        font-size: 23px;
        line-height: 27px;
        margin-bottom: 15px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
    }
}

.UpdateSeats {
    color: var(--color-green);
    text-decoration: underline;
    cursor: pointer;
}

.WrapperForFilters {
    display: flex;
    justify-content: space-between;
}

.ItemListContainer {
    display: block;
}

.HeaderTitleContainer {
    display: grid;
    grid-template-columns: 25% 25% 25% 24% 1%;
    font-family: var(--font-regular);
    color: var(--color-gray-title);
    padding: 0 24px;
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
        padding: 18px 34px 12px 24px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        padding: 28px 34px 16px 24px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        padding: 38px 34px 22px 24px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        padding: 48px 34px 32px 24px;
    }
}

.MainContainer {
    text-decoration: none;
    border: 1px solid var(--color-tomato);
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    background: transparent;
    

    @include media_desktop_hd {
        padding: 7.5px 16px;
        margin-top: 27px;
    }

    @include media_desktop_wxga {
        padding: 10px 16px;
        margin-top: 30px;
    }

    @include media_desktop_full {
        padding: 11px 16px;
        margin-top: 32px;
    }

    &:hover {
        color: var(--color-hover-btn);
        border: 1px solid var(--color-hover-btn);
        div {
            color: var(--color-hover-btn);
        }
        svg {
            fill:var(--color-hover-btn);
        }
    }
}



.Text {
    color: var(--color-tomato);
    text-decoration: none;
    font-family: var(--font-medium);
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
        
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
       
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
       
    }
}

.FilterContainer {
    display: flex;
    align-items: center;
}

.FilterContainerItem {
    font-size: 12px;
    line-height: 16px;
    margin-right: 20px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
        margin-right: 20px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-right: 24px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        margin-right: 28px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        margin-right: 32px;
    }
}

.Title {
    font-family: var(--font-regular);
    font-size: 12px;
    line-height: 16px;
    color: var(--color-gray-title);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.DropDownContainer {
    width: 146px;
    padding: 7px 14px 7px 13px;
    box-sizing: border-box;

    @include media_desktop_qhd {
        width: 146px;
        padding: 7px 14px 7px 13px;
    }

    @include media_desktop_hd {
        width: 166px;
        padding: 8px 15px 8px 14px;
    }

    @include media_desktop_wxga {
        width: 206px;
        padding: 9px 16px 9px 15px;
    }

    @include media_desktop_full {
        width: 246px;
        padding: 10px 17px 10px 16px;
    }
}

.DropDownMenuContainer {
    width: 83%;

    @include media_desktop_qhd {
        width: 83%;
    }

    @include media_desktop_hd {
        width: 86%;
    }

    @include media_desktop_wxga {
        width: 87%;
    }

    @include media_desktop_full {
        width: 87%;
    }
}

.Input {
    display: flex;
    align-items: center;
    width: 120px;
    padding: 7px 0 7px 13px;
    border-radius: 6px;
    z-index: 2;
    font-family: var(--font-bold);
    font-size: 20px;

    @include media_desktop_qhd {
        width: 120px;
        padding: 7px 0 7px 13px;
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        width: 166px;
        padding: 8px 16px 8px 20px;
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        width: 216px;
        padding: 9px 0 9px 15px;
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        width: 246px;
        padding: 10px 0 10px 16px;
        font-size: 20px;
        line-height: 24px;
    }

    &::placeholder {
        color: var(--color-dark-text);
        font-size: 12px;
        line-height: 16px;

        @include media_desktop_qhd {
            font-size: 12px;
            line-height: 16px;
        }

        @include media_desktop_hd {
            font-size: 14px;
            line-height: 18px;
        }

        @include media_desktop_wxga {
            font-size: 18px;
            line-height: 22px;
        }

        @include media_desktop_full {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.LearnerNoneFilter {
    position: absolute;
    top: 60%;
    left: 45%;
    text-align: center;
    font-family: var(--font-medium);
    font-size: 22px;
    line-height: 28px;

    @include media_desktop_qhd {
        font-size: 22px;
        line-height: 28px;
    }

    @include media_desktop_hd {
        font-size: 24px;
        line-height: 30px;
    }

    @include media_desktop_wxga {
        font-size: 26px;
        line-height: 32px;
    }

    @include media_desktop_full {
        font-size: 28px;
        line-height: 34px;
    }
}

.LearnerNone {
    display: flex;
    align-items: center;
    position: absolute;
    top: 28%;
    left: 45%;
    font-family: var(--font-medium);
    font-size: 22px;
    line-height: 28px;

    @include media_desktop_qhd {
        top: 28%;
        font-size: 22px;
        line-height: 28px;
    }

    @include media_desktop_hd {
        top: 35%;
        font-size: 24px;
        line-height: 30px;
    }

    @include media_desktop_wxga {
        top: 35%;
        font-size: 26px;
        line-height: 32px;
    }

    @include media_desktop_full {
        top: 40%;
        font-size: 28px;
        line-height: 34px;
    }
}

.LearnerNoneTitle {
    margin-right: 10px;
}

.NoData {
    display: flex;
    justify-content: center;
    font-family: var(--font-medium);
    font-size: 20px;
    margin-top: 150px;
}


.ResetFilters {
    font-family: var(--font-medium);
    box-sizing: border-box;
    background: var(--color-tomato);
    color: var(--color-white);
    border-radius: 6px;

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 20px;
        margin-top: 27px;
        padding: 7.5px 24px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        margin-top: 30px;
        padding: 10px 28px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        margin-top: 32px;
        padding: 11px 32px;
    }

    &:hover {
        background: var(--color-hover-btn);
    }
}


.ButtonInvite {
    display: flex;
    align-items: center;
    font-family: var(--font-medium);
    box-sizing: border-box;
    background: var(--color-tomato);
    color: var(--color-white);
    border-radius: 6px;
    margin-left: 24px;
    border: '1px solid';
    border-color: var(--color-tomato);

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 20px;
        margin-top: 27px;
        padding: 7.5px 16px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        margin-top: 30px;
        padding: 11px 16px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        margin-top: 32px;
        padding: 12px 16px;
    }
    

    &:hover {
        background: var(--color-hover-btn);
    }
}

.ImageInvite {
    width: 16px;
    height: 16px;
    margin-right: 10px;

    @include media_desktop_wxga {
        width: 18px;
        height: 18px;
        margin-right: 12px;
    }

    @include media_desktop_full {
        width: 20px;
        height: 20px;
        margin-right: 14px;

    }
}
