@import '../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../assets/scss/medias';

.Label {
  font-family: var(--font-medium);
  display: inline-block;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 22px;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.InputError {
  border: 1px solid var(--color-tomato-light) !important;

  &:focus{
    border: 1px solid var(--color-tomato);
    box-shadow: 0 0 1px 1px var(--color-tomato-light);
  }

  &::placeholder {
    color: var(--color-tomato);
  }
}

.PhoneInput.PhoneInput {
  width: 100%;
  border-radius: 6px;
  font-family: var(--font-medium);
  box-sizing: border-box;
  font-size: 18px;
  line-height: 22px;
  padding: 12px 25px 12px 60px;
  height: 48px;


  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 21px 8px 50px;
    height: 36px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 21px 8px 50px;
    height: 36px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 23px 10px 55px;
    height: 42px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    padding: 12px 25px 12px 60px;
    height: 48px;
  }
}

.ButtonDropDown.ButtonDropDown  {
  max-width: max-content;
  border-radius: 6px 0 0 6px;
  background: var(--color-light);
   &:hover {
     border-radius: 6px 0 0 6px ;
   }
}

.DropDown.DropDown {
  width: 320px;

  @include media_desktop_qhd {
    width: 280px;
  }

  @include media_desktop_hd {
    width: 280px;
  }

  @include media_desktop_wxga {
    width: 300px;
  }

  @include media_desktop_full {
    width: 320px;
  }
}

.Description {
  font-family: var(--font-regular);
  color: var(--color-silver);
  margin-top: 6px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_wxga {
    font-size: 13px;
    line-height: 17px;
  }

  @include media_desktop_full {
    font-size: 14px;
    line-height: 18px;
  }
}