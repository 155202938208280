@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.MainPopupContainer {
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        width: 520px;
        padding: 18px 18px 38px 18px;
    }

    @include media_desktop_hd {
        width: 650px;
        padding: 20px 20px 42px 20px;
    }

    @include media_desktop_wxga {
        width: 740px;
        padding: 22px 22px 46px 22px;
    }

    @include media_desktop_full {
        width: 813px;
        padding: 24px 24px 50px 24px;
    }
}

.ContentContainer {
    width: 100%;
    text-align: center;
    padding: 0 px-to-vh(40px);
    box-sizing: border-box;
}

.PopupTitle {
    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
    }
}

.SaveButton {
    color: var(--color-white);
    display: block;
    margin: 0 auto;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        padding: 6px 20px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        padding: 8px 24px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        padding: 10px 28px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        padding: 12px 32px;
    }
}
