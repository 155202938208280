@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.DropDownContainer {
    display: block;
    position: relative;
    text-align: left;
    max-width: max-content;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
        margin: 20px 0 10px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0 12px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin: 30px 0 14px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin: 35px 0 16px;
    }
}

.DropDownLabel {
    font-family: var(--font-regular);
    color: var(--color-silver);
}

.DropDown {
    color: var(--color-tomato);
    border: 1px solid var(--color-gray-border);
    padding: px-to-vh(8px) px-to-vh(8px) px-to-vh(8px) px-to-vh(16px);
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include media_desktop_qhd {
        margin-top: 2px;
    }

    @include media_desktop_hd {
        margin-top: 4px;
    }

    @include media_desktop_wxga {
        margin-top: 6px;
    }

    @include media_desktop_full {
        margin-top: 8px;
    }
}

.DropDownName {
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        margin-right: 66px;
    }

    @include media_desktop_hd {
        margin-right: 76px;
    }

    @include media_desktop_wxga {
        margin-right: 86px;
    }

    @include media_desktop_full {
        margin-right: 96px;
    }
}

.DropDownIcon {
    transition: 0.4s;

    @include media_desktop_qhd {
        width: 18px;
        height: 18px;
    }

    @include media_desktop_hd {
        width: 20px;
        height: 20px;
    }

    @include media_desktop_wxga {
        width: 22px;
        height: 22px;
    }

    @include media_desktop_full {
        width: 24px;
        height: 24px;
    }
}

.OptionsList {
    position: absolute;
    z-index: 99;
    width: 100%;
    bottom: -100px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-border);
    background-color: var(--color-white);
    box-sizing: border-box;
    text-align: left;

    @include media_desktop_qhd {
        padding: 18px 0 18px 12px;
        bottom: -90px;
    }

    @include media_desktop_hd {
        padding: 20px 0 20px 14px;
        bottom: -100px;
    }

    @include media_desktop_wxga {
        padding: 22px 0 22px 16px;
        bottom: -110px;
    }

    @include media_desktop_full {
        padding: 24px 0 24px 18px;
        bottom: -120px;
    }
}

.ActiveDropDownIcon {
    transition: 0.4s;
    transform: rotate(180deg);
}

.OptionItem {
    cursor: pointer;
    color: var(--color-gray-title);

    &:first-child {
        margin-top: 0;
    }

    &:hover {
        color: var(--color-tomato);
    }

    @include media_desktop_qhd {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        margin-top: 12px;
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        margin-top: 14px;
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        margin-top: 16px;
        font-size: 18px;
        line-height: 22px;
    }
}

.ActiveOptionItem {
    color: var(--color-tomato);
    cursor: not-allowed;
}