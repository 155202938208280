@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.ShareContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media_desktop_qhd {
        margin-top: 25px;
    }

    @include media_desktop_hd {
        margin-top: 30px;
    }

    @include media_desktop_wxga {
        margin-top: 35px;
    }

    @include media_desktop_full {
        margin-top: 40px;
    }
}

.ShareTextContainer {
    display: flex;
    align-items: center;
}

.ShareImage {
    @include media_desktop_qhd {
        width: 34px;
        height: 34px;
        margin-right: 26px;
    }

    @include media_desktop_hd {
        width: 36px;
        height: 36px;
        margin-right: 28px;
    }

    @include media_desktop_wxga {
        width: 38px;
        height: 38px;
        margin-right: 30px;
    }

    @include media_desktop_full {
        width: 40px;
        height: 40px;
        margin-right: 32px;
    }
}

.TextContainer {
    display: block;
    text-align: left;

    span {
        display: block;

        &:first-child {
            font-family: var(--font-medium);
            color: var(--color-dark-text);

            @include media_desktop_qhd {
                font-size: 14px;
                line-height: 18px;
            }

            @include media_desktop_hd {
                font-size: 16px;
                line-height: 20px;
            }

            @include media_desktop_wxga {
                font-size: 18px;
                line-height: 22px;
            }

            @include media_desktop_full {
                font-size: 20px;
                line-height: 24px;
            }
        }

        &:last-child {
            font-family: var(--font-regular);
            color: var(--color-silver);
            display: block;
            margin-top: px-to-vh(8px);

            @include media_desktop_qhd {
                font-size: 12px;
                line-height: 16px;
            }

            @include media_desktop_hd {
                font-size: 14px;
                line-height: 18px;
            }

            @include media_desktop_wxga {
                font-size: 16px;
                line-height: 20px;
            }

            @include media_desktop_full {
                font-size: 18px;
                line-height: 22px;
            }
        }

    }
}

.PublicInputField {
    box-sizing: border-box;
    border-right: none;
    border-radius: 6px 0 0 6px;
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.CopyPublicLinkContainer {
    border: 2px solid var(--color-tomato);
    border-radius: 0 6px 6px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;

    @include media_desktop_qhd {
        padding: 5px 20px 5px 18px;
    }

    @include media_desktop_hd {
        padding: 7px 24px 7px 20px;
    }

    @include media_desktop_wxga {
        padding: 9px 28px 9px 22px;
    }

    @include media_desktop_full {
        padding: 11px 32px 11px 24px;
    }
}

.InputContainer {
    display: flex;
    align-items: center;
    height: min-content;

    @include media_desktop_qhd {
        margin-top: 10px;
    }

    @include media_desktop_hd {
        margin-top: 12px;
    }

    @include media_desktop_wxga {
        margin-top: 14px;
    }

    @include media_desktop_full {
        margin-top: 16px;
    }
}

.CopyImage {
    @include media_desktop_qhd {
        height: 26px;
        width: 26px;
    }

    @include media_desktop_hd {
        height: 28px;
        width: 28px;
    }

    @include media_desktop_wxga {
        height: 30px;
        width: 30px;
    }

    @include media_desktop_full {
        height: 32px;
        width: 32px;
    }
}

.WrapperForHint {
    font-family: var(--font-medium);
    font-size: 14px;
    position: absolute;
    right: -50px;
    width: 80px;
    padding: 10px 10px;
    border-radius: 6px;
    color: white;
    background-color: var(--color-dark-text);
    opacity: 0.9;
}


