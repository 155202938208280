@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.MultipleChoiceContainer {
  text-align: left;
}

.CorrectText {
  width: 100%;
  display: block;
  text-align: right;
  box-sizing: border-box;
  color: var(--color-silver);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
    padding-right: 32px;
    margin-bottom: 6px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    padding-right: 32px;
    margin-bottom: 8px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    padding-right: 32px;
    margin-bottom: 10px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    padding-right: 32px;
    margin-bottom: 12px;
  }
}

.WrapperImg {
  position: relative;
  width: 85.3%;

  &:hover {
    .WrapperImgAdd {
      visibility: visible;
    }
  }

  @include media_desktop_qhd {
    margin-bottom: 10px;
  }

  @include media_desktop_hd {
    margin-bottom: 12px;
  }

  @include media_desktop_wxga {
    margin-bottom: 14px;
  }

  @include media_desktop_full {
    margin-bottom: 16px;
  }
}

.WrapperTitleQuestionInput {
  padding: 4px;
  border: 1px solid var(--color-gray-border);
  box-sizing: border-box;
  border-radius: 6px;
}

.TitleQuestionInput {
  box-sizing: border-box;
  min-height: 28px !important;
  max-height: 50px;
  overflow-y: auto;
  font-family: var(--font-medium);
  width: 100%;
  outline: 0;
  border: none;

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 50px 10px 10px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 46px 8px 8px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 46px 10px 10px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    padding: 12px 46px 12px 12px;
  }
}

.newImage {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  position: absolute;
  cursor: pointer;

  @include media_desktop_hd {
    top: 8px;
    right: 10px;
  }

  @include media_desktop_wxga {
    top: 10px;
    right: 12px;
  }

  @include media_desktop_full {
    top: 12px;
    right: 13px;
  }
}

.DeleteImg {
  border-radius: 50%;
  background: var(--color-tomato);
  padding: 4px;
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 99;
  cursor: pointer;

  @include media_desktop_hd {
    top: 3px;
    right: 5px;
  }

  @include media_desktop_wxga {
    top: 4px;
    right: 7px;
  }

  @include media_desktop_full {
    top: 7px;
    right: 7px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.WrapperImgAdd {
  position: absolute;
  cursor: pointer;
  visibility: hidden;

  @include media_desktop_hd {
    top: 16px;
    right: 15px;
  }

  @include media_desktop_wxga {
    top: 18px;
    right: 20px;
  }

  @include media_desktop_full {
    top: 21px;
    right: 17px;
  }

  svg {
    fill: var(--color-silver);

    &:hover {
      fill: var(--color-tomato);
    }
  }
}

.ActionIcon {
  cursor: pointer;
  svg {
    fill: var(--color-silver);

    &:hover {
      fill: var(--color-tomato);
      transform: scale(1.1);
    }

    @include media_desktop_qhd {
      height: 18px;
      width: 18px;
    }

    @include media_desktop_hd {
      height: 20px;
      width: 20px;
    }

    @include media_desktop_wxga {
      height: 22px;
      width: 22px;
    }

    @include media_desktop_full {
      height: 24px;
      width: 24px;
    }
  }
}

.DragDropImage {

  @include media_desktop_qhd {
    margin-right: 18px;
  }

  @include media_desktop_hd {
    margin-right: 20px;
  }

  @include media_desktop_wxga {
    margin-right: 22px;
  }

  @include media_desktop_full {
    margin-right: 24px;
  }

  svg {
    fill: var(--color-gray);

  }
}

.AddVariantButton {
  display: flex;
  align-items: center;
  color: var(--color-tomato);
  font-family: var(--font-medium);
  padding: px-to-vh(8px);

  @include media_desktop_qhd {
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin-top: 14px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin-top: 16px;
  }
}

.AddVariantText {
  display: block;

  @include media_desktop_qhd {
    margin-left: 10px;
  }

  @include media_desktop_hd {
    margin-left: 12px;
  }

  @include media_desktop_wxga {
    margin-left: 14px;
  }

  @include media_desktop_full {
    margin-left: 16px;
  }
}