@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.Text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 140px;

  @include media_desktop_qhd {
    width: 140px;
  }

  @include media_desktop_hd {
    width: 160px;
  }

  @include media_desktop_wxga {
    width: 180px;
  }

  @include media_desktop_full {
    width: 190px;
  }
}

.DropDownIcon {
  transition: 0.4s;
}

.isActiveDropDownIcon {
  transition: 0.4s;
  transform: rotate(180deg);
}

.WrapperForLoader {
  text-align: center;
}