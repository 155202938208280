@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.Container {
    display: flex;
    align-items: center;
}

.TextContainer {
    display: block;
    user-select: none;
    width: 580px;

    @include media_desktop_qhd {
        width: 580px;
    }

    @include media_desktop_hd {
        width: 680px;
    }

    @include media_desktop_wxga {
        width: 700px;
    }

    @include media_desktop_full {
        width: 816px;
    }
}

.TitleContainer {
    display: flex;
    align-items: center;

    span {
        font-size: 30px;
        line-height: 40px;
        color: var(--color-white);
        font-family: var(--font-medium);
        display: block;
        margin-right: px-to-vh(18px);

        @include media_desktop_qhd {
            font-size: 30px;
            line-height: 40px;
        }

        @include media_desktop_hd {
            font-size: 36px;
            line-height: 46px;
        }

        @include media_desktop_wxga {
            font-size: 42px;
            line-height: 52px;
        }

        @include media_desktop_full {
            font-size: 48px;
            line-height: 58px;
        }
    }
}

.DescriptionTitleText {
    font-family: var(--font-medium);
    color: var(--color-white);
    display: block;
    margin-top: px-to-vh(32px);
    font-size: 16px;
    line-height: 22px;

    @include media_desktop_qhd {
        font-size: 16px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 18px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 24px;
        line-height: 30px;
    }

    @include media_desktop_full {
        font-size: 28px;
        line-height: 34px;
    }
}

.InformationContainer {
    display: flex;
    margin-top: px-to-vh(43px);
}

.DignityContainer {
    display: block;
}

.DignityItem {
    display: block;
    text-align: center;
}

.DignityImage {
    height: 18px;
    width: 18px;

    @include media_desktop_qhd {
        height: 18px;
        width: 18px;
    }

    @include media_desktop_hd {
        height: 20px;
        width: 20px;
    }

    @include media_desktop_wxga {
        height: 22px;
        width: 22px;
    }

    @include media_desktop_full {
        height: 24px;
        width: 24px;
    }
}

.ReviewsContainer {
    display: block;
    text-align: center;
    width: 300px;
    margin-left: 74px;

    @include media_desktop_qhd {
        width: 300px;
        margin-left: 74px;
    }

    @include media_desktop_hd {
        width: 355px;
        margin-left: 87px;
    }

    @include media_desktop_wxga {
        width: 400px;
        margin-left: 100px;
    }

    @include media_desktop_full {
        width: 438px;
        margin-left: 113px;
    }
}

.SavedHoursContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--color-sakura);
    border-radius: 6px 81px 81px 6px;
    position: relative;
    height: 100px;

    @include media_desktop_qhd {
        height: 100px;
        width: 325px;
    }

    @include media_desktop_hd {
        height: 120px;
        width: 365px;
    }

    @include media_desktop_wxga {
        height: 140px;
        width: 405px;
    }

    @include media_desktop_full {
        height: 160px;
        width: 445px;
    }
}

.SavedHoursTitle {
    display: block;
    font-family: var(--font-medium);
    color: var(--color-white);

    @include media_desktop_qhd {
        font-size: 22px;
        line-height: 26px;
        max-width: 170px;
        margin-left: 10px;
    }

    @include media_desktop_hd {
        font-size: 24px;
        line-height: 30px;
        max-width: 180px;
        margin-left: 20px;
    }

    @include media_desktop_wxga {
        font-size: 28px;
        line-height: 34px;
        max-width: 190px;
        margin-left: 30px;
    }

    @include media_desktop_full {
        font-size: 32px;
        line-height: 38px;
        max-width: 200px;
        margin-left: 40px;
    }
}

.Round {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-white);
    border-radius: 50%;
    position: absolute;
    top: -2px;
    bottom: -2px;
    right: -2px;

    span {
        color: var(--color-black);

        &:first-child {
            font-family: var(--font-bold);

            @include media_desktop_qhd {
                font-size: 24px;
                line-height: 32px;
            }

            @include media_desktop_hd {
                font-size: 28px;
                line-height: 36px;
            }

            @include media_desktop_wxga {
                font-size: 32px;
                line-height: 40px;
            }

            @include media_desktop_full {
                font-size: 36px;
                line-height: 44px;
            }
        }

        &:last-child {
            display: block;
            margin: 0 auto;
            font-family: var(--font-medium);

            @include media_desktop_qhd {
                max-width: 60px;
                font-size: 15px;
                line-height: 10px;
            }

            @include media_desktop_hd {
                max-width: 70px;
                font-size: 18px;
                line-height: 23px;
            }

            @include media_desktop_wxga {
                max-width: 80px;
                font-size: 21px;
                line-height: 26px;
            }

            @include media_desktop_full {
                max-width: 90px;
                font-size: 24px;
                line-height: 29px;
            }
        }
    }

    @include media_desktop_qhd {
        width: 100px;
        height: 104px;
    }

    @include media_desktop_hd {
        width: 120px;
        height: 124px;
    }

    @include media_desktop_wxga {
        width: 140px;
        height: 144px;
    }

    @include media_desktop_full {
        width: 160px;
        height: 164px;
    }
}

.ReviewTextContainer {
    margin-top: px-to-vh(66px);
    text-align: center;
}

.ReviewText {
    display: block;
    font-family: var(--font-regular);
    color: var(--color-white);

    @include media_desktop_qhd {
        font-size: 10px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 12px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 14px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 16px;
        line-height: 22px;
    }
}

.ReviewerPhoto {
    display: block;
    margin: px-to-vh(37px) auto 0 auto;

    @include media_desktop_qhd {
        height: 34px;
        width: 34px;
    }

    @include media_desktop_hd {
        height: 44px;
        width: 44px;
    }

    @include media_desktop_wxga {
        height: 54px;
        width: 54px;
    }

    @include media_desktop_full {
        height: 64px;
        width: 64px;
    }
}

.ReviewerName {
    display: block;
    font-family: var(--font-medium);
    margin-top: px-to-vh(8px);
    color: var(--color-white);

    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
    }
}

.ReviewerPositionText {
    display: block;
    font-family: var(--font-medium);
    margin-top: px-to-vh(8px);
    color: var(--color-white);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}