@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.ReportsContainer {
    display: block;
    padding: 26px 60px 40px 40px;

    @include media_desktop_qhd {
        padding: 26px 60px 20px 40px;
    }

    @include media_desktop_hd {
        padding: 36px 70px 30px 40px;
    }

    @include media_desktop_wxga {
        padding: 46px 80px 40px 40px;
    }

    @include media_desktop_full {
        padding: 56px 90px 40px 40px;
    }
}

.WrapperFilters {
    display: flex;
    align-items: end;
}

.MainFilterContainer {
    position: relative;
    display: inline-block;

    @include media_desktop_qhd {
        margin-right: 20px;
    }

    @include media_desktop_hd {
        margin-right: 24px;
    }

    @include media_desktop_wxga {
        margin-right: 28px;
    }

    @include media_desktop_full {
        margin-right: 32px;
    }
}

.DropDownContainer {
    box-sizing: border-box;
    align-items: center;
    display: flex;
    font-size: 12px;
    line-height: 16px;
    justify-content: space-between;
    margin-top: 8px;
    border: 1px solid var(--color-gray-border);
    border-radius: 6px;
    padding: 7px 14px 7px 13px;
    cursor: pointer;
    user-select: none;
    background: var(--color-white);
    font-family: var(--font-medium);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 138px;

    @include media_desktop_qhd {
        width: 138px;
        font-size: 12px;
        line-height: 16px;
        padding: 7px 14px 7px 13px;
    }

    @include media_desktop_hd {
        width: 128px;
        font-size: 14px;
        line-height: 18px;
        padding: 8px 15px 8px 14px;
    }

    @include media_desktop_wxga {
        width: 158px;
        font-size: 18px;
        line-height: 22px;
        padding: 9px 16px 9px 15px;
    }

    @include media_desktop_full {
        width: 208px;
        font-size: 20px;
        line-height: 24px;
        padding: 10px 17px 10px 16px;
    }
}

.DropDownContainerCourse {
    width: 146px;

    @include media_desktop_qhd {
        width: 146px;
    }

    @include media_desktop_hd {
        width: 145px;
    }

    @include media_desktop_wxga {
        width: 168px;
    }

    @include media_desktop_full {
        width: 246px;
    }
}

.DropDownMenuContainer {
    border: 1px solid var(--color-light-grey);
    background: white;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    padding: 18px 4px 18px 14px;
    left: 0;
    top: 115%;
    width: 87%;
    height: max-content;

    @include media_desktop_qhd {
        padding: 18px 4px 18px 14px;
        width: 87%;
    }

    @include media_desktop_hd {
        padding: 16px 6px 16px 16px;
        width: 84%;
    }

    @include media_desktop_wxga {
        padding: 20px 8px 20px 18px;
        width: 84%;
    }

    @include media_desktop_full {
        padding: 24px 10px 24px 20px;
        width: 87%;
    }
}

.DropDownMenuContainerLearner {
    top: 130%;
}

.DropDownMenuContainerModuleType {
    width: 86%;

    @include media_desktop_qhd {
        width: 86%;
    }

    @include media_desktop_hd {
        width: 82%;
    }

    @include media_desktop_wxga {
        width: 83%;
    }

    @include media_desktop_full {
        width: 85%;
    }
}

.DropDownMenuContainerStatus {
    border-radius: 6px;
    padding: 18px 14px 18px 14px;
    width: 100%;
    display: block;
    position: absolute;

    @include media_desktop_qhd {
        padding: 18px 14px 18px 14px;
    }

    @include media_desktop_hd {
        padding: 20px 16px 20px 16px;
    }

    @include media_desktop_wxga {
        padding: 22px 18px 22px 18px;
    }

    @include media_desktop_full {
        padding: 24px 20px 24px 20px;
    }
}

.DropDownOverflow {
    overflow-y: auto;

    @include media_desktop_hd {
        max-height: 100px;
    }

    @include media_desktop_wxga {
        max-height: 120px;
    }

    @include media_desktop_full {
        max-height: 140px;
    }
}

.ResetFilters {
    font-family: var(--font-medium);
    box-sizing: border-box;
    background: var(--color-tomato);
    color: var(--color-white);
    border-radius: 6px;

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 20px;
        height: 35px;
        padding: 6px 24px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        height: 42px;
        padding: 8px 28px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        height: 46px;
        padding: 10px 32px;
    }

    &:hover {
        background: var(--color-hover-btn);
    }
}

.ListPagination {
    margin: 8px 13px;
    font-family: var(--font-medium);
    color: var(--color-gray-title);
    line-height: 22px;
    font-size: 12px;
    cursor: pointer;

    @include media_desktop_qhd {
        line-height: 16px;
        font-size: 12px;
    }

    @include media_desktop_hd {
        line-height: 18px;
        font-size: 14px;
    }

    @include media_desktop_wxga {
        line-height: 20px;
        font-size: 16px;
    }

    @include media_desktop_full {
        line-height: 22px;
        font-size: 18px;
    }
}

.ContainerPaginate {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: flex-end;
}

.ActiveBtnPaginate {
    background: var(--color-tomato);
    color: var(--color-white);
    border-radius: 5px;
    padding: 8px 14px;
}

.Previous,
.Next {
    color: var(--color-tomato);
    font-family: var(--font-medium);
    font-size: 20px;
    cursor: pointer;

    @include media_desktop_qhd {
        font-size: 20px;
    }

    @include media_desktop_hd {
        font-size: 22px;
    }

    @include media_desktop_wxga {
        font-size: 24px;
    }

    @include media_desktop_full {
        font-size: 26px;
    }
}

.Previous {
    margin: 0 18px 4px 0;
}

.Next {
    margin: 0 0 4px 18px;
}

.ReportsNone {
    position: absolute;
    top: 28%;
    left: 44%;
    text-align: center;
    font-family: var(--font-medium);
    font-size: 22px;
    line-height: 28px;

    @include media_desktop_qhd {
        top: 28%;
        left: 44%;
        font-size: 22px;
        line-height: 28px;
    }

    @include media_desktop_hd {
        top: 35%;
        left: 45%;
        font-size: 24px;
        line-height: 30px;
    }

    @include media_desktop_wxga {
        top: 35%;
        left: 45%;
        font-size: 26px;
        line-height: 32px;
    }

    @include media_desktop_full {
        top: 40%;
        left: 45%;
        font-size: 28px;
        line-height: 34px;
    }
}

.Subtitle {
    color: var(--color-silver);
    font-family: var(--font-medium);
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
    width: 350px;

    @include media_desktop_qhd {
        width: 350px;
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        width: 385px;
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        width: 385px;
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        width: 485px;
        font-size: 24px;
        line-height: 28px;
    }
}

.PaginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    @include media_desktop_qhd {
        margin-top: 20px;
    }

    @include media_desktop_qhd {
        margin-top: 24px;
    }

    @include media_desktop_wxga {
        margin-top: 28px;
    }

    @include media_desktop_full {
        margin-top: 34px;
    }
}

.TextNumberLearner {
    font-family: var(--font-regular);
    font-size: 9px;
    line-height: 17px;
    color: var(--color-gray-title);

    @include media_desktop_qhd {
        font-size: 9px;
        line-height: 17px;
    }

    @include media_desktop_hd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_wxga {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.ResultNoFound {
    display: flex;
    font-size: 20px;
    justify-content: center;
    margin-top: 150px;
    font-family: var(--font-medium);
}

.WrapperTittleNone {
    display: flex;
    justify-content: center;
    align-items: center;
}

.TitleNone {
    margin-right: 10px;
}