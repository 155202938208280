@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.ImageAdd {
  @include media_desktop_hd {
    width: 15px;
    height: 16px;
    margin-right: 11px;
  }

  @include media_desktop_wxga {
    width: 18px;
    height: 22px;
    margin-right: 13px;
  }

  @include media_desktop_full {
    width: 20px;
    height: 28px;
    margin-right: 15px;
  }

  svg {
    fill: var(--color-tomato);
    @include media_desktop_hd {
      width: 15px;
      height: 16px;
    }

    @include media_desktop_wxga {
      width: 18px;
      height: 22px;
    }

    @include media_desktop_full {
      width: 20px;
      height: 28px;
    }
  }
}

.ButtonAddMember {
  text-decoration: none;
  border: 1px solid var(--color-tomato);
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  font-family: var(--font-medium);
  color: var(--color-tomato);

  @include media_desktop_hd {
    padding: 4px 20px;
    font-size: 14px;
    margin-top: 26px;
  }

  @include media_desktop_wxga {
    padding: 6px 28px;
    font-size: 18px;
    margin-top: 30px;
  }

  @include media_desktop_full {
    padding: 8px 32px;
    font-size: 20px;
    margin-top: 32px;
  }

  &:hover {
    color: var(--color-hover-btn);
    border: 1px solid var(--color-hover-btn);
    div {
      color: var(--color-hover-btn);
    }
    svg {
      fill:var(--color-hover-btn);
    }
  }
}
