@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.ReportsListContainer {
    font-family: var(--font-regular);
}

.ReportsItem {
    display: grid;
    cursor: pointer;
    align-items: center;
    grid-template-columns: 18% 19% 20% 20% 15% 8%;
    background: var(--color-white);
    border-radius: 6px;
    font-family: var(--font-medium);
    padding: 0 16px 0 16px;

    span {
        padding: 12px 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @include media_desktop_qhd {
            padding: 12px 5px;
        }

        @include media_desktop_hd {
            padding: 12px 5px;
        }

        @include media_desktop_wxga {
            padding: 14px 5px;
        }

        @include media_desktop_full {
            padding: 16px 5px;
        }
    }

    @include media_desktop_qhd {
        margin-bottom: 9px;
    }

    @include media_desktop_hd {
        margin-bottom: 10px;
    }

    @include media_desktop_wxga {
        margin-bottom: 11px;
    }

    @include media_desktop_full {
        margin-bottom: 12px;
    }
}

.ReportsItemOnboardee {
    font-family: var(--font-medium);
    font-size: 12px;
    line-height: 16px;
    width: 112px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
        width: 112px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        width: 120px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        width: 180px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        width: 200px;
    }
}

.StatusContainer {
    display: flex;
    font-family: var(--font-bold);
}

.ResultContainer {
    color: var(--color-white);
    display: flex;

    span {
        font-family: var(--font-medium);
        font-size: 12px;
        line-height: 16px;

        @include media_desktop_qhd {
            font-size: 12px;
            line-height: 16px;
        }

        @include media_desktop_hd {
            font-size: 14px;
            line-height: 18px;
        }

        @include media_desktop_wxga {
            font-size: 18px;
            line-height: 22px;
        }

        @include media_desktop_full {
            font-size: 20px;
            line-height: 24px;
        }
    }

    @include media_desktop_qhd {
        width: 100px;
    }

    @include media_desktop_hd {
        width: 160px;
    }

    @include media_desktop_wxga {
        width: 190px;
    }

    @include media_desktop_full {
        width: 210px;
    }
}

.ReportsItemPassed {
    color: var(--color-green);
}

.ReportsItemFailed {
    color: var(--color-red);
}

.ReportsItemQuizReport {
    color: var(--color-sun);
}

.ReportsTask {
    color: var(--color-blue);
}

.Image {
    width: 14px;

    @include media_desktop_qhd {
        width: 14px;
    }

    @include media_desktop_hd {
        width: 15px;
        margin-right: 6px;
    }

    @include media_desktop_wxga {
        width: 17px;
        margin-right: 10px;
    }

    @include media_desktop_full {
        width: 18px;
        margin-right: 10px;
    }
}


.DateText {
    color: var(--color-silver);
}

.CourseText,
.ModuleText,
.StatusText,
.DateText {
    font-family: var(--font-medium) ;
    font-size: 12px;
    line-height: 16px;
    width: 100px;

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
        width: 100px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        width: 160px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        width: 180px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        width: 200px;
    }
}

.StatusText,
.DateText {
    width: 90px;

    @include media_desktop_qhd {
        width: 90px;
    }

    @include media_desktop_hd {
        width: 105px;
    }

    @include media_desktop_wxga {
        width: 130px;
    }

    @include media_desktop_full {
        width: 150px;
    }
}

.Tooltip {
    font-family: var(--font-medium) !important;
    font-size: 14px !important;
    line-height: 18px !important;
    background: var(--color-dark-text) !important;
    border-radius: 6px !important;
}
