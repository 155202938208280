@import '../../../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  overflow-x: auto;
  overflow-y: hidden;
}

.SubContainer {
  padding-bottom: 10px;
}

.WrapperLeanerMetrics {
  display: flex;
  align-items: center;
}

.TitleLearner {
  font-family: var(--font-regular);
  color: var(--color-silver);
  text-align: left;

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    margin: 22px 0;
    min-width: 160px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin: 24px 0;
    min-width: 170px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin: 26px 0;
    min-width: 180px;
  }
}

.Header {
  display: flex;
  text-align: left;
  font-family: var(--font-regular);
  align-items: center;
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    margin: 22px 0;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin: 24px 0;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin: 26px 0;
  }
  span {
    &:not(:last-child) {
      margin-right: 16px;
    }

    @include media_desktop_hd {
      width: 102px;
    }

    @include media_desktop_wxga {
      width: 112px;
    }

    @include media_desktop_full {
      width: 132px;
    }
  }
}