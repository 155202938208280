@import '../../../../../../assets/scss/helpers';
@import '../../../../../../assets/scss/medias';

.TabButton {
    font-family: var(--font-medium);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    display: flex;
    text-align: left;
    align-items: center;
    max-width: 100%;
    outline: none;
    padding: 14px 0 14px 36px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition: 0.4s;

    &:hover {
        background-color: var(--color-light);
    }

    @include media_desktop_qhd{
        font-size: 12px;
        line-height: 18px;
        padding: 12px 0 12px 36px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        padding: 14px 0 14px 41px;
    }

    @include media_desktop_wxga {
        font-size: 20px;
        line-height: 24px;
        padding: 16px 0 16px 56px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        padding: 20px 0 20px 56px;
    }
}

.SelectTabButton {
    background-color: var(--color-light-background);
    color: var(--color-tomato);
    border-left: 5px solid var(--color-tomato);
    transition: 0.4s;

    @include media_desktop_qhd {
        padding: 12px 0 12px 30px;
    }

    @include media_desktop_hd {
        padding: 14px 0 14px 35px;
    }

    @include media_desktop_wxga {
        padding: 16px 0 16px 51px;
    }

    @include media_desktop_full {
        padding: 20px 0 20px 51px;
    }
}

.TabIcon {
    display: block;
    margin-right: 15px;
    width: 100%;
    height: 100%;
    max-height: 18px;
    max-width: 18px;

    @include media_desktop_qhd {
        max-height: 18px;
        max-width: 18px;
    }

    @include media_desktop_hd {
        max-height: 20px;
        max-width: 20px;
    }

    @include media_desktop_wxga {
        max-height: 22px;
        max-width: 22px;
    }

    @include media_desktop_full {
        max-height: 24px;
        max-width: 24px;
    }
}

.WrapperPro {
    border-radius: 6px;
    background: var(--color-gray-border);
    padding: 2px 8px;
    color: var(--color-dark-text);

    @include media_desktop_hd {
        font-size: 14px;
        margin-left: 85px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        margin-left: 110px;
    }

    @include media_desktop_full {
        font-size: 18px;
        margin-left: 120px;
    }
}

.Tooltip {
    pointer-events: auto !important;

    span {
        color: yellow !important;
        text-decoration: underline !important;
    }

    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
    }
}

.Upgrade {
    color: yellow !important;
}