@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.TitleCourses {
  font-family: var(--font-medium);
  font-size: 16px;
  line-height: 20px;
  margin: 24px 0 24px 0;

    @include media_desktop_qhd {
      font-size: 18px;
      line-height: 22px;
      margin: 26px 0 26px 0;
    }

    @include media_desktop_hd {
      font-size: 20px;
      line-height: 24px;
      margin: 28px 0 28px 0;
    }

    @include media_desktop_wxga {
      font-size: 22px;
      line-height: 26px;
      margin: 30px 0 30px 0;
    }

    @include media_desktop_full {
      font-size: 24px;
      line-height: 28px;
      margin: 32px 0 32px 0;
    }
}

.FilterWrapper {
  display: flex;
  align-items: end;
  margin-bottom: 20px;

    @include media_desktop_qhd {
      margin-bottom: 20px;
    }

    @include media_desktop_hd {
      margin-bottom: 25px;
    }

    @include media_desktop_wxga {
      margin-bottom: 30px;
    }

    @include media_desktop_full {
      margin-bottom: 40px;
    }
}

.FilterComponentWrapper {
  position: relative;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 24px;

    @include media_desktop_qhd {
      margin-right: 26px;
    }

    @include media_desktop_hd {
      margin-right: 28px;
    }

    @include media_desktop_wxga {
      margin-right: 30px;
    }

    @include media_desktop_full {
      margin-right: 32px;
    }
  }
}

.DropDownContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  padding: 7px 14px 7px 13px;
  cursor: pointer;
  user-select: none;
  background: var(--color-white);
  font-family: var(--font-medium);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 138px;
  font-size: 12px;
  line-height: 16px;

  @include media_desktop_qhd {
    width: 138px;
    font-size: 12px;
    line-height: 16px;
    padding: 7px 14px 7px 13px;
  }

  @include media_desktop_hd {
    width: 158px;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 15px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 206px;
    font-size: 18px;
    line-height: 22px;
    padding: 9px 16px 9px 15px;
  }

  @include media_desktop_full {
    width: 246px;
    font-size: 20px;
    line-height: 24px;
    padding: 13px 17px 13px 16px;
  }
}

.isOpenDropDown {
  border: 1px solid var(--color-tomato);
}

.DropDownContainerModule {
  width: 140px;

    @include media_desktop_qhd {
      width: 140px;
    }

    @include media_desktop_hd {
      width: 168px;
    }

    @include media_desktop_wxga {
      width: 188px;
    }

    @include media_desktop_full {
      width: 208px;
    }
}

.DropDownContainerPeriod {
  width: 140px;

  @include media_desktop_qhd {
    width: 140px;
  }

  @include media_desktop_hd {
    width: 168px;
  }

  @include media_desktop_wxga {
    width: 188px;
  }

  @include media_desktop_full {
    width: 208px;
  }
}

.TitleFilter {
  font-family: var(--font-regular);
  color: var(--color-gray-title);
  font-size: 12px;
  line-height: 14px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 14px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 17px;
  }

  @include media_desktop_wxga {
    font-size: 17px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.DropDownMenuContainer {
  border: 1px solid var(--color-light-grey);
  background: white;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 18px 7px 12px 14px;
  left: 0;
  top: 115%;
  width: 83%;
  height: max-content;
  font-size: 12px;
  line-height: 16px;

  @include media_desktop_qhd {
    padding: 18px 7px 12px 14px;
    width: 83%;
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    padding: 20px 8px 14px 16px;
    width: 84%;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    padding: 22px 9px 16px 18px;
    width: 86%;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    padding: 24px 10px 18px 20px;
    width: 87%;
    font-size: 20px;
    line-height: 24px;
  }
}

.DropDownMenuContainerPeriodModule {
  width: 84%;

  @include media_desktop_qhd {
    width: 84%;
  }

  @include media_desktop_hd {
    width: 85%;
  }

  @include media_desktop_wxga {
    width: 85%;
  }

  @include media_desktop_full {
    width: 85%;
  }
}

.DropDownMenuContainerLearner {
  width: 85%;

  @include media_desktop_hd {
    width: 85%;
  }

  @include media_desktop_wxga {
    width: 86%;
  }

  @include media_desktop_full {
    width: 87%;
  }
}

.DropDownOverflow {
  max-height: 120px;
  overflow-y: auto;
}

.OptionsWrapper {
  &:not(:last-child) {
    margin-bottom: 10px;

    @include media_desktop_qhd {
      margin-bottom: 10px;
    }

    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
    }
  }
}

.NoData {
  margin-left: 270px;
  font-family: var(--font-medium);
  font-size: 20px;
  margin-top: 100px;

  @include media_desktop_qhd {
    margin-left: 270px;
  }

  @include media_desktop_hd {
    margin-left: 300px;
  }

  @include media_desktop_wxga {
    margin-left: 390px;
  }

  @include media_desktop_full {
    margin-left: 470px;
  }
}

.ResetFilters {
  font-family: var(--font-medium);
  box-sizing: border-box;
  background: var(--color-tomato);
  color: var(--color-white);
  border-radius: 6px;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 20px;
    height: 35px;
    padding: 6px 24px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    height: 42px;
    padding: 8px 28px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    height: 52px;
    padding: 10px 32px;
  }

  &:hover {
    background: var(--color-hover-btn);
  }
}

