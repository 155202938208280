@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.MainContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    transform: translate(-50%, -50%);
    background-color: var(--color-light-background);
    z-index: 99;
    text-align: center;
}

.LogoContainer {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}

.Logo {
    margin: 0 auto;
    width: 12%;
    height: 12%;

    @include media_tablet {
        width: 7%;
        height: 7%;
    }

    @include media_desktop_qhd {
        width: 5%;
        height: 5%;
    }

    @include media_desktop_hd {
        width: 5%;
        height: 5%;
    }

    @include media_desktop_wxga {
        width: 5%;
        height: 5%;
    }

    @include media_desktop_full {
        width: 5%;
        height: 5%;
    }
}