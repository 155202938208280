@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.MainModalContainer {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-border);
    border-radius: 6px;
    position: absolute;
    font-family: var(--font-medium);
    top: 0;

    @include media_desktop_hd {
        padding: 20px;
        right: -370px;
        width: 290px;
    }

    @include media_desktop_wxga {
        padding: 22px;
        right: -420px;
        width: 310px;
    }

    @include media_desktop_full {
        padding: 24px;
        right: -520px;
        width: 360px;
    }
}

.ContentContainer {
    @include media_desktop_qhd {
        padding-right: 18px;
    }

    @include media_desktop_hd {
        padding-right: 20px;
    }

    @include media_desktop_wxga {
        padding-right: 22px;
    }

    @include media_desktop_full {
        padding-right: 24px;
    }
}

.MoreContainer {
    @include media_desktop_qhd {
        padding-left: 18px;
    }

    @include media_desktop_hd {
        padding-left: 20px;
    }

    @include media_desktop_wxga {
        padding-left: 22px;
    }

    @include media_desktop_full {
        padding-left: 24px;
    }
}

.Title {
    color: var(--color-grey);

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.Line {
    display: block;
    width: 1px;
    height: 100%;
    background: var(--color-gray-border);
}

.ContentBlockList {
    display: block;

    @include media_desktop_hd {
        margin-top: 20px;
    }

    @include media_desktop_wxga {
        margin-top: 22px;
    }

    @include media_desktop_full {
        margin-top: 24px;
    }
}