@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.CloseContainer {
  width: 100%;
  display: block;
  text-align: right;
}

.MainPopupContainer {
  text-align: left;

  @include media_desktop_hd {
    width: 580px;
    padding: 12px;
  }

  @include media_desktop_wxga {
    width: 640px;
    padding: 14px;
  }

  @include media_desktop_full {
    width: 723px;
    padding: 16px;
  }
}

.MainContainer {
  @include media_desktop_hd {
    padding: 0 px-to-vh(30px) px-to-vh(25px);
  }

  @include media_desktop_wxga {
    padding: 0 px-to-vh(35px) px-to-vh(35px);
  }

  @include media_desktop_full {
    padding: 0 px-to-vh(48px) px-to-vh(40px);
  }
}

.CloseButton {
  cursor: pointer;

  @include media_desktop_hd {
    width: px-to-vh(40px);
    height: px-to-vh(40px);
  }
}

.TitleText {
  font-family: var(--font-medium);
  color: var(--color-gray-title);
  display: block;
  text-align: center;

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 24px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.LearnerName {
  font-family: var(--font-bold);
  word-wrap: break-word;
  color: var(--color-dark-text);
}

.CourseName {
  word-wrap: break-word;
}

.FirstDescriptionText {
  text-align: center;
  color: var(--color-dark-text);
  font-family: var(--font-regular);

  span {
    font-family: var(--font-bold);
    word-wrap: break-word;
  }

  @include media_desktop_hd {
    margin-top: px-to-vh(25px);
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_wxga {
    margin-top: px-to-vh(32px);
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_full {
    margin-top: px-to-vh(40px);
    font-size: 22px;
    line-height: 26px;
  }
}

.SecondDescriptionText {
  display: block;
  text-align: center;
  font-family: var(--font-medium);
  color: var(--color-tomato);

  @include media_desktop_hd {
    margin-top: px-to-vh(30px);
    font-size: 20px;
    line-height: 22px;
  }

  @include media_desktop_wxga {
    margin-top: px-to-vh(35px);
    font-size: 22px;
    line-height: 26px;
  }

  @include media_desktop_full {
    margin-top: px-to-vh(40px);
    font-size: 24px;
    line-height: 30px;
  }
}

.ButtonContainer {
  display: flex;
  justify-content: center;

  button {
    font-family: var(--font-medium);
    outline: none;

    @include media_desktop_hd {
      font-size: 16px;
      line-height: 16px;
    }

    @include media_desktop_wxga {
      font-size: 18px;
      line-height: 20px;
    }

    @include media_desktop_full {
      font-size: 20px;
      line-height: 24px;
    }
  }

  @include media_desktop_hd {
    margin-top: px-to-vh(40px);
  }

  @include media_desktop_wxga {
    margin-top: px-to-vh(40px);
  }

  @include media_desktop_full {
    margin-top: px-to-vh(40px);
  }
}

.CancelButton {
  border: 1px solid var(--color-gray-border);
  color: var(--color-gray-border);
  margin-right: 40px;
  background-color: var(--color-white);

  &:hover {
    background-color: var(--color-light-background);
  }

}

.ConfirmButton {
  color: var(--color-white);
  background-color: var(--color-tomato);
}
