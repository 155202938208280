@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.PopupContainer {
    @include media_desktop_qhd {
        padding: 18px;
    }

    @include media_desktop_hd {
        padding: 20px;
    }

    @include media_desktop_wxga {
        padding: 22px;
    }

    @include media_desktop_full {
        padding: 24px;
    }
}

.MainPopupContainer {
    display: flex;
    align-items: center;
    padding: px-to-vh(32px) px-to-vh(40px);
}

.TextContainer {
    display: block;
    text-align: left;
    margin-right: px-to-vh(32px);
}

.SliderContainer {
    display: flex;
    align-items: center;

    span {
        font-family: var(--font-medium);

        @include media_desktop_qhd {
            font-size: 14px;
            line-height: 18px;
        }

        @include media_desktop_hd {
            font-size: 16px;
            line-height: 20px;
        }

        @include media_desktop_wxga {
            font-size: 18px;
            line-height: 22px;
        }
        @include media_desktop_full {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.DescriptionContainer {
    font-family: var(--font-regular);
    color: var(--color-silver);
    display: block;
    margin-top: px-to-vh(8px);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.InputContainer {
    display: flex;
    align-items: center;
}

.InputField {
    text-align: center;
    font-family: var(--font-medium);
    box-sizing: border-box;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        width: 40px;
        padding: 6px 9px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        width: 45px;
        padding: 8px 11px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        width: 50px;
        padding: 10px 13px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        width: 55px;
        padding: 12px 15px;
    }

    &::-webkit-input-placeholder {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    &::-moz-placeholder {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    &:-moz-placeholder {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    &:-ms-input-placeholder {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    &:focus::-webkit-input-placeholder {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &:focus::-moz-placeholder {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &:focus:-moz-placeholder {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &:focus:-ms-input-placeholder {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}

.InputLabelText {
    font-family: var(--font-regular);
    color: var(--color-silver);
    display: block;
    margin-left: px-to-vh(8px);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.SaveButton {
    display: block;
    margin: 0 auto;
    color: var(--color-white);
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.DisableInputField {
    background-color: var(--color-light-background);
}