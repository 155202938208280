@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.Container {
  position: relative;
}

.WrapperMain {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.Title {
  font-family: var(--font-regular);
  color: var(--color-gray-title);
  font-size: 15px;
  line-height: 19px;
  margin: 0 24px 0 24px;

  @include media_desktop_qhd {
    font-size: 15px;
    line-height: 19px;
    margin: 0 24px 0 24px;
  }

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    margin: 0 26px 0 26px;
  }

  @include media_desktop_wxga {
    font-size: 17px;
    line-height: 21px;
    margin: 0 28px 0 28px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin: 0 30px 0 30px;
  }
}

.DropDownContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  padding: 7px 14px 7px 13px;
  cursor: pointer;
  user-select: none;
  background: var(--color-white);
  font-family: var(--font-medium);
  width: 140px;

  @include media_desktop_qhd {
    width: 140px;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 14px 7px 13px;
  }

  @include media_desktop_hd {
    width: 158px;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 15px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 178px;
    font-size: 18px;
    line-height: 22px;
    padding: 9px 16px 9px 15px;
  }

  @include media_desktop_full {
    width: 206px;
    font-size: 20px;
    line-height: 24px;
    padding: 10px 17px 10px 16px;
  }
}

.DropDownValue {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.DropDownIcon {
  transition: 0.4s;
}

.isActiveDropDownIcon {
  transition: 0.4s;
  transform: rotate(180deg);
}

.ActiveDropDown {
  border: 1px solid var(--color-tomato);
  color: var(--color-silver);
}

.DropDownMenuContainer {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: white;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 24px 20px 18px 20px;
  left: 160px;
  top: 115%;
  width: 140px;
  height: max-content;

  @include media_desktop_qhd {
    padding: 18px 14px 12px 14px;
    width: 140px;
    left: 160px;
  }

  @include media_desktop_hd {
    padding: 20px 16px 14px 16px;
    width: 158px;
    left: 172px;
  }

  @include media_desktop_wxga {
    padding: 22px 18px 16px 18px;
    width: 178px;
    left: 183px;
  }

  @include media_desktop_full {
    padding: 24px 20px 18px 20px;
    width: 206px;
    left: 194px;
  }
}

.DropDownOverflow {
  max-height: 100px;
  overflow-y: auto;
}

.OptionsText {
  font-family: var(--font-medium);
  font-size: 12px;
  line-height: 16px;
  color: var(--color-dark-text);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.OptionsWrapper {
  &:not(:last-child) {
    margin-bottom: 10px;

    @include media_desktop_qhd {
      margin-bottom: 10px;
    }

    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
    }
  }
}