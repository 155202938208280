@import '../../../../assets/scss/helpers';
@import '../../../../assets/scss/medias';

.MainPopupContainer {
  @include media_desktop_hd {
    width: 480px;
    height: 490px;
  }

  @include media_desktop_wxga {
    width: 500px;
    height: 520px;
  }

  @include media_desktop_full {
    width: 526px;
    height: 541px;
  }
}

.WrapperProcessing {
  background-color: var(--color-light-background);
}

.CloseImg {
  @include media_desktop_hd {
    padding: 18px 18px 0 0;
  }

  @include media_desktop_wxga {
    padding: 28px 28px 0 0;
  }

  @include media_desktop_full {
    padding: 38px 38px 0 0;
  }
}

.TitleProcessing,
.TitleError {
  font-family: var(--font-medium);
  text-align: center;

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 30px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 32px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 34px;
  }
}


.MainContainer {
  background: transparent;
}

.ContainerSuccessful,
.ContainerError {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media_desktop_hd {
    margin-bottom: 40px;
  }

  @include media_desktop_wxga {
    margin-bottom: 45px;
  }

  @include media_desktop_full {
    margin-bottom: 50px;
  }
}

.TitleSuccessful {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 21px;
    line-height: 31px;
  }

  @include media_desktop_wxga {
    font-size: 23px;
    line-height: 33px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 34px;
  }
}

.ImgSuccessful {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--color-green);
  margin-left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImgError {
  background: var(--color-red);
}

.TextSuccessful {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    margin: 18px 0 28px 0;
  }

  @include media_desktop_wxga {
    font-size: 17px;
    line-height: 21px;
    margin: 20px 0 30px 0;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin: 22px 0 32px 0;
  }
}

.TextError {
  font-family: var(--font-medium);
  margin-bottom: 22px;

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 17px;
    line-height: 21px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.TextErrorDetails {
  margin: 0;
  padding: 0 20px;
  overflow-y: auto;
  max-height: 150px;
}

.BtnContinue,
.BtnTryAgain {
  margin: 0 auto;
  padding: 12px 0;

  @include media_desktop_hd {
    width: 356px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    width: 376px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    width: 396px;
    font-size: 20px;
    line-height: 24px;

  }
}

.BtnTryAgain {
  position: fixed;
  bottom: 0;
  left: 13%;
  margin-bottom: 74px;

  @include media_desktop_hd {
    margin-bottom: 44px;
  }

  @include media_desktop_wxga {
    margin-bottom: 54px;
  }

  @include media_desktop_full {
    margin-bottom: 74px;
  }
}

.ErrorImg {
  width: 12px;
  height: 12px;
}

.GifImg {
  width: 330px;
  height: 180px;
}
