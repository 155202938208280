@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.Button {
    position: relative;
    border-radius: 6px;
    line-height: 20px;
    user-select: none;
    outline: none;
    max-width: 100%;
    padding: 12px 32px;
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        padding: 6px 20px;
    }

    @include media_desktop_hd {
        padding: 8px 24px;
    }

    @include media_desktop_wxga {
        padding: 10px 28px;
    }

    @include media_desktop_full {
        padding: 14px 32px;
    }
}

.ButtonFilled {
    background-color: var(--color-tomato);
    color: var(--color-white);

    &:hover {
        background-color: var(--color-hover-btn);
    }

    &:active {
        background-color: var(--color-tomato-active);
    }
}

.ButtonEmpty {
    background-color: var(--color-white);
    color: var(--color-tomato);
    border: 1px solid;
    border-color: var(--color-tomato);

    &:hover {
        color: var(--color-tomato-hover);
        border-color: var(--color-tomato-hover);
    }

    &:active {
        color: var(--color-tomato-active);
        border-color: var(--color-tomato-active);
    }
}

.ButtonText {
    position: relative;
    transform: translate(-50%, -50%);
    z-index: 2;
    white-space: nowrap;
}
