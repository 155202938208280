@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.TitleModule {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-silver);
  font-family: var(--font-regular);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.DisabledContainer {
  background: rgba(203, 195, 193, 0.4);
  border: none;
  color: var(--color-silver);
  //border: 1px solid var(--color-gray-border);
}

.ActiveDropDown {
  border: 1px solid var(--color-tomato);
  color: var(--color-silver);
}

.TextSelect {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.OptionsText {
  font-family: var(--font-medium);
  font-size: 12px;
  line-height: 16px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.OptionsWrapper {
  &:not(:last-child) {
    margin-bottom: 10px;

    @include media_desktop_qhd {
      margin-bottom: 10px;
    }

    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
    }
  }
}

.DropDownIcon {
  transition: 0.4s;
}

.isActiveDropDownIcon {
  transition: 0.4s;
  transform: rotate(180deg);
}

.WrapperForLoader {
  text-align: center;
}