@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.MainContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 97;
    text-align: center;
    overflow: hidden;
}

.PseudoMainContainer {
    background-color: var(--color-silver);
    opacity: .6;
    width: 100%;
    height: 100%;
    z-index: 98;
}

.MainContentContainer {
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: var(--color-white);
    border-radius: 6px;
    box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.05);
    z-index: 4;
    height: max-content;
    box-sizing: border-box;
}

.CloseContainer {
    width: 100%;
    display: block;
    text-align: right;
}

.CloseButton {
    cursor: pointer;
    outline: none;

    @include media_desktop_hd {
        width: px-to-vh(40px);
        height: px-to-vh(40px);
    }
}