@import '../../../../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../../../../assets/scss/medias';

.Title {
  font-family: var(--font-medium);
  text-align: left;

  @include media_desktop_hd {
    font-size: 19px;
    line-height: 23px;
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px;
  }
}