@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.CrmContainer {
  user-select: none;
}

.WrapperBtnName {
  display: flex;
  align-items: center;
  user-select: none;

  span {
    font-family: var(--font-medium);
    margin-left: 17px;

    @include media_desktop_hd {
      font-size: 22px;
      line-height: 28px;
    }

    @include media_desktop_wxga {
      font-size: 25px;
      line-height: 31px;
    }

    @include media_desktop_full {
      font-size: 28px;
      line-height: 34px;
    }
  }

  @include media_desktop_hd {
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    margin-bottom: 31px;
  }

  @include media_desktop_full {
    margin-bottom: 33px;
  }
}

.BackImgWrapper {
  border: 1px solid var(--color-tomato);
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--color-tomato-hover);

    svg {
      fill: var(--color-tomato-hover);
    }
  }
}

.BackImg {
  padding: 6px 12px;

  svg {
    fill: var(--color-tomato);
  }
}

.TitlePerformance {
  font-family: var(--font-medium);
  user-select: none;

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 32px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px;
  }
}

.WrapperFilters {
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    margin-bottom: 32px;
  }
}

.DropDownContainer {
  margin-top: 0 !important;
  width: 146px;
  padding: 7px 14px 7px 13px;
  box-sizing: border-box;

  @include media_desktop_qhd {
    width: 146px;
    padding: 7px 14px 7px 13px;
  }

  @include media_desktop_hd {
    width: 166px;
    padding: 8px 15px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 206px;
    padding: 9px 16px 9px 15px;
  }

  @include media_desktop_full {
    width: 246px;
    padding: 10px 17px 10px 16px;
  }
}

.DropDownMenuContainer {
  width: 83%;

  @include media_desktop_qhd {
    width: 83%;
  }

  @include media_desktop_hd {
    width: 86%;
  }

  @include media_desktop_wxga {
    width: 87%;
  }

  @include media_desktop_full {
    width: 87%;
  }
}

.DropDownMenuContainerNotSelected {
  top: 115%;
}

.FilterContainerItem {
  font-size: 12px;
  line-height: 16px;
  margin-right: 20px;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-right: 13px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin-right: 15px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin-right: 17px;
  }
}

.WrapperLogo {
  display: flex;
  box-sizing: border-box;
  user-select: none;

  @include media_desktop_hd {
    width: 172px;
    height: 46px;
  }

  @include media_desktop_wxga {
    width: 182px;
    height: 50px;
  }

  @include media_desktop_full {
    width: 192px;
    height: 54px;
  }
}

.Red {
  background: var(--color-tomato);
  color: var(--color-tomato);
  border-radius: 6px 0 0 6px;
  width: 5px;
  user-select: none
}

.PipedriveLogo {
  background: var(--color-white);
  border-radius: 0 6px 6px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WrapperForLoader {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  align-items: center;
}

.TextLoader {
  margin-top: 20px;
  font-family: var(--font-medium);
  font-size: 16px;
  color: var(--color-silver);
}

.ToastContainer {
  div {
    font-family: var(--font-medium);
    color: var(--color-silver);
    font-size: 16px;
    text-align: left;
  }

  .Toastify__close-button > svg {
    color: var(--color-dark-text) !important
  }

  svg {
    visibility: visible;
    fill: var(--color-dark-text) !important;
  }

  [role="progressbar"] {
    background: var(--color-tomato) !important;
  }
}


