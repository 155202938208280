@import '../../../../../../../assets/scss/helpers';
@import '../../../../../../../assets/scss/medias';

.WrapperMobile {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 30px auto;
  position: relative;
  width: 320px;

  @include media_desktop_qhd {
    width: 280px;
  }
}

.WrapperGoBack {
  display: flex;
  align-items: center;
  padding: 25px 0 25px 25px;

  &:hover {
    cursor: pointer;
    color: var(--color-tomato);
    svg {
      fill: var(--color-tomato);
    }
  }

  @include media_desktop_hd {
    padding: 0;
    margin-bottom: 40px;
    justify-content: center;
  }

  @include media_desktop_wxga {
    padding: 0;
    margin-bottom: 40px;
    justify-content: center;
  }

  @include media_desktop_full {
    padding: 0;
    margin-bottom: 40px;
    justify-content: center;
  }
}

.TextGoBack {
  font-family: var(--font-regular);
  margin-left: 8px;
  font-size: 18px;
  line-height: 22px;

  @include media_desktop_hd {
    margin-left: 6px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    margin-left: 7px;
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    margin-left: 8px;
    font-size: 20px;
    line-height: 24px;
  }
}

.ImageMenu {
  display: flex;
  align-items: end;

  svg {
    @include media_desktop_hd {
      width: 8px;
      height: 11px;
    }

    @include media_desktop_wxga {
      width: 9px;
      height: 12px;
    }

    @include media_desktop_full {
      width: 10px;
      height: 13px;
    }
    fill: var(--color-dark-text);
  }

  @include media_desktop_hd {
    width: 8px;
    height: 12px;
  }

  @include media_desktop_wxga {
    width: 9px;
    height: 13px;
  }

  @include media_desktop_full {
    width: 10px;
    height: 14px;
  }
}

.StepContainer {
  display: flex;
  align-items: center;
}

.ImageContainer {
  display: block;
  text-align: center;
  position: relative;
  box-sizing: border-box;

  @include media_desktop_hd {
    height: 54px;
  }

  @include media_desktop_wxga {
    height: 64px;
  }

  @include media_desktop_full {
    height: 74px;
  }
}

.Line {
  width: 100%;
  height: 100%;
  max-width: 1px;
  background: var(--color-gray-border);
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.LineMobile {
  display: block;
  content: ' ';
  position: absolute;
  height: 1px;
  background: var(--color-gray-border);
  top: 50%;

  width: 320px;
  @include media_desktop_qhd {
    width: 280px;
  }
}

.StepIcon {
  display: block;
  border-radius: 50%;
  box-shadow: -2px 2px 6px rgba(58, 70, 93, 0.11965);
  z-index: 2;

  @include media_desktop_hd {
    width: 20px;
    height: 20px;
  }

  @include media_desktop_wxga {
    width: 22px;
    height: 22px;
  }

  @include media_desktop_full {
    width: 24px;
    height: 24px;
  }
}

.Text {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-left: 30px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-left: 35px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-left: 40px;
  }
}

.StepPassed {
  color: var(--color-green);
}

.TextFinish {
  color: var(--color-silver);
}