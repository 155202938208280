@import '../../../../assets/scss/helpers';
@import '../../../../assets/scss/medias';

.MainPopupContainer {
  padding: 38px 38px 72px 38px;

  @include media_desktop_hd {
    width: 660px;
    padding: 18px 26px 32px 26px;
  }

  @include media_desktop_wxga {
    width: 760px;
    padding: 28px 30px 52px 30px;
  }

  @include media_desktop_full {
    width: 860px;
    padding: 38px 38px 72px 38px;
  }
}

.Title {
  font-family: var(--font-medium);
  color: var(--color-silver);

  @include media_desktop_hd  {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 40px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 50px;
  }
}

.Upgrade {
  color: var(--color-tomato);
}

.WrapperSeat {
  border: 1px solid var(--color-light-tomato);
  width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 6px;

  @include media_desktop_hd {
    width: 520px;
  }

  @include media_desktop_wxga {
    width: 560px;
  }

  @include media_desktop_full {
    width: 620px;
  }
}

.WrapperPrice {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media_desktop_hd {
    margin-top: 30px;
  }

  @include media_desktop_wxga {
    margin-top: 40px;
  }

  @include media_desktop_full {
    margin-top: 50px;
  }
}

.Price {
  font-family: var(--font-bold);
  margin-right: 16px;

  @include media_desktop_hd {
    font-size: 42px;
    line-height: 52px;
  }

  @include media_desktop_wxga {
    font-size: 45px;
    line-height: 55px;
  }

  @include  media_desktop_full {
    font-size: 48px;
    line-height: 58px;
  }
}

.Details {
  font-family: var(--font-medium);
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.WrapperBenefits {
  display: flex;
  text-align: left;
  justify-content: space-around;
  margin: 32px 0 50px 0;
}

.ItemBenefits {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.Icon {
  margin-right: 22px;
}

.CalculateWrapper {
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.CalculateTitle {
  font-family: var(--font-regular);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 24px;
    margin-right: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 26px;
    margin-right: 30px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 28px;
    margin-right: 40px;
  }
}

.PriceCalculate {
  border: 1px solid var(--color-gray-border);
  color: var(--color-gray);
  padding: 12px 14px;
  border-radius: 5px;
  width: 58px;
  height: 44px;
  box-sizing: border-box;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.WrapperSubtitleCalculatesUsers {
  position: absolute;
  margin-left: 14px;
}

.WrapperSubtitleCalculates {
  position: absolute;
}

.SubtitleCalculates {
  color: var(--color-silver);
  margin-top: 4px;

  @include media_desktop_hd {
    font-size: 10px;
    line-height: 12px;
  }

  @include media_desktop_wxga {
    font-size: 11px;
    line-height: 13px;
  }

  @include media_desktop_full {
    font-size: 12px;
    line-height: 14px;
  }
}

.SubtitleCalculatesPerSeat {
  position: absolute;
  margin-left: 5px;
}

.PerMonth {
  position: absolute;
  margin-left: 20px;
}

.CrossImg {
  margin: 0 8px 0 8px;
}

.EqualsImg {
  margin: 0 15px 0 15px;
}

.Input.Input {
  width: 58px;
  height: 44px;
  padding: 12px 14px;
  font-family: var(--font-medium);
  box-sizing: border-box;
  position: relative;
  text-align: center;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }

  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  }
}

.PricePerMonth {
  font-size: var(--font-medium);
  color: var(--color-tomato);
  width: 85px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  @include media_desktop_hd {
    font-size: 32px;
    line-height: 39px;
  }

  @include media_desktop_wxga {
    font-size: 34px;
    line-height: 41px;
  }

  @include media_desktop_full {
    font-size: 36px;
    line-height: 43px;
  }
}

.Btn.Btn {
  @include media_desktop_hd {
    margin: 55px auto 0 auto;
  }

  @include media_desktop_wxga {
    margin: 65px auto 0 auto;
  }

  @include media_desktop_full {
    margin: 75px auto 0 auto;
  }
}

.DisabledBtn {
  background: var(--color-gray-border);
  cursor: default;

  &:hover {
    background: var(--color-gray-border);
  }
}