@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.TitleAnalytics {
  font-family: var(--font-medium);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 15px;

    @include media_desktop_qhd {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 18px;
    }

    @include media_desktop_hd {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    @include media_desktop_wxga {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 22px;
    }

     @include media_desktop_full {
       font-size: 24px;
       line-height: 28px;
       margin-bottom: 24px;
     }
}

.WrapperForComponentsAnalytics {
  box-sizing: border-box;
  background: var(--color-white);
  border-radius: 6px;
  display: flex;
  padding: 16px 20px 16px 20px;
  width: 100%;
  max-width: 800px;

  @include media_desktop_qhd {
    padding: 16px 20px 16px 20px;
    max-width: 800px;
  }

  @include media_desktop_hd {
    padding: 20px 26px 20px 25px;
    max-width: 1000px;
  }

  @include media_desktop_wxga {
    padding: 30px 29px 30px 30px;
    max-width: 1200px;
  }

  @include media_desktop_full {
    padding: 37px 29px 37px 40px;
    max-width: 1367px;
  }
}

.Analytics {
  display: flex;
  border-right: 1px solid var(--color-gray-border);
}

.TextLearning {
  width: 55px;

    @include media_desktop_hd {
      width: 55px;
    }

    @include media_desktop_wxga {
      width: 65px;
    }

    @include media_desktop_full {
      width: 75px;
    }
}

.TextCourse {
  width: 70px;

    @include media_desktop_hd {
      width: 70px;
    }

    @include media_desktop_wxga {
      width: 91px;
    }

    @include media_desktop_full {
      width: 101px;
    }
}

.TextHoursSaved {
  width: 45px;

    @include media_desktop_hd {
      width: 45px;
    }

    @include media_desktop_wxga {
      width: 49px;
    }

    @include media_desktop_full {
      width: 59px;
    }
}

.WrapperMoneySaved {
  display: flex;
}

.WrapperDetails {
  display: flex;
  align-items: center;
}

.TextMoney {
  width: min-content;
  margin-right: 16px;
  margin-left: 12px;
  font-size: 12px;
  line-height: 19px;
  color: var(--color-dark-text);
  font-family: var(--font-regular);

    @include media_desktop_qhd {
      margin-right: 16px;
      margin-left: 12px;
      font-size: 12px;
      line-height: 19px;
    }

    @include media_desktop_hd {
      margin-right: 20px;
      margin-left: 20px;
      font-size: 14px;
      line-height: 22px;
    }

    @include media_desktop_wxga {
      margin-right: 20px;
      margin-left: 30px;
      font-size: 17px;
      line-height: 25px;
    }

    @include media_desktop_full {
      margin-right: 30px;
      margin-left: 38px;
      font-size: 20px;
      line-height: 28px;
    }
}

.ContainerResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.TextNumberInput,
.TextNumber {
  box-sizing: border-box;
  border: 1px solid var(--color-gray-border);
  font-family: var(--font-medium);
  border-radius: 5px;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 4px;
  text-align: center;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 4px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    padding: 9px 8px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 10px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    padding: 11px 12px;
  }

  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  }
}

.TextNumberInput {
  width: 36px;

  @include  media_desktop_qhd {
    width: 40px;
  }

  @include media_desktop_hd {
    width: 44px;
  }

  @include media_desktop_wxga {
    width: 50px;
  }

  @include media_desktop_full {
    width: 54px;
  }
}

.TextNumber {
  width: 42px;

  @include  media_desktop_qhd {
    width: 42px;
  }

  @include media_desktop_hd {
    width: 52px;
  }

  @include media_desktop_wxga {
    width: 62px;
  }

  @include media_desktop_full {
    width: 74px;
  }
}

.TextNumberMarginPerHour {
  margin-right: 2px;

    @include media_desktop_qhd {
      margin-right: 2px;
    }

    @include media_desktop_hd {
      margin-right: 4px;
    }

    @include  media_desktop_wxga {
      margin-right: 6px;
    }

    @include media_desktop_full {
      margin-right: 8px;
    }
}

.TextNumberMarginHoursSaved {
  margin-left: 2px;
  margin-right: 6px;

    @include media_desktop_qhd {
      margin-left: 2px;
      margin-right: 6px;
    }

    @include media_desktop_hd {
      margin-left: 4px;
      margin-right: 9px;
    }

    @include media_desktop_wxga {
      margin-left: 6px;
      margin-right: 11px;
    }

    @include media_desktop_full {
      margin-left: 8px;
      margin-right: 15px;
    }
}

.TextHours {
  color: var(--color-silver);
}

.Subtitle {
  font-family: var(--font-regular);
  color: var(--color-silver);
  font-size: 9px;
  line-height: 11px;
  width: max-content;
  text-align: center;
  position: absolute;
  top: 35px;

    @include  media_desktop_qhd {
      font-size: 9px;
      line-height: 11px;
      width: max-content;
      top: 35px;
    }

    @include  media_desktop_hd {
      font-size: 10px;
      line-height: 12px;
      width: max-content;
      top: 40px;
    }

    @include  media_desktop_wxga {
      font-size: 11px;
      line-height: 13px;
      width: max-content;
      top: 48px;
    }

    @include media_desktop_full {
      font-size: 12px;
      line-height: 14px;
      width: max-content;
      top: 52px;
    }
}

.Result {
  font-family: var(--font-medium);
  color: var(--color-tomato);
  font-size: 16px;
  line-height: 24px;
  margin-left: 6px;
  width: 70px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

    @include media_desktop_qhd {
      font-size: 16px;
      line-height: 24px;
      margin-left: 6px;
      width: 70px;
    }

    @include media_desktop_hd {
      font-size: 22px;
      line-height: 28px;
      margin-left: 9px;
      width: 80px;
    }

    @include media_desktop_wxga {
      font-size: 30px;
      line-height: 36px;
      margin-left: 11px;
      width: 99px;
    }

    @include media_desktop_full {
      font-size: 36px;
      line-height: 43px;
      margin-left: 15px;
      width: 114px;
    }
}

.Tooltip {
  font-family: var(--font-medium) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background: var(--color-dark-text) !important;
  border-radius: 6px !important;
}

