@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.ModuleItemContainer {
    display: flex;
    align-items: center;
    position: relative;

    @include media_desktop_hd {
        padding-bottom: 20px;
    }

    @include media_desktop_wxga {
        padding-bottom: 22px;
    }

    @include media_desktop_full {
        padding-bottom: 24px;
    }

    &:hover {
        .DeleteIcon {
            visibility: visible;
        }
        .DragAndDrop {
            visibility: visible;
        }
    }
}

.InputField {
    font-family: var(--font-medium);

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 18px;
        padding: 12px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 20px;
        padding: 14px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        padding: 16px;
    }
}

.ErrorInputField {
    border-color: var(--color-tomato);

    &::placeholder {
        color: var(--color-tomato);
    }
}

.DeleteIcon {
    visibility: hidden;

    svg {
        fill: var(--color-silver);
        cursor: pointer;

        &:hover {
            fill: var(--color-tomato);
            transform: scale(1.1);
        }

        @include media_desktop_hd {
            width: 18px;
            height: 18px;
        }

        @include media_desktop_wxga {
            width: 20px;
            height: 20px;
        }

        @include media_desktop_full {
            width: 22px;
            height: 22px;
        }
    }

    @include media_desktop_hd {
        margin-left: 16px;
    }

    @include media_desktop_wxga {
        margin-left: 20px;
    }

    @include media_desktop_full {
        margin-left: 28px;
    }
}

.DragAndDrop {
    visibility: hidden;

    svg {
        fill: var(--color-silver);

        &:hover {
            fill: var(--color-tomato);
            transform: scale(1.1);
        }

        @include media_desktop_hd {
            width: 20px;
            height: 20px;
        }

        @include media_desktop_wxga {
            width: 22px;
            height: 22px;
        }

        @include media_desktop_full {
            width: 24px;
            height: 24px;
        }
    }

    @include media_desktop_hd {
        margin-right: 16px;
    }

    @include media_desktop_wxga {
        margin-right: 20px;
    }

    @include media_desktop_full {
        margin-right: 28px;
    }
}


