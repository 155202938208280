@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.VideoContainer {
    width: 100%;
    height: 100%;

    @include media_desktop_hd {
        width: 873px;
        height: 480px;
    }

    @include media_desktop_wxga {
        width: 980px;
        height: 535px;
    }

    @include media_desktop_full {
        width: 1150px;
        height: 555px;
    }
}

.Video {
    width: 100%;
    height: 100%;

    @include media_desktop_full {
        border-radius: 6px;
    }
}