@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.InputContainer {
  position: relative;
  border-radius: 6px;
  margin-top: 8px;
}

.Input {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 146px;
  padding: 7px 20px 7px 10px;
  z-index: 2;
  font-family: var(--font-medium);
  font-size: 12px;
  line-height: 16px;

  @include media_desktop_qhd {
    width: 146px;
    font-size: 12px;
    line-height: 16px;
    padding: 7px 20px 7px 10px;
  }

  @include media_desktop_hd {
    width: 176px;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 22px 8px 12px;
  }

  @include media_desktop_wxga {
    width: 206px;
    font-size: 18px;
    line-height: 22px;
    padding: 9px 25px 9px 18px;
  }

  @include media_desktop_full {
    width: 246px;
    font-size: 20px;
    line-height: 24px;
    padding: 13px 21px 13px 24px;
  }

  &::placeholder {
    color: var(--color-dark-text);
    font-size: 12px;
    line-height: 16px;
    font-family: var(--font-medium);

    @include media_desktop_qhd {
      font-size: 12px;
      line-height: 16px;
    }

    @include media_desktop_hd {
      font-size: 14px;
      line-height: 18px;
    }

    @include media_desktop_wxga {
      font-size: 18px;
      line-height: 22px;
    }

    @include media_desktop_full {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.Image {
  z-index: 0;
  position: absolute;
  bottom: 28%;
  right: 6px;
  cursor: pointer;

  @include media_desktop_hd {
    width: 20px;
    height: 20px;
    bottom: 8px;
    right: 6px;
  }

  @include media_desktop_wxga {
    width: 23px;
    height: 23px;
    bottom: 10px;
    right: 8px;
  }

  @include media_desktop_full {
    width: 26px;
    height: 26px;
    bottom: 10px;
    right: 10px;
  }
}

.ImageClose {

  @include media_desktop_hd {
    right: 4px;
  }

  @include media_desktop_wxga {
    right: 5px;
  }

  @include media_desktop_full {
    right: 6px;
  }
}

.WrapperForLoader {
  text-align: center;
}

.InputNotFound {
  font-family: var(--font-medium);
  font-size: 14px;
}