@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  box-sizing: border-box;
  padding-right: 5px;
}

.SubContainer {
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  @include media_desktop_hd {
    height: 408px;
  }

  @include media_desktop_wxga {
    height: 483px;
  }

  @include media_desktop_full {
    height: 550px;
  }
}

//.BlockScroll {
//  overflow-x: hidden;
//  overflow-y: hidden;
//}

.WrapperMetricItem {
  display: flex;
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  box-sizing: border-box;

  @include media_desktop_hd {
    width: 350px;
    margin: 0 48px 20px 44px;
    padding: 12px 12px 23px 12px;
  }

  @include media_desktop_wxga {
    width: 370px;
    margin: 0 48px 22px 44px;
    padding: 14px 14px 23px 14px;
  }

  @include media_desktop_full {
    width: 390px;
    margin: 0 48px 24px 44px;
    padding: 16px 16px 23px 16px;
  }
}

.WrapperInput {
  width: 100%;

  input[type='checkbox'] {
    transition: 0.2s;
    cursor: pointer;
    box-sizing: border-box;
    appearance: none;
    padding: 0;
    background: var(--color-white);
    border-radius: 2px;
    outline: 1px solid var(--color-gray-border);
    border: 3px solid white;
    margin-left: 0;
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  input[type='checkbox']:checked {
    transition: 0.2s;
    transform: scale(1.1);
    box-sizing: border-box;
    background: var(--color-tomato);
    border-radius: 2px;
  }

}

.WrapperMetricImg {
  display: flex;
  justify-content: space-between;

  @include media_desktop_hd {
    margin-bottom: 19px;
  }

  @include media_desktop_wxga {
    margin-bottom: 21px;
  }

  @include media_desktop_full {
    margin-bottom: 23px;
  }
}

.InputAndMetrics {
  display: flex;
  align-items: center;
}

.Metric {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}

.WrapperImage {
  cursor: pointer;
  position: relative;
}

.Image {
  transition: 0.2s;
  svg {
    fill: var(--color-gray-border);

    &:hover {
      fill: var(--color-tomato);
    }
  }
}

.Description {
  font-family: var(--font-medium);
  text-align: left;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    max-width: 304px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    max-width: 300px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    max-width: 330px;
  }
}
