@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.WrapperOptions {
  &:not(:last-child) {
    @include media_desktop_hd {
      margin-bottom: 12px;
    }

    @include media_desktop_wxga {
      margin-bottom: 14px;
    }

    @include media_desktop_full {
      margin-bottom: 16px;
    }
  }
}

.Options {
  display: block;
  cursor: pointer;
  font-family: var(--font-medium);
  //text-overflow: ellipsis;
  //white-space: nowrap;
  //overflow: hidden;
  &:hover {
    color: var(--color-tomato);
  }
}

.OptionsImg {
  width: 13px;
  height: 15px;

  @include media_desktop_qhd {
    width: 13px;
    height: 15px;
  }

  @include media_desktop_hd {
    width: 15px;
    height: 17px;
  }

  @include media_desktop_wxga {
    width: 17px;
    height: 19px;
  }

  @include media_desktop_full {
    width: 19px;
    height: 21px;
  }
}

.ReportsImg {
  margin-left: 16px;
}

.CheckBoxOption {
  word-break: break-word;

  @include media_desktop_hd {
    width: 104px;
  }
  @include media_desktop_wxga {
    width: 140px;
  }

  @include media_desktop_full {
    width: 170px;
  }
}

.ActiveOptions {
  color: var(--color-tomato);
}

.Tooltip {
  font-family: var(--font-medium) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background: var(--color-dark-text) !important;
  border-radius: 6px !important;
}

.Label {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    cursor: pointer;
    box-sizing: border-box;
    appearance: none;
    padding: 0;
    background: var(--color-white);
    border-radius: 2px;
    outline: 1px solid var(--color-gray-border);
    border: 3px solid white;
    margin-right: 16px;
    margin-left: 1px;

    @include media_desktop_hd {
      width: 16px;
      height: 16px;
    }

    @include media_desktop_wxga {
      width: 18px;
      height: 18px;
    }

    @include media_desktop_full {
      width: 20px;
      height: 20px;
    }
  }

  input[type='checkbox']:checked {
    box-sizing: border-box;
    background: var(--color-tomato);
    border-radius: 2px;
  }
}