@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.ItemContainer {
    display: flex;

    @include media_desktop_qhd {
        margin-top: 10px;
    }

    @include media_desktop_hd {
        margin-top: 12px;
    }

    @include media_desktop_wxga {
        margin-top: 14px;
    }

    @include media_desktop_full {
        margin-top: 16px;
    }
}

.FieldName {
    display: block;
    color: var(--color-dark-text);
    font-family: var(--font-medium);
    cursor: pointer;

    &:hover {
        color: var(--color-tomato);
    }

    @include media_desktop_hd {
        margin-left: 12px;
        font-size: 14px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        margin-left: 14px;
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        margin-left: 16px;
        font-size: 18px;
        line-height: 22px;
    }
}

.Icon {
    cursor: pointer;

    @include media_desktop_qhd {
        width: 18px;
        height: 18px;
    }

    @include media_desktop_hd {
        width: 20px;
        height: 20px;
    }

    @include media_desktop_wxga {
        width: 22px;
        height: 22px;
    }

    @include media_desktop_full {
        width: 24px;
        height: 24px;
    }
}