@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.SettingsPopupContainer {
    overflow-x: hidden;

    @include media_desktop_qhd {
        padding: 18px;
        width: 404px;
    }

    @include media_desktop_hd {
        padding: 20px;
        width: 424px;
    }

    @include media_desktop_wxga {
        padding: 22px;
        width: 444px;
    }

    @include media_desktop_full {
        padding: 24px;
        width: 464px;
    }
}

.MainContainer {
    padding: 0 px-to-vh(40px) px-to-vh(32px);
}

.PopupTitle {
    color: var(--color-dark-text);
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
    }
}

.SliderTitleText {
    font-family: var(--font-medium);
    color: var(--color-dark-text);

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.InputField {
    text-align: center;
    color: var(--color-dark-text);
    box-sizing: border-box;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        width: 44px;
        padding: 6px 9px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        width: 51px;
        padding: 8px 11px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        width: 58px;
        padding: 10px 13px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        width: 65px;
        padding: 12px 15px;
    }

    &::-webkit-input-placeholder {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    &::-moz-placeholder {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    &:-moz-placeholder {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    &:-ms-input-placeholder {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
    &:focus::-webkit-input-placeholder {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &:focus::-moz-placeholder {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &:focus:-moz-placeholder {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &:focus:-ms-input-placeholder {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}

.DisableInputField {
    background-color: var(--color-light-background);
}

.SaveButton {
    display: block;
    color: var(--color-white);
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        margin: 26px auto 0;
    }

    @include media_desktop_hd {
        margin: 28px auto 0;
    }

    @include media_desktop_wxga {
        margin: 30px auto 0;
    }

    @include media_desktop_full {
        margin: 32px auto 0;
    }
}