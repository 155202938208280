@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.MainContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.BackgroundImage {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.SignInContainer {
  display: block;
}

.TitleText {
  display: block;
  font-family: var(--font-medium);
  color: var(--color-tomato);
  font-size: 26px;
  line-height: 30px;

  @include media_desktop_qhd {
    font-size: 42px;
    line-height: 46px;
  }

  @include media_desktop_hd {
    font-size: 44px;
    line-height: 50px;
  }

  @include media_desktop_wxga {
    font-size: 46px;
    line-height: 54px;
  }

  @include media_desktop_full {
    font-size: 48px;
    line-height: 58px;
  }

  span {
    color: var(--color-black);
  }
}

.TitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: px-to-vh(64px);
}

.WrapperSubtitle {
  text-align: center;
  font-family: var(--font-medium);
  font-size: 16px;
  line-height: 18px;

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.Subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RocketImg {
  width: 22px;
  height: 22px;
  margin-left: 5px;
}

.Button {
  margin: px-to-vh(61px) auto;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    width: 280px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    width: 300px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    width: 320px;
  }
}