@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.CourseModuleContainer {
    display: flex;
}

.ImageContainer {
    display: block;
    text-align: center;
    position: relative;
}

.BottomStepIcon {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ModulesContainer {
    position: relative;
    width: 100%;

    @include media_desktop_hd {
        width: 553px;
        margin-left: 30px;
    }

    @include media_desktop_wxga {
        width: 723px;
        margin-left: 35px;
    }

    @include media_desktop_full {
        width: 837px;
        margin-left: 40px;
    }
}

.Line {
    width: 100%;
    height: 100%;
    max-width: 1px;
    background: var(--color-gray-border);
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.StepText {
    font-family: var(--font-regular);
    color: var(--color-silver);
    margin: 1px 6px 0 0;

    @include media_desktop_hd {
        font-size: 13px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 15px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 17px;
        line-height: 22px;
    }
}

.AddModuleButton {
    display: flex;
    align-items: center;

    @include media_desktop_hd {
        padding: 6px;
        margin-bottom: 20px;
    }

    @include media_desktop_wxga {
        padding: 7px;
        margin-bottom: 22px;
    }

    @include media_desktop_full {
        padding: 8px;
        margin-bottom: 24px;
    }
}

.PlusImage {
    @include media_desktop_hd {
        width: 20px;
        height: 20px;
    }

    @include media_desktop_wxga {
        width: 22px;
        height: 22px;
    }

    @include media_desktop_full {
        width: 24px;
        height: 24px;
    }

}

.ButtonText {
    color: var(--color-tomato);

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }

}

.SaveButton {
    position: absolute;
    font-family: var(--font-medium);
    color: var(--color-white);

    @include media_desktop_hd {
        padding: 8px 24px;
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        padding: 10px 28px;
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        padding: 12px 32px;
        font-size: 20px;
        line-height: 24px;
    }
}

.DisableSaveButton {
    cursor: not-allowed;
}