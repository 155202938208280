@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.InputContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.InputField {
    box-sizing: border-box;
    position: relative;
    border-right: none;
    border-radius: 6px 0 0 6px;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        margin: 30px 0;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        margin: 35px 0;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        margin: 40px 0;
    }
}

.InputError {
    border-color: var(--color-tomato);

    &::placeholder {
        color: var(--color-tomato);
    }
}

.OpenVideoButton {
    display: flex;
    align-items: center;
    height: max-content;
    white-space: nowrap;
    border: 2px solid var(--color-tomato);
    color: var(--color-tomato);
    border-radius: 0 6px 6px 0;
    box-sizing: border-box;
    cursor: pointer;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        padding: 9px 10px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
        padding: 11px 12px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
        padding: 13px 14px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
        padding: 15px 16px;
    }
}

.YouTubeIcon {
    display: block;

    @include media_desktop_qhd {
        height: 18px;
        margin-right: 12px;
    }

    @include media_desktop_hd {
        height: 20px;
        margin-right: 14px;
    }

    @include media_desktop_wxga {
        height: 22px;
        margin-right: 16px;
    }

    @include media_desktop_full {
        height: 24px;
        margin-right: 18px;
    }
}