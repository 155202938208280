@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.WrapperFree {
  border: 1px solid var(--color-tomato-hover);
  height: fit-content;
  box-sizing: border-box;

  @include media_desktop_hd {
    width: 288px;
    padding: 15px 36px 54px 36px;
  }

  @include media_desktop_hd-after {
    width: 350px;
    padding: 15px 36px 54px 36px;
  }

  @include media_desktop_wxga {
    width: 380px;
    padding: 20px 40px 64px 40px;
  }

  @include media_desktop_wxga-after {
    width: 440px;
    padding: 20px 40px 64px 40px;
  }

  @include media_desktop_full {
    width: 450px;
    padding: 25px 46px 74px 46px;
  }
}

.CurrentPlanWrapper {
  text-align: center;
}

.PlanTitle {
  font-family: var(--font-medium);
  color: var(--color-gray-title);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 21px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 23px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 25px;
  }
}

.Plan {
  font-family: var(--font-bold);

  @include media_desktop_hd {
    font-size: 39px;
    line-height: 49px;
    margin-bottom: 6px;
  }

  @include media_desktop_wxga {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 8px;
  }

  @include media_desktop_full {
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 10px;
  }
}

.Seats {
  font-family: var(--font-medium);
  color: var(--color-gray-title);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 33px;

  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 35px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 38px;
  }
}

.Subtitle {
  font-family: var(--font-medium);
  color: var(--color-gray-title);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 7px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 9px;
  }
}

.CompanyBranding {
  display: flex;
  align-items: center;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 25px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 28px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 31px;
  }
}

.Image {
  @include media_desktop_hd {
    margin-right: 18px;
  }

  @include media_desktop_wxga {
    margin-right: 20px;
  }

  @include media_desktop_full {
    margin-right: 22px;
  }
}

.TrainingItem {
  display: flex;
  align-items: center;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 9px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}