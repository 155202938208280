@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.Container {
  box-sizing: border-box;
  padding-right: 5px;
}

.SubContainer {
  overflow-y: auto;
  overflow-x: hidden;

  @include media_desktop_hd {
    height: 408px;
  }

  @include media_desktop_wxga {
    height: 483px;
  }

  @include media_desktop_full {
    height: 550px;
  }
}

.InputAndLearner {
  display: flex;
  align-items: center;
}

.WrapperInput {
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  border: 1px solid var(--color-gray-border);
  border-radius: 6px;
  box-sizing: border-box;

  @include media_desktop_hd {
    width: 350px;
    margin-bottom: 20px;
  }

  @include media_desktop_wxga {
    width: 370px;
    margin-bottom: 22px;
  }

  @include media_desktop_full {
    width: 390px;
    margin-bottom: 24px;
  }

  input[type='checkbox'] {
    transition: 0.2s;
    cursor: pointer;
    box-sizing: border-box;
    appearance: none;
    padding: 0;
    background: var(--color-white);
    border-radius: 2px;
    outline: 1px solid var(--color-gray-border);
    border: 3px solid white;
    margin-right: 16px;
    margin-left: 0;

    @include media_desktop_hd {
      width: 20px;
      height: 20px;
    }

    @include media_desktop_wxga {
      width: 22px;
      height: 22px;
    }

    @include media_desktop_full {
      width: 24px;
      height: 24px;
    }
  }

  input[type='checkbox']:checked {
    transition: 0.2s;
    transform: scale(1.1);
    box-sizing: border-box;
    background: var(--color-tomato);
    border-radius: 2px;
  }

  @include media_desktop_hd {
    padding: 12px;
  }

  @include media_desktop_wxga {
    padding: 14px;
  }

  @include media_desktop_full {
    padding: 16px;
  }
}

.Learner {
  font-family: var(--font-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    max-width: 280px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    max-width: 300px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    max-width: 320px;
  }
}

.ContainerEmptyData {
  padding: 0 40px;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    margin: 100px 0 278px 0;
  }

  @include media_desktop_wxga {
    margin: 120px 0 328px 0;
  }

  @include media_desktop_full {
    margin: 150px 0 367px 0;
  }
}

.FirstItemEmpty {
  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 22px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 24px;
  }
}

.SecondItemEmpty {
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
  }

  @include  media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
  }
}
