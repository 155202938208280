@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.ControlPanel {
  display: flex;
  cursor: default;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 6px -2px var(--color-grey);
  padding: 10px 10px;
  background: var(--color-silver);
  font-size: 14px;
  border-radius: 4px 4px 0 0;
}

.ControlPanelArrowContainer{
  display: flex;
  align-items: center;
  span {
    color: var(--color-white);
  }
}

.ControlPanelBtnPrevious {
  cursor: pointer;
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.ControlPanelBtnNext {
  cursor: pointer;
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

.ControlPanelBtnPrevious:hover,
.ControlPanelBtnNext:hover {
  transform: scale(1.4);
}

.ControlPanelDisabled {
  cursor: not-allowed;
  color: var(--color-gray);
  opacity: 0.4;
}

.ControlPanelBtnFullScreen {
  cursor: pointer;
  border: none;
  outline: none;
}

.ControlPanelFullScreen {
  text-decoration: none;
  color: var(--color-white);
  padding: 4px 10px;
  border: 1px solid var(--color-white);
  border-radius: 4px;

  &:hover {
    border: 1px solid var(--color-gray-bronze) ;
    color: var(--color-gray-bronze);
  }
}