@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.MainPopupContainer {

  @include media_desktop_hd {
    width: 480px;
    padding: 12px;
  }

  @include media_desktop_wxga {
    width: 520px;
    padding: 14px;
  }

  @include media_desktop_full {
    width: 558px;
    padding: 16px;
  }
}

.Background {
  opacity: 0.1;
}

.WrapperForBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-medium);
  margin-bottom: 10px;

  @include media_desktop_hd {
    margin-bottom: 16px;
  }

  @include media_desktop_wxga {
    margin-bottom: 18px;
  }

  @include media_desktop_full {
    margin-bottom: 20px;
  }
}

.Title {
  color: var(--color-silver);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 35px;
  }

  @include media_desktop_full {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 40px;
  }
}

.Subtitle {
  font-family: var(--font-regular);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  div {
    @include media_desktop_hd {
      font-size: 18px;
      line-height: 20px;
    }

    @include media_desktop_wxga {
      font-size: 20px;
      line-height: 22px;
    }

    @include media_desktop_full {
      font-size: 22px;
      line-height: 24px;
    }
  }
}

.EmphasizedName {
  font-family: var(--font-bold);
  margin-left: 10px;
  max-width: 210px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ResetBtn {
  border: 1px solid var(--color-tomato);

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 20px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 22px;
    line-height: 24px;
  }
}

.CancelBtn {
  border: 1px solid var(--color-gray-border);
  color: var(--color-gray-border);
  margin-right: 40px;
  background-color: var(--color-white);

  &:hover {
    background-color: var(--color-light-background);
  }

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    font-size: 20px;
    line-height: 22px;
  }

  @include media_desktop_full {
    font-size: 22px;
    line-height: 24px;
  }
}

