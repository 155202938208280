@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.Container {
  @include media_desktop_hd {
    height: 60px;
  }

  @include media_desktop_wxga {
    height: 70px;
  }

  @include media_desktop_full {
    height: 80px;
  }
}

.WrapperVerifyBlock {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  align-items: center;
  background: var(--color-light-tomato);

  @include media_desktop_hd {
    padding: 12px 36px;
  }

  @include media_desktop_wxga {
    padding: 14px 38px;
  }

  @include media_desktop_full {
    padding: 16px 40px;
  }
}

.TextVerify {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    margin-right: 20px;
  }

  @include media_desktop_wxga {
    font-size: 20px;
    line-height: 24px;
    margin-right: 24px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin-right: 28px;
  }
}

.VerifyButton {
  display: flex;
  align-items: center;
  font-family: var(--font-medium);
  background: var(--color-white);
  color: var(--color-tomato);

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 28px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 30px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    padding: 12px 32px;
  }

  &:hover {
    background: var(--color-custom-brand-opacity);
    color: var(--color-dark-text);
  }
}

.Loader {
  margin: 0 10px 0 4px;
}

.LoaderButton {
  padding-right: 0;
}

.EmailSendWrapper {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    margin-left: 24px;
    font-size: 14px;
    line-height: 20px;
  }

  @include media_desktop_wxga {
    margin-left: 26px;
    font-size: 16px;
    line-height: 22px;
  }

  @include media_desktop_full {
    margin-left: 28px;
    font-size: 20px;
    line-height: 24px;
  }
}

.Image {
  width: 16px;
  height: 11px;

  @include media_desktop_hd {
    margin-right: 12px;
  }

  @include media_desktop_wxga {
    margin-right: 14px;
  }

  @include media_desktop_full {
    margin-right: 16px;
  }
}