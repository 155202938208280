@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.ContainerTeam {
  padding: 26px 60px 20px 40px;

  @include media_desktop_qhd {
    padding: 26px 60px 20px 40px;
  }

  @include media_desktop_hd {
    padding: 36px 70px 30px 40px;
  }

  @include media_desktop_wxga {
    padding: 46px 80px 40px 40px;
  }

  @include media_desktop_full {
    padding: 56px 90px 40px 40px;
  }
}

.WrapperFiltersAndAddBtn {
  display: flex;
  justify-content: space-between;

  @include media_desktop_hd {
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    margin-bottom: 38px;
  }

  @include media_desktop_full {
    margin-bottom: 48px;
  }
}

.WrapperFilters {
  display: flex;
}

.FilterContainerItem {
  font-size: 12px;
  line-height: 16px;
  margin-right: 20px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
    margin-right: 20px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-right: 24px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin-right: 28px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin-right: 32px;
  }
}

.Title {
  font-family: var(--font-regular);
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-title);

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.DropDownContainer {
  width: 146px;
  padding: 7px 14px 7px 13px;
  box-sizing: border-box;

  @include media_desktop_qhd {
    width: 146px;
    padding: 7px 14px 7px 13px;
  }

  @include media_desktop_hd {
    width: 166px;
    padding: 8px 15px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 206px;
    padding: 9px 16px 9px 15px;
  }

  @include media_desktop_full {
    width: 246px;
    padding: 10px 17px 10px 16px;
  }
}

.DropDownMenuContainer {
  width: 83%;

  @include media_desktop_qhd {
    width: 83%;
  }

  @include media_desktop_hd {
    width: 86%;
  }

  @include media_desktop_wxga {
    width: 87%;
  }

  @include media_desktop_full {
    width: 87%;
  }
}

.Input {
  display: flex;
  align-items: center;
  width: 120px;
  padding: 7px 0 7px 13px;
  border-radius: 6px;
  z-index: 2;
  font-family: var(--font-bold);
  font-size: 20px;

  @include media_desktop_qhd {
    width: 120px;
    padding: 7px 0 7px 13px;
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    width: 166px;
    padding: 8px 16px 8px 20px;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    width: 216px;
    padding: 9px 0 9px 15px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    width: 246px;
    padding: 10px 0 10px 16px;
    font-size: 20px;
    line-height: 24px;
  }

  &::placeholder {
    color: var(--color-dark-text);
    font-size: 12px;
    line-height: 16px;

    @include media_desktop_qhd {
      font-size: 12px;
      line-height: 16px;
    }

    @include media_desktop_hd {
      font-size: 14px;
      line-height: 18px;
    }

    @include media_desktop_wxga {
      font-size: 18px;
      line-height: 22px;
    }

    @include media_desktop_full {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.ResetFilters {
  font-family: var(--font-medium);
  box-sizing: border-box;
  background: var(--color-tomato);
  color: var(--color-white);
  border-radius: 6px;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 20px;
    margin-top: 26px;
    padding: 8px 24px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin-top: 30px;
    padding: 10px 28px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin-top: 32px;
    padding: 11px 32px;
  }

  &:hover {
    background: var(--color-hover-btn);
  }
}

.NoData {
  display: flex;
  justify-content: center;
  font-family: var(--font-medium);
  font-size: 20px;
  margin-top: 150px;
}
