@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.ContainerCustomersItem {
  font-family: var(--font-medium);
  display: grid;
  grid-template-columns: 16% 16% 16% 22% 15% 15%;
  background: var(--color-white);
  padding: 16px 24px;
  border-radius: 6px;

  @include media_desktop_hd {
    padding: 14px 20px;
    margin-bottom: 28px;
  }

  @include media_desktop_wxga {
    padding: 15px 22px;
    margin-bottom: 30px;
  }

  @include media_desktop_full {
    padding: 16px 24px;
    margin-bottom: 32px;
  }
}

.CompanyName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media_desktop_hd {
    max-width: 140px;
  }

  @include media_desktop_wxga {
    max-width: 170px;
  }

  @include media_desktop_full {
    max-width: 200px;
  }
}

.Email {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media_desktop_hd {
    max-width: 140px;
  }

  @include media_desktop_wxga {
    max-width: 170px;
  }

  @include media_desktop_full {
    max-width: 200px;
  }
}

.SignUpToTime {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media_desktop_hd {
    max-width: 160px;
  }

  @include media_desktop_wxga {
    max-width: 180px;
  }

  @include media_desktop_full {
    max-width: 200px;
  }
}

.Source {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  @include media_desktop_hd {
    max-width: 190px;
  }

  @include media_desktop_wxga {
    max-width: 200px;
  }

  @include media_desktop_full {
    max-width: 210px;
  }
}

.Learners {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media_desktop_hd {
    max-width: 140px;
  }

  @include media_desktop_wxga {
    max-width: 170px;
  }

  @include media_desktop_full {
    max-width: 200px;
  }
}

.Billing {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media_desktop_hd {
    max-width: 160px;
  }

  @include media_desktop_wxga {
    max-width: 180px;
  }

  @include media_desktop_full {
    max-width: 200px;
  }
}

.Free {
  color: var(--color-blue);
}

.Paid {
  color: var(--color-green);
}

.Failure {
  color: var(--color-tomato-active);
}