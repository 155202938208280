@import '../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../assets/scss/medias';

.WrapperFilters {
  display: flex;
  align-items: end;

  @include media_desktop_hd {
    margin-bottom: 40px;
  }

  @include media_desktop_wxga {
    margin-bottom: 44px;
  }

  @include media_desktop_full {
    margin-bottom: 48px;
  }
}

.DropDownContainer {
  width: 146px;
  padding: 7px 14px 7px 13px;
  box-sizing: border-box;

  @include media_desktop_qhd {
    width: 126px;
    padding: 7px 14px 7px 13px;
  }

  @include media_desktop_hd {
    width: 146px;
    padding: 8px 15px 8px 14px;
  }

  @include media_desktop_wxga {
    width: 186px;
    padding: 9px 16px 9px 15px;
  }

  @include media_desktop_full {
    width: 208px;
    padding: 10px 17px 10px 16px;
  }
}

.DropDownContainerData {
  @include media_desktop_qhd {
    width: 146px;
  }

  @include media_desktop_hd {
    width: 166px;
  }

  @include media_desktop_wxga {
    width: 206px;
  }

  @include media_desktop_full {
    width: 246px;
  }
}

.DropDownMenuContainer {
  width: 83%;

  @include media_desktop_qhd {
    width: 83%;
  }

  @include media_desktop_hd {
    width: 86%;
  }

  @include media_desktop_wxga {
    width: 87%;
  }

  @include media_desktop_full {
    width: 87%;
  }
}

.FilterContainerItem {
  font-size: 12px;
  line-height: 16px;
  margin-right: 20px;

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
    margin-right: 13px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    margin-right: 15px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    margin-right: 17px;
  }
}

.BtnReset {
  display: flex;
  align-items: center;

  @include media_desktop_hd {
    height: 35px;
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    height: 42px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_full {
    height: 46px;
    font-size: 20px;
    line-height: 24px;
  }
}
