@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.WrapperAnalytics {
    padding: 26px 60px 20px 40px;

    @include media_desktop_qhd {
        padding: 26px 60px 20px 40px;
    }

    @include media_desktop_hd {
        padding: 36px 70px 30px 40px;
    }

    @include media_desktop_wxga {
        padding: 46px 80px 40px 40px;
    }

    @include media_desktop_full {
        padding: 56px 90px 40px 40px;
    }
}

.AnalyticsNone {
    position: absolute;
    top: 28%;
    left: 44%;
    text-align: center;
    font-family: var(--font-medium);
    font-size: 22px;
    line-height: 28px;

    @include media_desktop_qhd {
        top: 28%;
        left: 44%;
        font-size: 22px;
        line-height: 28px;
    }

    @include media_desktop_hd {
        top: 35%;
        left: 45%;
        font-size: 24px;
        line-height: 30px;
    }

    @include media_desktop_wxga {
        top: 35%;
        left: 45%;
        font-size: 26px;
        line-height: 32px;
    }

    @include media_desktop_full {
        top: 40%;
        left: 45%;
        font-size: 28px;
        line-height: 34px;
    }
}

.Subtitle {
    color: var(--color-silver);
    font-family: var(--font-medium);
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
    width: 350px;

    @include media_desktop_qhd {
        width: 350px;
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        width: 385px;
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        width: 385px;
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        width: 485px;
        font-size: 24px;
        line-height: 28px;
    }
}


.WrapperTitleNone {
    display: flex;
    align-items: center;
    justify-content: center;
}

.TitleNone {
    margin-right: 10px;
}
