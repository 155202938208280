@import '../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../assets/scss/medias';

.MainPopupContainer {
    overflow: hidden;
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        width: 790px;
        height: 550px;
    }

    @include media_desktop_hd {
        width: 880px;
        height: 600px;
    }

    @include media_desktop_wxga {
        width: 950px;
        height: 650px;
    }

    @include media_desktop_full {
        width: 1030px;
        height: 700px;
    }
}

.MainContentComponent {
    display: flex;
    width: 100%;
    height: 100%;
}

.QuizList {
    display: block;
    border-right: 1px solid var(--color-gray-border);
    width: 100%;

    @include media_desktop_qhd {
        max-width: 184px;
    }

    @include media_desktop_hd {
        max-width: 214px;
    }

    @include media_desktop_wxga {
        max-width: 244px;
    }

    @include media_desktop_full {
        max-width: 264px;
    }
}

.AddNewQuestions {
    position: relative;
    width: max-content;
    display: block;
    margin: 0 auto;
}

.SearchBlock {
    display: flex;
    border-bottom: 1px solid var(--color-gray-border);
    box-sizing: border-box;

    @include media_desktop_qhd {
        padding: 22px 11px;
    }

    @include media_desktop_hd {
        padding: 24px 13px;
    }

    @include media_desktop_wxga {
        padding: 26px 15px;
    }

    @include media_desktop_full {
        padding: 28px 17px;
    }
}

.SearchQuestionInput {
    padding: 0;
    border: none;

    &::placeholder {
        color: var(--color-gray-border);
    }

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.MainBlock {
    display: block;
    width: 100%;
    height: 100%;

    @include media_desktop_qhd {
        padding: 18px;
    }

    @include media_desktop_hd {
        padding: 20px;
    }
    @include media_desktop_wxga {
        padding: 22px;
    }

    @include media_desktop_full {
        padding: 24px;
    }
}
.CloseContainer {
    width: 100%;
    display: block;
    text-align: right;
}

.CloseButton {
    cursor: pointer;
    outline: none;

    @include media_desktop_hd {
        width: px-to-vh(40px);
        height: px-to-vh(40px);
    }
}

.TitleText {
    @include media_desktop_qhd {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
    }
}

.QuizContainer {
    text-align: center;
    box-sizing: border-box;
    overflow-x: auto;
    height: 100%;
    width: 100%;

    @include media_desktop_qhd {
        padding: 26px 0 26px 8px;
    }

    @include media_desktop_hd {
        padding: 28px 0 28px 10px;
    }

    @include media_desktop_wxga {
        padding: 30px 0 30px 12px;
    }

    @include media_desktop_full {
        padding: 32px 0 32px 15px;
    }
}

.ButtonImg {
    @include media_desktop_qhd {
        height: 18px;
        width: 18px;
        margin-right: 10px;
    }

    @include media_desktop_hd {
        height: 20px;
        width: 20px;
        margin-right: 12px;
    }

    @include media_desktop_wxga {
        height: 22px;
        width: 22px;
        margin-right: 14px;
    }

    @include media_desktop_full {
        height: 24px;
        width: 24px;
        margin-right: 16px;
    }
}

.AddQuestionsText {
    display: block;
    font-family: var(--font-medium);

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_full {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.SettingsText {
    font-family: var(--font-medium);
    color: var(--color-silver);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.SettingsButton {
    display: flex;
    align-items: center;
    padding: px-to-vh(8px);
    margin: 0 auto;

    @include media_desktop_qhd {
        margin-top: 10px;
    }

    @include media_desktop_hd {
        margin-top: 12px;
    }

    @include media_desktop_wxga {
        margin-top: 14px;
    }

    @include media_desktop_full {
        margin-top: 16px;
    }
}