@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.MainContainerDesktop {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainContainerMobile {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.LogoImage {
    width: 136px;
    height: 37px;
    padding: 25px 0 0 25px;
    margin-bottom: 50px;
}

.SubContainer {
    display: flex;
    align-items: center;
    justify-content: center;

}

.BackgroundImage {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.SignInContainer {
    display: block;
}

.TitleText {
    display: block;
    font-family: var(--font-medium);
    color: var(--color-tomato);
    font-size: 28px;
    line-height: 34px;

    @include media_tablet {
        font-size: 40px;
        line-height: 44px;
    }

    @include media_desktop_qhd {
        font-size: 42px;
        line-height: 46px;
    }

    @include media_desktop_hd {
        font-size: 44px;
        line-height: 50px;
    }

    @include media_desktop_wxga {
        font-size: 46px;
        line-height: 54px;
    }

    @include media_desktop_full {
        font-size: 48px;
        line-height: 58px;
    }

    span {
        color: var(--color-black);
    }
}

.TitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.InputContainer {
    display: block;
    width: 320px;
    margin: px-to-vh(64px) auto 0;

    @include media_desktop_qhd {
        width: 260px;
    }

    @include media_desktop_hd {
        width: 280px;
    }

    @include media_desktop_wxga {
        width: 300px;
    }

    @include media_desktop_full {
        width: 320px;
    }
}

.TitleDescriptionContainer {
    display: flex;
    align-items: center;
    margin-bottom: px-to-vh(32px);

    span {
        font-family: var(--font-regular);
        width: 100%;
        white-space: nowrap;
        display: block;
        font-size: 16px;
        line-height: 22px;
        margin: 0 px-to-vh(12px);

        @include media_tablet {
            font-size: 16px;
            line-height: 22px;
        }

        @include media_desktop_qhd {
            font-size: 14px;
            line-height: 28px;
        }

        @include media_desktop_hd {
            font-size: 14px;
            line-height: 28px;
        }

        @include media_desktop_wxga {
            font-size: 16px;
            line-height: 20px;
        }

        @include media_desktop_full {
            font-size: 18px;
            line-height: 22px;
        }
    }
}

.Line {
    height: 0.5px;
    width: 100%;
    background-color: var(--color-silver);
}

.LabelInputText {
    display: block;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 22px;
    font-family: var(--font-regular);

    @include media_tablet {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 8px;
    }

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: px-to-vh(8px);
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: px-to-vh(8px);
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: px-to-vh(8px);
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: px-to-vh(8px);
    }
}

.InputField {
    display: block;
    box-sizing: border-box;
    font-family: var(--font-regular);
    font-size: 18px;
    line-height: 22px;
    padding: 12px 25px;
    margin-bottom: 24px;

    &::placeholder {
        color: var(--color-gray-border);
    }

    @include media_tablet {
        font-size: 18px;
        line-height: 22px;
        padding: 12px 25px;
        margin-bottom: 24px;
    }

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: px-to-vh(25px);
        padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: px-to-vh(25px);
        padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: px-to-vh(25px);
        padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: px-to-vh(25px);
        padding: px-to-vh(12px) 0 px-to-vh(12px) px-to-vh(25px);
    }
}

.LoginButton {
    display: block;
    margin: px-to-vh(32px) 0;
    box-sizing: border-box;
    width: 100%;
    font-size: 20px;
    line-height: 24px;

    @include media_tablet {
        font-size: 20px;
        line-height: 24px;
    }

    @include media_desktop_qhd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

.SignUpDescriptionContainer {
    display: block;
    margin-top: px-to-vh(32px);
}

.SignUpButton {
    display: block;
    margin: 0 auto;
    font-size: 18px;
    line-height: 22px;
    font-family: var(--font-regular);
    color: var(--color-tomato);

    @include media_tablet {
        font-size: 18px;
        line-height: 22px;
    }

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
    }
}

.ResetButton {
    color: var(--color-dark-text);
    margin-bottom: 15px;
}


