@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.TitleText {
    font-family: var(--font-medium);
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 40px;

    @include media_desktop_qhd {
        font-size: 28px;
        line-height: 22px;
        margin-bottom: 40px;
    }

    @include media_desktop_hd {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 44px;
    }

    @include media_desktop_wxga {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 46px;
    }

    @include media_desktop_full {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 48px;
    }
}

.NewColorContainer {
    display: flex;
    width: 154px;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    background: var(--color-white);

    @include media_desktop_qhd {
        width: 155px;
    }

    @include media_desktop_hd {
        width: 164px;
    }

    @include media_desktop_wxga {
        width: 184px;
    }

    @include media_desktop_full {
        width: 194px;
    }
}

.SignatureText {
    font-size: 16px;
    font-family: var(--font-regular);
    display: block;
    color: var(--color-gray-title);

    @include media_desktop_qhd {
        font-size: 12px;
        line-height: 16px;
        margin-top: 13px;
    }

    @include media_desktop_hd {
        font-size: 14px;
        line-height: 18px;
        margin-top: 14px;
    }

    @include media_desktop_wxga {
        font-size: 16px;
        line-height: 20px;
        margin-top: 15px;
    }

    @include media_desktop_full {
        font-size: 18px;
        line-height: 22px;
        margin-top: 16px;
    }
}

.ColorPickerContainer {
    position: absolute;
    top: 110px;
    bottom: 0;

    div {
        font-family: var(--font-medium);
        --rcp-input-border: var(--color-gray-border);
        --rcp-input-text: var(--color-dark-text);
        --rcp-input-label: var(--color-white);
    }

    @include media_desktop_qhd {
        top: 110px;
        bottom: 0;
    }

    @include media_desktop_hd {
        top: 110px;
        bottom: 0;
    }

    @include media_desktop_wxga {
        top: 0;
        left: 220px;
    }

    @include media_desktop_full {
        top: 0;
        left: 220px;
    }
}

.ColorBlock {
    width: 38px;
    height: 38px;
    background: #F85A40;
    border-radius: 6px;
    margin: 8px 24px 8px 8px;
    border: 1px solid var(--color-white);

    @include media_desktop_qhd {
        width: 38px;
        height: 38px;
    }

    @include media_desktop_hd {
        width: 40px;
        height: 40px;
    }

    @include media_desktop_wxga {
        width: 45px;
        height: 45px;
    }

    @include media_desktop_full {
        width: 48px;
        height: 48px;
    }
}

.BorderColor {
    border: 1px solid var(--color-gray-title);
}

.ColorCode {
    display: flex;
    align-items: center;
    font-family: var(--font-medium);
    font-size: 14px;
    line-height: 18px;

    @include media_desktop_qhd {
        font-size: 14px;
        line-height: 18px;
    }

    @include media_desktop_hd {
        font-size: 16px;
        line-height: 20px;
    }

    @include media_desktop_wxga {
        font-size: 17px;
        line-height: 21px;
    }

    @include media_desktop_full {
        font-size: 20px;
        line-height: 24px;
    }
}

