@import '../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../assets/scss/medias';

.AnalyticsComponents {
  display: flex;
  align-items: center;
  margin-right: 12px;

  @include media_desktop_qhd {
    margin-right: 12px;
  }

  @include media_desktop_hd {
    margin-right: 28px;
  }

  @include media_desktop_wxga {
    margin-right: 38px;
  }

  @include media_desktop_full {
    margin-right: 38px;
  }
}

.TextAnalytics {
  font-family: var(--font-regular);
  color: var(--color-dark-text);
  margin: 0 8px 0 8px;
  font-size: 12px;
  line-height: 20px;

  @include  media_desktop_qhd {
    margin-right: 8px;
    margin-left: 8px;
    font-size:  12px;
    line-height: 20px;
  }

  @include  media_desktop_hd {
    margin-right: 14px;
    margin-left: 14px;
    font-size:  14px;
    line-height: 22px;
  }

  @include  media_desktop_wxga {
    margin-right: 16px;
    margin-left: 16px;
    font-size:  17px;
    line-height: 25px;
  }

  @include media_desktop_full {
    margin-right: 20px;
    margin-left: 20px;
    font-size:  20px;
    line-height: 28px;
  }
}


.Result {
  font-family: var(--font-medium);
  font-size: 16px;
  line-height: 24px;
  color: var(--color-tomato);

    @include media_desktop_qhd {
      font-size: 16px;
      line-height: 24px;
    }

    @include media_desktop_hd {
      font-size: 22px;
      line-height: 28px;
    }

    @include media_desktop_wxga {
      font-size: 30px;
      line-height: 36px;
    }

    @include media_desktop_full {
      font-size: 36px;
      line-height: 43px;
    }
}

