@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.WrapperForSeats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 18px;
    line-height: 22px;
  }

  @include media_desktop_wxga {
    font-size: 21px;
    line-height: 25px;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
  }
}

.Button {
  @include media_desktop_hd {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 22px;
  }

  @include media_desktop_wxga {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 22px;
  }

  @include media_desktop_full {
    font-size: 20px;
    line-height: 24px;
    padding: 12px 32px;
  }
}

.ContainerForFreeAndPro {
  display: flex;
}
