@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

.SwitchContainer {
    position: relative;
    display: block;

    @include media_desktop_qhd {
        width: 33px;
        height: 18px;
        margin-right: 18px;
    }

    @include media_desktop_hd {
        width: 35px;
        height: 20px;
        margin-right: 20px;
    }

    @include media_desktop_wxga {
        width: 37px;
        height: 24px;
        margin-right: 22px;
    }

    @include media_desktop_full {
        width: 39px;
        height: 26px;
        margin-right: 24px;
    }
}

.SwitchContainer input {
    display:none;
}

.Slider {
    display: flex;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-white);
    -webkit-transition: .4s;
    transition: .4s;
}

.Slider::before {
    position: absolute;
    content: "";
    align-self: center;
    left: 10%;
    background-color: var(--color-tomato);
    -webkit-transition: .4s;
    transition: .4s;

    @include media_desktop_qhd {
        height: 12px;
        width: 12px;
    }

    @include media_desktop_hd {
        height: 14px;
        width: 14px;
    }

    @include media_desktop_wxga {
        height: 16px;
        width: 16px;
    }

    @include media_desktop_full {
        height: 18px;
        width: 18px;
    }
}

input:checked + .Slider {
    background-color: var(--color-tomato);
}

.DisabledSlider::before {
    background-color: var(--color-gray-border);
}

input:not(:checked) + .Slider {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-border);
    box-sizing: content-box;
}

input:checked + .Slider::before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    background-color: var(--color-white);
}

.Slider.Round {
    border-radius: 20px;
}

.Slider.Round::before {
    border-radius: 50%;
}