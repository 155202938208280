@import '../../../../../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../../../../../assets/scss/medias';

.VariantsListContainer {
  overflow-x: auto;

  @include media_desktop_qhd {
    max-height: 240px;
    padding-right: 6px;
  }

  @include media_desktop_hd {
    max-height: 250px;
    padding-right: 8px;
  }

  @include media_desktop_wxga {
    max-height: 280px;
    padding-right: 4px;
  }

  @include media_desktop_full {
    max-height: 300px;
  }
}

.VariantItemContainer {
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    .WrapperImgAdd {
      visibility: visible;
    }
  }

  @include media_desktop_qhd {
    padding-top: 10px;
  }

  @include media_desktop_hd {
    padding-top: 12px;
  }

  @include media_desktop_wxga {
    padding-top: 14px;
  }

  @include media_desktop_full {
    padding-top: 16px;
  }
}

.CheckBoxContainer {
  position: relative;
  display: block;
  align-items: center;

  input[type='checkbox'] {
    border: 1px solid var(--color-gray-border);
    color: var(--color-white);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    padding: px-to-vh(7px);
    box-sizing: border-box;
    border-radius: 2px;

    &:not(:checked) {
      background-color: var(--color-white);
      background-image: url('../../../../../../../../../../../../../../assets/images/icon/check-box-mark-cross.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 60%;
    }

    &:checked {
      border-color: var(--color-tomato);
      background-color: var(--color-tomato);
      background-image: url('../../../../../../../../../../../../../../assets/images/icon/check-box-mark-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%;
    }

    @include media_desktop_qhd {
      width: 18px;
      height: 18px;
      margin: 0 18px;
    }

    @include media_desktop_hd {
      width: 20px;
      height: 20px;
      margin: 0 20px;
    }

    @include media_desktop_wxga {
      width: 22px;
      height: 22px;
      margin: 0 22px;
    }

    @include media_desktop_full {
      width: 24px;
      height: 24px;
      margin: 0 24px;
    }
  }
}

.LabelInput {
  display: flex;
  align-items: center;
  width: max-content;
}

.UnavailableIcon {
  cursor: not-allowed;
}

.VariantInput {
  font-family: var(--font-medium);
  padding-right: 50px;

  @include media_desktop_qhd {
    font-size: 12px;
    line-height: 16px;
  }

  @include media_desktop_hd {
    font-size: 14px;
    line-height: 18px;
  }

  @include media_desktop_wxga {
    font-size: 16px;
    line-height: 20px;
  }

  @include media_desktop_full {
    font-size: 18px;
    line-height: 22px;
  }
}

.newImage {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  position: absolute;

  @include media_desktop_hd {
    top: 18px;
    right: 90px;
  }

  @include media_desktop_wxga {
    top: 23px;
    right: 100px;
  }

  @include media_desktop_full {
    top: 28px;
    right: 110px;
  }
}

.DeleteImg {
  border-radius: 50%;
  background: var(--color-tomato);
  padding: 4px;
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 99;
  cursor: pointer;

  @include media_desktop_hd {
    top: 14px;
    right: 85px;
  }

  @include media_desktop_wxga {
    top: 18px;
    right: 95px;
  }

  @include media_desktop_full {
    top: 23px;
    right: 105px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.WrapperImgAdd {
  position: absolute;
  cursor: pointer;
  visibility: hidden;

  @include media_desktop_hd {
    right: 94px;
    top: 25px;
  }

  @include media_desktop_wxga {
    right: 108px;
    top: 30px;
  }

  @include media_desktop_full {
    top: 35px;
    right: 114px;
  }

  svg {
    fill: var(--color-silver);

    &:hover {
      fill: var(--color-tomato);
    }
  }
}