@import '../../../../../../../../../../assets/scss/helpers';
@import '../../../../../../../../../../assets/scss/medias';

.Title {
  font-family: var(--font-medium);

  @include media_desktop_hd {
    font-size: 20px;
    line-height: 24px;
    margin: 27px 0 20px 0;
  }

  @include media_desktop_wxga {
    font-size: 22px;
    line-height: 26px;
    margin: 29px 0 22px 0;
  }

  @include media_desktop_full {
    font-size: 24px;
    line-height: 28px;
    margin: 31px 0 24px 0;
  }
}

.EmptyReportTitle {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  font-family: var(--font-medium);

  font-size: 20px;
}
