.MainContainer {
    border-radius: 5px;
    margin: 0px auto;
    padding: 5px 7px;
    text-align: center;
    font-size: 16px;
    font-family: var(--font-regular);
    outline: none;
    background-color: var(--color-modal-error-background);
    border: 1px solid var(--color-modal-error-background);
    color: var(--color-modal-error-text);
}

.LinkText {
    text-decoration: underline;
    cursor: pointer;
}